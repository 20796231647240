import DisplayPropertiesPicker from '../widgets/DisplayPropertiesPicker';

const displaySchema = {
  type: 'object',
  properties: {
    componentIsActive: {
      type: ['boolean', 'null'],
      title: 'When a component is selected',
      default: null,
      anyOf: [
        {
          enum: [null],
          title: 'No setting',
        },
        {
          enum: [true],
          title: 'Show',
        },
        {
          enum: [false],
          title: 'Hide',
        },
      ],
    },
  },
};

const displayUiSchema = {
  componentIsActive: {
    'ui:widget': DisplayPropertiesPicker,
  },
};

export { displaySchema, displayUiSchema };
