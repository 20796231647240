export const schema = {
  type: 'object',
  properties: {
    title: {
      type: 'string',
      title: 'Title',
    },
    duvalMode: {
      type: 'string',
      title: 'Mode',
      default: 'current',
      oneOf: [
        { enum: ['current'], title: 'Current value' },
        { enum: ['over time'], title: 'Values over time' },
      ],
    },
    duvalType: {
      type: 'number',
      title: 'Duval type',
      default: 1,
      oneOf: [
        { enum: [1], title: 'Duval triangle 1 (CH4, C2H4, C2H2)' },
        { enum: [4], title: 'Duval triangle 4 (H2, CH4, C2H6)' },
        { enum: [5], title: 'Duval triangle 5 (CH4, C2H4, C2H6)' },
      ],
    },
    pointRadius: {
      type: 'number',
      title: 'Size of plot points',
      default: 5,
      maximum: 10,
      minimum: 1,
    },
    lines: {
      type: 'object',
      title: 'Reference lines for latest point',
      properties: {
        show: { type: 'boolean', default: true },
      },
    },
    highlightAreas: {
      type: 'object',
      title: 'Highlight latest point area',
      properties: {
        show: { type: 'boolean', default: false },
      },
    },
    legend: {
      type: 'object',
      title: 'Legend',
      properties: {
        show: { type: 'boolean', default: false },
      },
    },

    rollingAverage: {
      type: 'object',
      title: 'Rolling average',
      properties: {
        show: {
          type: 'boolean',
          title: 'show',
          default: false,
        },
        decay: {
          title: ' ',
          type: 'null',
          default: 0.5,
        },
      },
      if: {
        properties: {
          show: {
            enum: [true],
          },
        },
      },
      then: {
        properties: {
          type: {
            type: 'string',
            title: 'Rolling average type',
            enum: ['Points', 'Lines', 'Both'],
            default: 'Points',
          },
          decay: {
            type: 'number',
            title: 'Rolling average decay',
            default: 0.5,
            multipleOf: 0.05,
            maximum: 1,
            minimum: 0,
          },
        },
      },
    },
  },
};

export const uiSchema = {
  pointRadius: {
    'ui:widget': 'range',
  },
  thresholds: {
    areaItems: {
      'ui:options': {
        orderable: false,
      },
    },
    linearItems: {
      'ui:options': {
        orderable: false,
      },
    },
  },
  rollingAverage: {
    'ui:order': ['show', 'type', 'decay'],
    decay: {
      'ui:widget': 'range',
      default: 2,
    },
  },
};
