import { visualizations as vizMappings } from '../../Visualization';

const types = vizMappings.map(({ type }) => type);

const getVisualizationsPanelSchema = (visualizations = []) => {
  const schema = {
    title: {
      type: 'string',
      title: 'Title',
      default: 'Visualizations',
    },
    options: {
      type: 'object',
      properties: {
        autoPopulate: {
          title: 'Auto-populate with visualizations related to selected component',
          type: 'boolean',
          default: false,
        },
        hideToolbar: {
          title: 'Hide toolbar',
          type: 'boolean',
          default: true,
        },
        syncVisualizationTooltip: {
          title: 'Sync the visualization tooltip',
          type: 'boolean',
          default: false,
        },
        visualizationLimit: {
          title: 'Limit visualizations per panel',
          type: 'number',
          default: 10,
        },
        height: {
          type: 'string',
          title: 'Visualization height',
          enum: ['small', 'medium-small', 'medium', 'large'],
          default: 'medium',
        },
        width: {
          type: 'string',
          title: 'Visualization width',
          enum: ['small', 'medium-small', 'medium', 'large'],
          default: 'medium',
        },
        visualizationTypes: {
          type: 'array',
          title: 'Visualization Types',
          description:
            'Limit auto-populated visualizations to the selected types (no selected types means that all types are allowed)',
          default: [],
          uniqueItems: true,
          isMulti: true,
          items: {
            type: 'string',
            enum: types,
          },
        },
      },
    },
  };

  if (visualizations.length) {
    schema.options.properties.visualizations = {
      type: 'array',
      title: 'Visualizations',
      default: [],
      uniqueItems: true,
      isMulti: true,
      items: {
        type: 'string',
        anyOf: visualizations.map((viz) => ({
          enum: [viz.id],
          title: `${viz.name} (${viz.type})`,
        })),
      },
    };
  }

  return schema;
};

const getVisualizationsPanelAdminSchema = (visualizations = []) => ({
  type: 'object',
  properties: {
    autoPopulate: {
      title: 'Auto-populate with visualizations related to selected component',
      type: 'boolean',
      default: false,
    },
    hideToolbar: {
      title: 'Hide toolbar',
      type: 'boolean',
      default: true,
    },
    syncVisualizationTooltip: {
      title: 'Sync the visualization tooltip',
      type: 'boolean',
      default: false,
    },
    visualizationLimit: {
      title: 'Limit visualizations per panel',
      type: 'number',
      default: 10,
    },
    visualizations: visualizations.length
      ? {
          type: 'array',
          title: 'Include specific visualizations',
          default: [],
          uniqueItems: true,
          isMulti: true,
          items: {
            type: 'string',
            anyOf: visualizations.map((viz) => ({
              enum: [viz.id],
              title: `${viz.name} (${viz.type})`,
            })),
          },
        }
      : {
          type: 'object',
          title: 'Visualizations',
          description: 'None available',
          properties: {},
        },
  },
});

export { getVisualizationsPanelSchema, getVisualizationsPanelAdminSchema };
