import React, { useState, useEffect, Children } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from '@iq/react-components';
import StyledItem from '../../StyledItem';
import { getTimezone } from '../../../bundles/application';
import { utcToSite, datetimeString } from '../../../datetimeUtils';
import { STATUSES } from '../../../constants';
import IotEventModal from '../../EventModal/IotEventModal';

const HealthRisk = ({ risk, isOpen: isColumnOpen, riskThreshold = {} }) => {
  const { event } = risk;
  const {
    shutdownWarning = 60,
    shutdownEmergency = 80,
    downtimeWarning = 8,
    downtimeEmergency = 10,
  } = riskThreshold;
  const [isOpen, setOpen] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);

  const timezone = useSelector(getTimezone);
  const { color: statusColor, icon: statusIcon } = STATUSES[risk.status] || {};
  const [r, g, b] = statusColor || [0, 0, 0];
  const riskValue = Object.entries(risk)
    .filter(([k]) => k.includes('fr') || k.includes('mttr'))
    .map(([k, v]) => {
      let time = '';
      const value = typeof v === 'number' ? Math.round((v + Number.EPSILON) * 100) / 100 : v;
      const prop = k.toLowerCase();
      if (prop.includes('day')) {
        time = '2 days';
      } else if (prop.includes('week')) {
        time = '1 week';
      } else if (prop.includes('month')) {
        time = '1 month';
      }
      return {
        time: value,
        timeUnit: k.includes('fr') ? 'h' : '',
        timespan: time,
        type: k.includes('fr') ? 'fr' : 'mttr',
      };
    });
  const DownTimeRisk = riskValue.filter((x) => x.type === 'mttr');
  const ShutdownRisk = riskValue.filter((x) => x.type === 'fr');
  const riskData = [];
  riskData['Shutdown Risk'] = ShutdownRisk;
  riskData['Downtime Risk'] = DownTimeRisk;
  useEffect(() => {
    setOpen(isColumnOpen);
  }, [isColumnOpen]);

  const getValueColor = (k, t) => {
    if (k === 'Shutdown Risk') {
      if (t > shutdownEmergency) {
        return '#C62828';
      }
      if (t > shutdownWarning) {
        return '#C6B628';
      }
      return '#6C6B6B';
    }
    if (k === 'Downtime Risk') {
      if (t > downtimeEmergency) {
        return '#C62828';
      }
      if (t > downtimeWarning) {
        return '#C6B628';
      }
      return '#6C6B6B';
    }
    return null;
  };

  const formatEventDatetime = (timestamp, withMs = true) =>
    datetimeString(utcToSite(timestamp, timezone), timezone, withMs);

  return (
    <>
      <StyledItem
        itemClass="risk-list-item"
        active={isOpen}
        onClick={() => setOpen(!isOpen)}
        headerLabel={
          <div className="risk-title">
            <div className="risk-title-status">
              <div>
                <Icon
                  size="s"
                  icon={statusIcon}
                  style={{ color: `rgba(${r}, ${g}, ${b}, ${statusColor ? 1 : 0})` }}
                />
                <div className="title">{STATUSES[risk.status].text}</div>
              </div>
              <div>{formatEventDatetime(risk.eventDateTime)}</div>
            </div>
            <div className="risk-title-info">
              <div className="risk-eventtext">{risk.eventText}</div>
              <div className="risk-component">{`${risk.itemDesignation} ${risk.component}`}</div>
            </div>
          </div>
        }
        headerActions={
          <>
            <Icon icon={isOpen ? 'he-up' : 'he-down'} />
          </>
        }
        bodyActionsPosition="center"
      >
        <div className="grid risk-body main risk-wide-panel">
          <div className="grid-content narrow">
            {Children.toArray(
              Object.keys(riskData).map((key) => {
                const riskTime = riskData[key];
                return (
                  <>
                    <div className="grid-item risk-body-title">
                      {key} <span>{key === 'Downtime Risk' && '(hours)'}</span>
                    </div>
                    {Children.toArray(
                      riskTime.map((value) => (
                        <div className="grid-item">
                          <span>{value.timespan}</span>
                          <div
                            className="circle"
                            style={{ border: `1.5px solid ${getValueColor(key, value.time)}` }}
                          >
                            {key === 'Shutdown Risk' && value.time !== 'NA'
                              ? `${value.time}%`
                              : value.time}
                          </div>
                        </div>
                      ))
                    )}
                  </>
                );
              })
            )}
          </div>
        </div>
        <div className="risk-message">
          *indicated shutdown & downtime risk was valid at the time when the alarm occured
        </div>
        <div className="risk-body mitigation">
          <div className="risk-body-title">Description & Mitigation</div>
          {risk.mitigation}
        </div>
        <div className="source-event">
          <Button
            activity="secondary"
            onClick={() => setShowEventModal(true)}
          >
            View source event
          </Button>
        </div>
      </StyledItem>
      {showEventModal && event && (
        <IotEventModal
          event={event}
          onClose={() => setShowEventModal(false)}
        ></IotEventModal>
      )}
    </>
  );
};

export default HealthRisk;
