/* eslint-disable default-param-last */
import { all, call, put, select, takeEvery, takeLatest, debounce, delay } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import {
  getRemoteAssetInfo as getRemoteAssetInfoApi,
  getComponents as getComponentsApi,
  getSiteComponentTree,
  getComponentsStatus as getComponentsStatusApi,
  createComponent as createComponentApi,
  updateComponent as updateComponentApi,
  deleteComponent as deleteComponentApi,
  deleteNonSiteComponents as deleteNonSiteComponentsApi,
  downloadComponentsExcelTemplate as downloadCompsExcelApi,
  upsertComponentsInBulk as upsertComponentsInBulkApi,
  createVariables as createVariablesApi,
  updateVariable as updateVariableApi,
  deleteVariable as deleteVariableApi,
} from '../services';
import { displayNotification, checkOnline } from './notifications';
import { getSources } from './sources';
import getNotification from './notification-defaults';
import {
  getActiveComponentId,
  getActiveComponentFilter,
  setPollingActive,
  setPollingActiveDone,
  REFRESH_VALUES,
  CLEAR_SITE_DATA,
} from './application';
import { COMPONENT_SCOPES } from '../components/panels/panelSchema';
import { downloadBlob } from '../utils';
import { INTERNAL_DATA_SOURCE } from '../constants';

/** ********************************************
 *                                             *
 *                 Action Types                *
 *                                             *
 ********************************************* */

const REQUEST_COMPONENTS = 'dt/components/REQUEST_COMPONENTS';
const RESET_COMPONENTS = 'dt/components/RESET_COMPONENTS';
const RECEIVE_COMPONENTS = 'dt/components/RECEIVE_COMPONENTS';
const RECEIVE_STATUS = 'dt/components/RECEIVE_STATUS';

const CREATE_COMPONENT = 'dt/components/CREATE_COMPONENT';
const UPSERT_COMPONENTS = 'dt/components/UPSERT_COMPONENTS';
const CREATE_COMPONENT_SUCCESS = 'dt/components/CREATE_COMPONENT_SUCCESS';
const DELETE_COMPONENT = 'dt/components/DELETE_COMPONENT';
const DELETE_NONSITE_COMPONENTS = 'dt/components/DELETE_NONSITE_COMPONENTS';

const UPDATE_COMPONENT = 'dt/components/UPDATE_COMPONENT';

const DOWNLOAD_COMPONENTS_TEMPLATE = 'dt/components/DOWNLOAD_COMPONENTS_TEMPLATE';
const COMPONENT_IMPORT_ERRORS = 'dt/components/COMPONENT_IMPORT_ERRORS';
const RESET_COMPONENTS_IMPORTED_AT = 'dt/components/RESET_COMPONENTS_IMPORTED_AT';

const REQUEST_REMOTE_ASSET_INFO = 'dt/components/REQUEST_REMOTE_ASSET_INFO';
const RECEIVE_REMOTE_ASSET_INFO = 'dt/components/RECEIVE_REMOTE_ASSET_INFO';

const SET_PAGE_COMPONENTS = 'dt/components/SET_PAGE_COMPONENTS';

/** ********************************************
 *                                             *
 *               Action Creators               *
 *                                             *
 ******************************************** */
export const requestRemoteAssetInfo = (integrationIds, componentId) => ({
  type: REQUEST_REMOTE_ASSET_INFO,
  integrationIds,
  componentId,
});

export const receiveRemoteAssetInfo = (componentId, assetInfo) => ({
  type: RECEIVE_REMOTE_ASSET_INFO,
  componentId,
  assetInfo,
});

export const requestComponents = (siteId) => ({
  type: REQUEST_COMPONENTS,
  siteId,
});

export const receiveComponents = (...args) => {
  const [components, hashmap, componentTree, siteId, componentsImportedAt = 0] = args;
  return {
    type: RECEIVE_COMPONENTS,
    components,
    hashmap,
    componentTree,
    siteId,
    componentsUpdatedAt: Date.now(),
    componentsImportedAt,
  };
};

export const receiveStatus = (componentsStatus) => ({
  type: RECEIVE_STATUS,
  componentsStatus,
});

export const resetComponents = () => ({
  type: RESET_COMPONENTS,
});

export const createComponent = (siteId, org, data, doNotify = true) => ({
  type: CREATE_COMPONENT,
  siteId,
  org,
  data,
  doNotify,
});

export const upsertComponents = (siteId, org, components, doNotify = false) => {
  return {
    type: UPSERT_COMPONENTS,
    siteId,
    org,
    components,
    doNotify,
  };
};

export const updateComponent = (id, siteId, data) => ({
  type: UPDATE_COMPONENT,
  id,
  siteId,
  data,
});

export const deleteComponent = (id, siteId) => ({
  type: DELETE_COMPONENT,
  id,
  siteId,
});
export const deleteNonSiteComponents = (org, siteId) => ({
  type: DELETE_NONSITE_COMPONENTS,
  org,
  siteId,
});

const receiveCreatedComponent = (id, parent) => ({
  type: CREATE_COMPONENT_SUCCESS,
  id,
  parent,
});

export const downloadComponentsExcel = (site) => ({
  type: DOWNLOAD_COMPONENTS_TEMPLATE,
  site,
});

export const setImportErrors = (errors) => ({
  type: COMPONENT_IMPORT_ERRORS,
  errors,
});

export const resetImportedComponentsAt = () => ({
  type: RESET_COMPONENTS_IMPORTED_AT,
});

export const setPageComponents = (pageComponents) => ({
  type: SET_PAGE_COMPONENTS,
  pageComponents,
});

/** ********************************************
 *                                             *
 *                Initial State                *
 *                                             *
 ******************************************** */

const initialState = {
  components: [],
  hashmap: {},
  remoteAssetInfo: {},
  siteLoaded: undefined,
  componentTree: {},
  componentsStatus: [],
  componentsUpdatedAt: 0,
  componentsImportedAt: 0,
  createdComponent: {},
  importErrors: [],
  pageComponents: [],
};

/** ********************************************
 *                                             *
 *                   Reducers                  *
 *                                             *
 ********************************************* */

export function reducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_COMPONENTS: {
      return {
        ...state,
        components: action.components,
        hashmap: action.hashmap,
        componentTree: action.componentTree,
        siteLoaded: action.siteId,
        componentsUpdatedAt: action.componentsUpdatedAt,
        componentsImportedAt: action.componentsImportedAt,
      };
    }
    case RECEIVE_REMOTE_ASSET_INFO: {
      const { componentId, assetInfo } = action;
      return {
        ...state,
        remoteAssetInfo: {
          ...state.remoteAssetInfo,
          [componentId]: assetInfo,
        },
      };
    }
    case RECEIVE_STATUS: {
      return { ...state, componentsStatus: action.componentsStatus };
    }
    case CREATE_COMPONENT_SUCCESS: {
      return { ...state, createdComponent: { id: action.id, parent: action.parent } };
    }
    case SET_PAGE_COMPONENTS: {
      return { ...state, pageComponents: action.pageComponents };
    }
    case RESET_COMPONENTS_IMPORTED_AT: {
      return { ...state, componentsImportedAt: 0 };
    }
    case RESET_COMPONENTS:
    case CLEAR_SITE_DATA: {
      // ***IMPORTANT***
      // Explicitly resetting each piece of state here because we've experienced
      // issues with stale state (in visualizations, specifically) - even when returning
      // initialState, using a spread copy of initialState as default state,
      // and/or returning a spread copy of initialState.
      return {
        ...state,
        components: [],
        hashmap: {},
        siteLoaded: undefined,
        componentTree: {},
        componentsStatus: [],
        componentsUpdatedAt: 0,
        componentsImportedAt: 0,
        createdComponent: {},
        remoteAssetInfo: {},
        importErrors: [],
      };
    }
    case COMPONENT_IMPORT_ERRORS: {
      return { ...state, importErrors: action.errors };
    }
    default:
      return state;
  }
}

/** ********************************************
 *                                             *
 *                   Helpers                   *
 *                                             *
 ********************************************* */

const findActiveAncestorIds = (comp, comps, iteration = 0) => {
  const active = [];
  if (iteration !== 0) {
    active.push(comp.id);
  }
  if (comp.parent) {
    active.push(comp.parent);
    const parent = comps.find((c) => c.id === comp.parent);
    if (parent) {
      active.push(...findActiveAncestorIds(parent, comps, iteration + 1));
    }
  }
  return active;
};

const findActiveDescendantIds = (comp, node, found = false) => {
  const active = [];
  let add = found;
  if (add) {
    active.push(node.id);
  }
  if (node.id === comp.id) {
    add = true;
  }
  if (node.children) {
    node.children.forEach((child) => active.push(...findActiveDescendantIds(comp, child, add)));
  }
  return active;
};

const getStatuses = (compStatuses, compId) => {
  const original = compStatuses.find((s) => s.id === compId);

  const errors = compStatuses.filter((s) => s.status === 2);
  const warns = compStatuses.filter((s) => s.status === 1);
  const oks = compStatuses.filter((s) => s.status === 0);
  const riskStatus = compStatuses
    .filter((s) => s.id === compId && s.eventRiskStatus?.length > 0)
    .sort((x, y) => y.eventDateTime - x.eventDateTime);

  if (original && original.status === 2) {
    return [
      {
        ...original,
        eventRiskStatus: [],
      },
      {
        ...original,
        errors: errors.length,
        warns: warns.length,
        oks: oks.length,
        eventRiskStatus: riskStatus[0]?.eventRiskStatus,
      },
    ];
  }
  if (errors.length) {
    return [
      original,
      {
        ...errors[0],
        errors: errors.length,
        warns: warns.length,
        oks: oks.length,
      },
    ];
  }

  if (original && original.status === 1) {
    return [
      {
        ...original,
        eventRiskStatus: [],
      },
      {
        ...original,
        warns: warns.length,
        oks: oks.length,
        eventRiskStatus: riskStatus[0]?.eventRiskStatus,
      },
    ];
  }
  if (warns.length) {
    return [
      original,
      {
        ...warns[0],
        warns: warns.length,
        oks: oks.length,
      },
    ];
  }

  if (original && original.status === 0) {
    return [
      {
        ...original,
        eventRiskStatus: [],
      },
      { ...original, oks: oks.length, eventRiskStatus: riskStatus[0]?.eventRiskStatus },
    ];
  }
  if (oks.length) {
    return [original, { ...oks[0], oks: oks.length }];
  }
  if (riskStatus.length) {
    return [original, { ...original, ...riskStatus[0]?.eventRiskStatus }];
  }

  return [undefined, undefined];
};

const buildComponentsWithStatus = (components = [], healthStatuses = []) => {
  const healthByCompId = {};
  healthStatuses.forEach((status) => {
    const { id: compId, status: healthStatus } = status;
    if (healthStatus !== null) {
      const comp = components.find((c) => c.id === compId);
      if (comp) {
        const ids = [compId, ...comp.ancestorIds];
        ids.forEach((id) => {
          healthByCompId[id] = [...(healthByCompId[id] || []), status];
        });
      }
    }
  });
  const mapped = components.map((comp) => {
    const [ownStatus, filteredStatus] = getStatuses(healthByCompId[comp.id] || [], comp.id);
    return {
      ...comp,
      ownStatus,
      filteredStatus,
    };
  });
  return mapped;
};

const getScopeKey = (scope) => {
  const { selectedScope = 'all', activeComponentTypeFilter = 'none' } = scope;
  return `${selectedScope}-${activeComponentTypeFilter}`;
};

// Uses re-reselect's caching strategy for selectors to cache scoped comps
export const getScopedComponents = createCachedSelector(
  (argsObj) => argsObj,
  ({ components, activeComponentId, componentScope }) => {
    const { selectedScope, activeComponentTypeFilter } = JSON.parse(componentScope);
    const activeComponent = components.find((c) => c.id === activeComponentId);

    if (activeComponent && COMPONENT_SCOPES.includes(selectedScope) && selectedScope !== 'all') {
      // Does not match filter
      if (
        activeComponentTypeFilter !== 'any' &&
        activeComponent.type !== activeComponentTypeFilter
      ) {
        return [];
      }

      if (selectedScope === 'active_children_all' || selectedScope === 'active_children_none') {
        return [
          activeComponent,
          ...activeComponent.descendantIds.map((childId) =>
            components.find(({ id }) => id === childId)
          ),
        ];
      }
      return [activeComponent];
    }

    if (selectedScope?.includes('all')) {
      if (activeComponentTypeFilter !== 'any') {
        return components.filter((c) => c.type === activeComponentTypeFilter);
      }
      return components;
    }

    if (selectedScope?.includes('none')) {
      return [];
    }

    return components;
  }
)((argsObj) => {
  const { componentsUpdatedAt, activeComponentId, componentScope } = argsObj;
  const scopeKey = getScopeKey(JSON.parse(componentScope));
  return `${componentsUpdatedAt}-${activeComponentId || ''}-${scopeKey}`;
});

export const generatePathToId = (tree, id) => {
  let path = [];

  if (!tree.children) return path;

  const iterator = (p) => (object) => {
    if (object.id === id) {
      path = p.concat(object.id);
      return true;
    }
    return Array.isArray(object.children) && object.children.some(iterator(p.concat(object.id)));
  };

  tree.children.some(iterator([]));

  return path;
};

/** ********************************************
 *                                             *
 *                  Selectors                  *
 *                                             *
 ********************************************* */

export const getComponentsLoaded = (state) => state.components.siteLoaded;

export const getStaticComponents = (state) => state.components.components;

export const getSiteComponent = createSelector(getStaticComponents, (components) =>
  components.find((component) => component.type === 'site')
);

export const checkHasSiteComponentModel = createSelector(
  [(_, siteId) => siteId, getComponentsLoaded, getSiteComponent],
  (siteId, siteLoaded, siteComponent) => {
    if (siteLoaded !== siteId || !siteComponent) {
      return false;
    }
    return siteComponent.models.length;
  }
);

export const getComponentTree = (state) => state.components.componentTree;

export const getComponentsUpdatedAt = (state) => state.components.componentsUpdatedAt;

export const getComponentsImportedAt = (state) => state.components.componentsImportedAt;

export const getComponentHashmap = (state) => state.components.hashmap;

export const getRemoteAssetInfo = (state) => state.components.remoteAssetInfo;

export const getCreatedComponent = (state) => state.components.createdComponent;

export const getComponentsStatus = (state) => state.components.componentsStatus;

export const getComponents = createSelector(
  [getStaticComponents, getComponentsStatus],
  buildComponentsWithStatus
);

export const getActiveComponent = createSelector(
  [getComponentHashmap, getActiveComponentId],
  (hashmap, activeId) => hashmap[activeId]
);

export const getActiveComponentRemoteAssetInfo = createSelector(
  [getRemoteAssetInfo, getActiveComponentId],
  (remoteAssetInfo, activeId) => remoteAssetInfo[activeId]
);

export const getFilteredActiveComponentId = createSelector(
  [getActiveComponent, getActiveComponentFilter],
  (activeComp, acFilter) => {
    let ac = (activeComp || {}).id || null;

    if (acFilter && acFilter.active) {
      if (activeComp && acFilter.filterType === 'include') {
        if (
          (acFilter.types.length && acFilter.types.includes(activeComp.type)) ||
          (acFilter.components.length && acFilter.components.includes(activeComp.id))
        ) {
          ac = null;
        }
      }

      if (activeComp && acFilter.filterType === 'exclude') {
        if (
          (acFilter.types.length && !acFilter.types.includes(activeComp.type)) ||
          (acFilter.components.length && !acFilter.components.includes(activeComp.id))
        ) {
          ac = null;
        }
      }
    }
    return ac;
  }
);

export const getImportErrors = (state) => state.components.importErrors;

export const getPageComponents = (state) => state.components.pageComponents;

/** ********************************************
 *                                             *
 *                    Sagas                    *
 *                                             *
 ********************************************* */

function* doGetComponentsStatus(siteId) {
  try {
    const { values: newHealth } = yield call(getComponentsStatusApi, siteId);

    const oldHealth = yield select(getComponentsStatus);
    const changed = JSON.stringify(oldHealth) !== JSON.stringify(newHealth);

    if (changed) {
      yield put(receiveStatus(newHealth));
    }
  } catch (e) {
    console.error('Unable to fetch component status: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getComponentsStatus', 'error')()));
  }
}

function* doForceGetComponentsStatus(action) {
  yield put(setPollingActive('components'));
  yield call(doGetComponentsStatus, action.siteId);
  yield put(setPollingActiveDone('components'));
}

export function* doRequestComponents(action) {
  const { siteId, imported = false } = action;

  try {
    const { values: newComps } = yield call(getComponentsApi, siteId);
    const { data: tree } = yield call(getSiteComponentTree, siteId);

    if (newComps && newComps.length) {
      const updatedHashmap = {};
      newComps.forEach((comp) => {
        updatedHashmap[comp.id] = comp;
      });
      const compsWithRelations = yield all(
        newComps.map((comp) => ({
          ...comp,
          ancestorIds: findActiveAncestorIds(comp, newComps),
          descendantIds: findActiveDescendantIds(comp, tree),
        }))
      );

      yield put(
        receiveComponents(
          compsWithRelations,
          updatedHashmap,
          tree,
          siteId,
          imported ? Date.now() : 0
        )
      );
    } else {
      yield put(receiveComponents([], {}, {}, siteId, 0));
    }
  } catch (e) {
    console.error('Unable to fetch components: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('getComponents', 'error')()));
    yield put(receiveComponents([], {}, {}, siteId, 0));
  }
}

function* doCrudComponentAttributes(
  { updates = [], creates = [], deletes = [] },
  component,
  action
) {
  const sources = yield select(getSources);
  const internalSource = sources.find((source) => source.type === INTERNAL_DATA_SOURCE);

  if (!internalSource) {
    yield put(displayNotification(getNotification('noInternalSource', 'error')()));
    return false;
  }

  if (!component.asset?.id) {
    yield put(displayNotification(getNotification('noComponentAsset', 'error')()));
    return false;
  }

  try {
    yield all(
      updates.map((variableData) =>
        call(updateVariableApi, internalSource.id, variableData.id, variableData)
      )
    );
  } catch (e) {
    console.error('Unable to update component attributes: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('updateComponentAttributes', 'error')(
          component.name,
          component.itemDesignation,
          action
        )
      )
    );
    return false;
  }

  try {
    const { errors } = yield call(createVariablesApi, internalSource.id, creates);
    if (errors.length) {
      throw new Error(JSON.stringify(errors));
    }
  } catch (e) {
    console.error('Unable to create component attributes: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('createComponentAttributes', 'error')(
          component.name,
          component.itemDesignation,
          action
        )
      )
    );
    return false;
  }

  try {
    yield all(
      deletes.map((variableDeleteId) =>
        call(deleteVariableApi, internalSource.id, variableDeleteId)
      )
    );
  } catch (e) {
    console.error('Unable to delete component attributes: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('deleteComponentAttributes', 'error')(
          component.name,
          component.itemDesignation,
          action
        )
      )
    );
    return false;
  }
  return true;
}

export function* doCreateComponent(action) {
  const { siteId, org, data, doNotify } = action;
  const { attributes, component } = data;
  let newComponent;
  let handledAttributes;

  try {
    newComponent = yield createComponentApi({ ...component, site: siteId, org });
  } catch (e) {
    console.error('Unable to create component: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('createComponent', 'error')(component.name)));
    return undefined;
  }

  if (attributes) {
    //  Need to inject asset_id from new comp when creating.
    if (attributes?.creates) {
      attributes.creates = attributes.creates.map((item) => ({
        ...item,
        asset_id: newComponent.asset?.id,
      }));
    }

    handledAttributes = yield call(doCrudComponentAttributes, attributes, newComponent, 'create');
  } else {
    handledAttributes = true;
  }

  if (handledAttributes) {
    yield doRequestComponents({ siteId });
    yield put(receiveCreatedComponent(newComponent.id, newComponent.parent));
    if (doNotify) {
      yield put(
        displayNotification(
          getNotification('createComponent', 'success')(component.name, component.itemDesignation)
        )
      );
    }
  }
  return newComponent;
}

export function* doUpdateComponent(action) {
  const { id, siteId, data } = action;
  const { attributes, component } = data;
  const componentObject = yield select((state) => state.components.hashmap[id]);

  let updatedComponent;
  let handledAttributes;

  try {
    updatedComponent = yield updateComponentApi(id, component);
  } catch (e) {
    console.error('Unable to update component: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('updateComponent', 'error')(
          componentObject.name,
          componentObject.itemDesignation,
          id
        )
      )
    );
  }

  if (updatedComponent && attributes) {
    handledAttributes = yield call(
      doCrudComponentAttributes,
      attributes,
      updatedComponent,
      'update'
    );
  } else if (updatedComponent) {
    handledAttributes = true;
  }

  if (handledAttributes) {
    yield doRequestComponents({ siteId });
    yield put(
      displayNotification(
        getNotification('updateComponent', 'success')(
          componentObject.name,
          componentObject.itemDesignation,
          id
        )
      )
    );
    yield call(doForceGetComponentsStatus, { siteId });
  }
}

export function* doUpsertComponents(action) {
  const { siteId, org, components, doNotify } = action;

  try {
    const jsonBlob = new Blob([JSON.stringify(components)], { type: 'application/json' });
    const fd = new FormData();
    fd.append('file', jsonBlob, 'data.json');
    fd.append('site', siteId);
    fd.append('org', org);

    yield upsertComponentsInBulkApi(fd);
    yield doRequestComponents({ siteId, imported: true });
    yield call(doForceGetComponentsStatus, { siteId });
    if (doNotify) {
      yield put(displayNotification(getNotification('upsertComponents', 'success')()));
    }
  } catch (e) {
    yield call(checkOnline);
    yield put(
      setImportErrors(
        e.errors && e.errors.upsertErrors
          ? e.errors.upsertErrors
          : ['An error occured when updating/creating components']
      )
    );
    if (doNotify) {
      yield put(displayNotification(getNotification('upsertComponents', 'error')()));
    }
  }
}

function* doDeleteComponent(action) {
  const { id, siteId } = action;
  const componentObject = yield select((state) => state.components.hashmap[id]);
  try {
    yield deleteComponentApi(id);
    yield doRequestComponents({ siteId });
    yield put(
      displayNotification(
        getNotification('deleteComponent', 'success')(
          componentObject.name,
          componentObject.itemDesignation,
          id
        )
      )
    );
  } catch (e) {
    console.error('Unable to delete component: ', e);
    yield call(checkOnline);
    yield put(
      displayNotification(
        getNotification('deleteComponent', 'error')(
          componentObject.name,
          componentObject.itemDesignation,
          id
        )
      )
    );
  }
}
function* doDeleteNonSiteComponents(action) {
  const { org, siteId } = action;
  try {
    yield call(deleteNonSiteComponentsApi, { org, site: siteId });
    yield doRequestComponents({ siteId });
    yield put(displayNotification(getNotification('deleteNonSiteComponents', 'success')));
  } catch (e) {
    console.error('Unable to delete nonsite components: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('deleteNonSiteComponents', 'error')()));
  }
}

function* doDownloadCompsTemplate(action) {
  const { site } = action;
  try {
    const file = yield call(downloadCompsExcelApi, { site: site.id, org: site.org });
    downloadBlob(
      file.data,
      `${site.name.replace(/\s/g, '_')}-components_template.xlsx`,
      file.headers['content-type']
    );
  } catch (e) {
    console.error('Unable to download components excel: ', e);
    yield call(checkOnline);
    yield put(displayNotification(getNotification('downloadCompExcel', 'error')()));
  }
}

export function* doRequestRemoteAssetInfo(action) {
  const { integrationIds, componentId } = action;
  try {
    const assetInfo = yield all(
      integrationIds.map((id) => call(getRemoteAssetInfoApi, id, componentId))
    );

    const mappedAssetInfo = assetInfo
      // TODO: uncomment these lines when we have implemented integration-specific display for remote info
      // .map((info, i) => ({ integrationId: integrationIds[i], ...assetInfo }))
      .reduce((acc, info) => {
        if (Object.keys(info).length > 1) {
          return { ...acc, ...info };
          // return {
          //   ...acc,
          //   [info.integrationId]: info,
          // };
        }
        return acc;
      }, {});
    yield put(receiveRemoteAssetInfo(componentId, mappedAssetInfo));
  } catch (e) {
    console.error(e);
    return undefined;
  }
  return undefined;
}

export function* pollForSiteComponent(siteId) {
  while (true) {
    const { values: comps } = yield call(getComponentsApi, siteId);
    if (comps.some((comp) => comp.type === 'site')) {
      break;
    }
    yield delay(500);
  }
}

export const sagas = [
  takeLatest(REQUEST_COMPONENTS, doRequestComponents),
  takeLatest(REQUEST_REMOTE_ASSET_INFO, doRequestRemoteAssetInfo),

  takeLatest(CREATE_COMPONENT, doCreateComponent),
  takeLatest(UPDATE_COMPONENT, doUpdateComponent),
  takeLatest(UPSERT_COMPONENTS, doUpsertComponents),
  takeEvery(DELETE_COMPONENT, doDeleteComponent),
  takeEvery(DELETE_NONSITE_COMPONENTS, doDeleteNonSiteComponents),

  takeLatest(DOWNLOAD_COMPONENTS_TEMPLATE, doDownloadCompsTemplate),

  debounce(500, REFRESH_VALUES, doForceGetComponentsStatus),
];
