const getComponentsPanelSchema = (components) => {
  const schema = {
    title: {
      type: 'string',
      default: 'Components',
    },
    options: {
      type: 'object',
      properties: {
        allowFloat: {
          type: 'boolean',
          title: 'Allow floating',
          default: false,
        },
        withToolbar: {
          type: 'boolean',
          title: 'Include panel tools',
          default: false,
        },
        displayStatus: {
          type: 'boolean',
          title: 'Show component status',
          default: false,
        },
        autoLoad: {
          type: 'boolean',
          title: 'Load model on selection',
          default: false,
        },
        flatList: {
          type: 'boolean',
          title: 'Show as flat list',
          default: false,
        },
        showVirtuals: {
          type: 'boolean',
          title: 'Show area type components',
          default: false,
        },
        startingDepth: {
          type: 'number',
          title: 'Starting depth (site = 1)',
          minimum: 1,
          placeholder: 1,
          default: 1,
        },
        endingDepth: {
          type: 'number',
          title: 'Ending depth (no limit = 0)',
          minimum: 0,
          placeholder: 0,
          default: 0,
        },
        expandedDepth: {
          type: 'number',
          title: 'Default expansion depth (all expanded = 0)',
          minimum: 0,
          placeholder: 0,
          default: 0,
        },
      },
    },
  };

  if (components.length) {
    const componentOptions = {
      type: 'string',
      anyOf: components.map((c) => ({
        enum: [c.id],
        title:
          c.itemDesignation !== '-'
            ? `[${c.itemDesignation.replace('=', '')}] ${c.name}`
            : `${c.name}`,
      })),
    };

    schema.options.properties.limitComponents = {
      type: 'array',
      title: 'Limit to specific components.',
      default: [],
      uniqueItems: true,
      isMulti: true,
      items: componentOptions,
    };

    schema.options.properties.excludeComponents = {
      type: 'array',
      title: 'Exclude specific components.',
      default: [],
      uniqueItems: true,
      isMulti: true,
      items: componentOptions,
    };
  }

  return schema;
};

export default getComponentsPanelSchema;
