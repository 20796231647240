const schema = {
  type: 'object',
  required: ['name', 'isAreaStateSet'],
  properties: {
    name: {
      type: 'string',
      title: 'State set name',
    },
    isAreaStateSet: {
      type: 'boolean',
      title: 'Area component',
      default: false,
    },
  },
  dependencies: {
    isAreaStateSet: {
      oneOf: [
        {
          properties: {
            isAreaStateSet: {
              enum: [true],
            },
            states: {
              title: 'state',
              type: 'object',
              required: ['name', 'text', 'color', 'description'],
              properties: {
                name: {
                  type: 'string',
                  title: 'State name',
                },
                text: {
                  type: 'string',
                  title: 'Text',
                },
                description: {
                  type: 'string',
                  title: 'Description / Suggestion',
                },
                color: {
                  type: 'string',
                  title: 'Color',
                  inputType: 'color',
                  default: '#3366ff',
                },
              },
            },
          },
        },
        {
          properties: {
            isAreaStateSet: {
              enum: [false],
            },
            states: {
              title: 'states',
              type: 'array',
              items: {
                type: 'object',
                required: ['name', 'text', 'color', 'description', 'trigger'],
                properties: {
                  name: {
                    type: 'string',
                    title: 'State name',
                  },
                  text: {
                    type: 'string',
                    title: 'Text',
                  },
                  description: {
                    type: 'string',
                    title: 'Description / Suggestion',
                  },
                  color: {
                    type: 'string',
                    title: 'Color',
                    inputType: 'color',
                    default: '#3366ff',
                  },
                  trigger: {
                    $ref: '#/definitions/trigger',
                  },
                },
              },
            },
          },
        },
      ],
    },
  },
  definitions: {
    trigger: {
      title: '',
      type: 'object',
      properties: {
        condition: {
          title: 'Trigger condition',
          type: 'string',
          default: '=',
          oneOf: [
            { enum: ['='], title: 'equal =' },
            { enum: ['<>'], title: 'between <>' },
            { enum: ['>'], title: 'greater than >' },
            { enum: ['<'], title: 'less than <' },
            { enum: ['<='], title: 'less than or equal to <=' },
            { enum: ['>='], title: 'greater than or equal to >=' },
          ],
        },
      },
      allOf: [
        {
          if: {
            properties: {
              condition: {
                enum: ['<>'],
              },
            },
            required: ['condition'],
          },
          then: {
            required: ['triggerValue1', 'triggerValue2', 'condition'],
            properties: {
              triggerValue1: {
                type: 'number',
                title: 'Trigger start value',
              },
              triggerValue2: {
                type: 'number',
                title: 'Trigger end value',
              },
            },
          },
          else: {
            required: ['triggerValue', 'condition'],
            properties: {
              triggerValue: {
                type: 'number',
                title: 'Trigger value',
              },
            },
          },
        },
      ],
    },
  },
};

const uiSchema = (s) => {
  const hasType = s
    ? {
        isAreaStateSet: { 'ui:widget': 'hidden' },
      }
    : null;

  return {
    ...hasType,
    definitions: {
      'ui:options': {
        addable: false,
      },
    },
  };
};

export { uiSchema, schema };
