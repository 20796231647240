import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input, Spinner } from '@iq/react-components';
import CustomSelect from '../../CustomSelect';
import ComponentModal from './components/ComponentModal';
import QrModal from './components/QrModal';
import QrCodeIcon from '../../QrCodeIcon';
import ListItem from '../../ListItem';
import {
  requestComponents,
  getComponentsLoaded,
  getComponents,
  deleteComponent,
  updateComponent,
  createComponent,
} from '../../../bundles/components';
import { requestStateSets } from '../../../bundles/statesets';
import { getAllIntegrations } from '../../../bundles/integrations';
import { getActiveSite } from '../../../bundles/sites';
import { requestModels, getAllModels } from '../../../bundles/models';
import { requestSources, getSources, getSourceTypes } from '../../../bundles/sources';
import Heading from '../../Heading';
import prepData from './utils';
import { useClientSize } from '../../../utils';
import { setSelectedQRS } from '../../../bundles/qrCodes';

const ComponentView = () => {
  const dispatch = useDispatch();
  const [{ width }, clientRef] = useClientSize();
  const { id: siteId, org } = useSelector(getActiveSite);
  const modelsHashmap = useSelector(getAllModels);
  const componentsLoaded = useSelector(getComponentsLoaded);
  const components = useSelector(getComponents);
  const integrations = useSelector(getAllIntegrations);
  const sources = useSelector(getSources);
  const sourceTypes = useSelector(getSourceTypes);

  const [searchFilter, setSearchFilter] = useState('');
  const [relationFilter, setRelationFilter] = useState('all');
  const [integrationFilter, setIntegrationFilter] = useState('none');
  const [sortBy, setSortBy] = useState({ sortKey: 'name', desc: true });
  const [allSelected, setAllSelected] = useState(false);
  const [anySelected, setAnySelected] = useState(false);
  const [sortedComps, setSortedComps] = useState([]);

  const [updating, setUpdating] = useState({ show: false, componentId: null });
  const [creating, setCreating] = useState({ show: false });
  const [qrModal, setQrModal] = useState({ show: false });

  useEffect(() => {
    if (siteId) {
      dispatch(requestStateSets(siteId));
      dispatch(requestComponents(siteId));
      dispatch(requestModels(siteId));
      dispatch(requestSources({ siteId, withVariables: false }));
    }
  }, [siteId]);

  const componentsWithSelect = useMemo(
    () => components.map((c) => ({ selected: false, ...c })),
    [components]
  );

  const relationFilteredComponents = useMemo(() => {
    if (componentsWithSelect && relationFilter !== 'all') {
      return componentsWithSelect.filter((c) => {
        switch (relationFilter) {
          case 'integrated-active': {
            return Object.values(c.integrationOptions || {}).some((i) => i.enabled);
          }
          case 'integrated-stale': {
            if (!c.integrationOptions) return false;
            return Object.keys(c.integrationOptions).length === 0;
          }
          case 'models': {
            return c.models.length > 0;
          }
          case 'event-sources': {
            return c.eventSources?.length > 0;
          }
          case 'source': {
            return !!c.source;
          }
          case 'signals': {
            return c.variables.length > 0;
          }
          case 'stateset': {
            return !!c.stateset;
          }
          case 'area': {
            return c.type === 'virtual';
          }
          default: {
            return true;
          }
        }
      });
    }
    return componentsWithSelect;
  }, [componentsWithSelect, relationFilter]);

  const integrationFilteredComponents = useMemo(() => {
    if (relationFilteredComponents && integrationFilter !== 'none') {
      return relationFilteredComponents.filter((c) =>
        Object.keys(c.integrationOptions || {}).includes(integrationFilter)
      );
    }
    return relationFilteredComponents;
  }, [relationFilteredComponents, integrationFilter, integrations]);

  const searchedComps = useMemo(() => {
    if (searchFilter) {
      const filters = searchFilter.trim().split(' ');
      return integrationFilteredComponents.filter(
        (c) =>
          filters.every((f) => c.name.toLowerCase().indexOf(f.toLowerCase()) !== -1) ||
          filters.every(
            (f) =>
              c.itemDesignation && c.itemDesignation.toLowerCase().indexOf(f.toLowerCase()) !== -1
          )
      );
    }
    return integrationFilteredComponents;
  }, [integrationFilteredComponents, searchFilter]);

  const nameSort = (values, attr, descending = false, shouldReturn = false) => {
    let nameSorted = [];
    if (!shouldReturn) {
      nameSorted = descending
        ? values.sort((a, b) => a.name.localeCompare(b.name))
        : values.sort((a, b) => b.name.localeCompare(a.name));
    }

    if (attr === 'name') {
      return nameSorted;
    }
    if (attr === 'itemDesignation') {
      if (shouldReturn) {
        return descending
          ? values.sort((a, b) => (a.itemDesignation || '').localeCompare(b.itemDesignation || ''))
          : values.sort((a, b) => (b.itemDesignation || '').localeCompare(a.itemDesignation || ''));
      }
      return nameSort(nameSorted, 'itemDesignation', descending, true);
    }
    return values;
  };

  useEffect(() => {
    if (sortBy.sortKey) {
      setSortedComps(() => [...nameSort(searchedComps, sortBy.sortKey, sortBy.desc)]);
    }
  }, [sortBy, searchedComps]);

  const count = sortedComps.length ? `(${sortedComps.length})` : '';

  const relationalFilterOptions = [
    { value: 'all', label: 'All components' },
    { value: 'integrated-active', label: 'Has active integrations' },
    { value: 'integrated-stale', label: 'Has inactive integrations' },
    { value: 'models', label: 'Has models' },
    { value: 'event-sources', label: 'Has event sources' },
    { value: 'source', label: 'Has a data source' },
    { value: 'signals', label: 'Has signals' },
    { value: 'stateset', label: 'Has an area state' },
    { value: 'area', label: 'Is an area type' },
  ];

  const integrationFilterOptions = [
    { value: 'none', label: 'No integration filter' },
    ...integrations.map((i) => ({ value: i.id, label: i.name })),
  ];

  const getSourceConfig = useCallback(
    (sourceId) => {
      if (sources && sourceTypes) {
        const selectedSource = sources.find((source) => source.id === sourceId);
        const selectedSourceType = sourceTypes.find(
          (sourceType) => sourceType.name === selectedSource.type
        );
        if (selectedSourceType?.schemas.config.properties.objectId) {
          return { objectId: selectedSource.options?.iotDevice?.objectId };
        }
        return null;
      }
      return null;
    },
    [sources, sourceTypes]
  );

  const prepCompFormData = (data) => {
    const { attributeData, ...componentData } = data;
    if (componentData.source) {
      const sourceConfig = getSourceConfig(componentData.source);
      if (sourceConfig) {
        componentData.sourceConfig = sourceConfig;
      }
    }

    return {
      component: prepData(componentData),
      attributes: attributeData,
    };
  };

  const handleUpdateComponent = useCallback(
    (formData) => {
      const preparedData = prepCompFormData(formData);
      dispatch(updateComponent(updating.componentId, siteId, preparedData));
      setUpdating({
        show: false,
        componentId: null,
        component: null,
      });
    },
    [updating]
  );

  const handleCloseUpdating = useCallback(() => {
    setUpdating({
      show: false,
      componentId: null,
      component: null,
    });
  }, []);

  const onClickCreate = () => {
    const siteCompId = components.find((c) => c.type === 'site')?.id;
    setCreating(() => ({ show: true, parent: siteCompId }));
  };

  const handleCreateComponent = useCallback(
    (formData) => {
      const preparedData = prepCompFormData(formData);
      dispatch(createComponent(siteId, org, preparedData));
      setCreating({ show: false });
    },
    [sources, sourceTypes]
  );

  const handleCloseCreating = useCallback(() => setCreating({ show: false }), []);
  const handleCloseQR = useCallback(() => setQrModal({ show: false }), []);

  const onDeleteComponent = useCallback(
    (componentId) => dispatch(deleteComponent(componentId, siteId)),
    [siteId]
  );

  const handleSort = (sortKey) => {
    const desc = sortBy.sortKey === sortKey ? !sortBy.desc : true;
    setSortBy(() => ({ sortKey, desc }));
  };

  const colWidths = {
    type: 'percent',
    widths: width < 1700 ? ['-', 40, 15, 9, 9, 9, 9, 9, 9] : ['-', 25, 13, 12, 12, 12, 12, 12, 12],
  };

  const headers = [
    { label: '' },
    { sortKey: 'name', label: 'Component name' },
    { sortKey: 'itemDesignation', label: 'Item designation' },
    { icon: 'he-link', label: 'Integrations' },
    { icon: 'he-storage', label: 'Data sources' },
    { icon: 'he-storage', label: 'Event sources' },
    { icon: 'he-cube', label: 'Models' },
    { icon: 'he-signal', label: 'Signals' },
    { icon: 'he-states', label: 'Area state' },
  ];

  const headerColumns = headers.map((h, i) => {
    const onHeaderClick = h.sortKey ? () => handleSort(h.sortKey) : undefined;
    const key = `${h.sortKey || h.label}-${i}`;
    if (!h.sortKey && !h.icon) {
      return (
        <div
          key={key}
          className="ellipsed-text"
        >
          {h.label}
        </div>
      );
    }

    let icon;
    if (h.sortKey) {
      const sortIcon = sortBy.sortKey === h.sortKey && !sortBy.desc ? 'he-up' : 'he-down';
      icon = <Icon icon={sortIcon} />;
    }

    let headerTextOrIcon = h.label;
    if (!h.sortKey && h.icon && width < 1700) {
      headerTextOrIcon = (
        <Icon
          size={'s'}
          icon={h.icon}
        />
      );
    }

    const buttonClass = !h.sortKey ? 'button-header' : '';

    return (
      <Button
        key={key}
        onClick={onHeaderClick}
        icon={icon}
        iconPosition="right"
        activity="secondary"
        className={buttonClass}
        tooltip={h.icon && h.label}
      >
        {headerTextOrIcon}
      </Button>
    );
  });

  const toggleAllSelected = () => {
    if (allSelected) {
      setSortedComps(sortedComps.map((component) => ({ ...component, selected: false })));
      setAnySelected(false);
    } else {
      setSortedComps(sortedComps.map((component) => ({ ...component, selected: true })));
      setAnySelected(true);
    }
    setAllSelected(!allSelected);
  };
  const toggleSelect = (id) => {
    const comps = sortedComps.map((component) =>
      component.id === id ? { ...component, selected: !component.selected } : component
    );
    setSortedComps(comps);
    setAnySelected(comps.some((component) => component.selected === true));
  };
  const openQrModal = () => {
    dispatch(
      setSelectedQRS(
        sortedComps
          .filter((component) => component.selected === true)
          .map((component) => ({
            id: component.id,
            name: component.name,
            itemDesignation: component.itemDesignation,
          }))
      )
    );
    setQrModal({ show: true });
  };

  const getListItemColumns = (component) => [
    <div
      key={`${component.id}-col-1`}
      className="ellipsed-text"
      onClick={() => {
        toggleSelect(component.id);
      }}
    >
      <Icon
        className={`select-all ${component.selected ? 'checked' : ''}`}
        icon={`${component.selected ? 'he-checkbox-selected' : 'he-checkbox'}`}
        size="s"
      />
    </div>,
    <div key={`${component.id}-col-2`}>{component.name}</div>,
    <div
      key={`${component.id}-col-3`}
      className="blocked-item"
    >
      {component.itemDesignation}
    </div>,
    <div key={`${component.id}-col-4`}>
      {Object.keys(component.integrationOptions || {}).length || ''}
    </div>,
    <div key={`${component.id}-col-5`}>{component.source ? 1 : ''}</div>,
    <div key={`${component.id}-col-6`}>{component.eventSources?.length || ''}</div>,
    <div key={`${component.id}-col-7`}>{component.models.length || ''}</div>,
    <div key={`${component.id}-col-8`}>{component.variables.length || ''}</div>,
    <div key={`${component.id}-col-9`}>{component.stateset ? 1 : ''}</div>,
  ];

  const componentList = useMemo(() => {
    if (!componentsLoaded) {
      return (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      );
    }
    return sortedComps.map((c, i) => (
      <ListItem
        key={c.id}
        itemIndex={i}
        entity={`Component (${c.itemDesignation || '<itemDesignation>'})`}
        item={c}
        columns={getListItemColumns(c)}
        columnWidths={colWidths}
        customEdit
        onEdit={() => setUpdating(() => ({ show: true, componentId: c.id }))}
        onDelete={() => onDeleteComponent(c.id)}
        confirmationDialogTitle="Remove Component"
        confimationDialogBody={
          <>
            <p>
              {'This will also remove all child components, variables, event links and references ' +
                'associated with the component.'}
            </p>
            <p style={{ paddingTop: '1.5rem' }}>
              This is a destructive action and cannot be un-done.
            </p>
          </>
        }
        withSelect
      />
    ));
  }, [sortedComps, componentsLoaded, colWidths]);

  return (
    <>
      <Heading
        contentLeft={
          <div className="components-header">
            <div className="title">Components</div>
            <Input
              type="text"
              onChange={(e) => setSearchFilter(e.target.value)}
              value={searchFilter}
              placeholder="Search name or designation"
            />

            <div className="component-filter">
              <CustomSelect
                isMulti={false}
                onChange={(selection) => setRelationFilter(selection)}
                creatable={false}
                value={relationFilter}
                rawOptions={relationalFilterOptions}
                closeMenuOnSelect={true}
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '100%',
                    height: '36px',
                    minHeight: '36px',
                  }),
                }}
              />
            </div>
            {integrations.length > 0 && (
              <div className="component-filter">
                <CustomSelect
                  isMulti={false}
                  onChange={(selection) => setIntegrationFilter(selection)}
                  creatable={false}
                  value={integrationFilter}
                  rawOptions={integrationFilterOptions}
                  closeMenuOnSelect={true}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '100%',
                      height: '36px',
                      minHeight: '36px',
                    }),
                  }}
                />
              </div>
            )}
          </div>
        }
        contentRight={
          <Button
            onClick={onClickCreate}
            className="button-create"
          >
            Add Component
          </Button>
        }
      />
      <div
        ref={clientRef}
        className="manage-components-body custom-thin-scrollbar"
      >
        <p className="components">{`Manage components ${count}`} </p>
        <div className="selection-toggler">
          <label
            className="select-all-label"
            onClick={toggleAllSelected}
          >
            <Icon
              className={`select-all ${allSelected ? 'checked' : ''}`}
              icon={`${allSelected ? 'he-checkbox-selected' : 'he-checkbox'}`}
              size="s"
            />
            Select all
          </label>
          {anySelected && (
            <Button
              className="action-button-box"
              activity="secondary"
              onClick={openQrModal}
              icon={<QrCodeIcon />}
            ></Button>
          )}
        </div>
        <div className="list-container">
          <ListItem
            isHeader
            columns={headerColumns}
            columnWidths={colWidths}
            withSelect
          />
          <div className="custom-thin-scrollbar">{componentList}</div>
        </div>
      </div>
      {qrModal.show && (
        <QrModal
          siteId={siteId}
          onCloseModal={handleCloseQR}
        />
      )}
      {(updating.show || creating.show) && (
        <ComponentModal
          modelsHashmap={modelsHashmap}
          onSubmit={updating.show ? handleUpdateComponent : handleCreateComponent}
          onCloseModal={updating.show ? handleCloseUpdating : handleCloseCreating}
          saveButtonText={updating.show ? 'Update' : 'Save'}
          updateId={updating.componentId}
          parentId={creating.parent}
        />
      )}
    </>
  );
};

export default ComponentView;
