.site-create-view-component {
  display: flex;
}
.site-create-view-component .avt-react-components--button.variation-with-icon-left .avt-react-components--icon,
.site-create-view-component .avt-react-components--button.variation-with-icon-right .avt-react-components--icon {
  height: 16px;
  width: 16px;
}
.site-create-view-component .avt-react-components--button.variation-with-icon-left .avt-react-components--icon:hover,
.site-create-view-component .avt-react-components--button.variation-with-icon-right .avt-react-components--icon:hover {
  opacity: 0.75;
}
.site-create-view-component .loader-component {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.light-theme .site-create-view-component .loader-component {
  background: rgba(242.25, 242.25, 242.25, 0.6);
}

.dark-theme .site-create-view-component .loader-component {
  background: rgba(0, 0, 0, 0.6);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL29wdC9zcmMvc2NyaXB0L2NvbXBvbmVudHMvU2l0ZUNyZWF0ZVZpZXciLCJzb3VyY2VzIjpbIlNpdGVDcmVhdGVWaWV3LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7QUFFQTtBQUFBO0VBRUU7RUFDQTs7QUFFQTtBQUFBO0VBQ0U7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFNQTtFQUNFOzs7QUFPRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnQGlxL3JlYWN0LWNvbXBvbmVudHMvZGlzdC9jb2xvcnMuc2NzcycgYXMgY29sb3JzO1xuXG4uc2l0ZS1jcmVhdGUtdmlldy1jb21wb25lbnQge1xuICBkaXNwbGF5OiBmbGV4O1xuXG4gIC5hdnQtcmVhY3QtY29tcG9uZW50cy0tYnV0dG9uLnZhcmlhdGlvbi13aXRoLWljb24tbGVmdCAuYXZ0LXJlYWN0LWNvbXBvbmVudHMtLWljb24sXG4gIC5hdnQtcmVhY3QtY29tcG9uZW50cy0tYnV0dG9uLnZhcmlhdGlvbi13aXRoLWljb24tcmlnaHQgLmF2dC1yZWFjdC1jb21wb25lbnRzLS1pY29uIHtcbiAgICBoZWlnaHQ6IDE2cHg7XG4gICAgd2lkdGg6IDE2cHg7XG5cbiAgICAmOmhvdmVyIHtcbiAgICAgIG9wYWNpdHk6IDAuNzU7XG4gICAgfVxuICB9XG5cbiAgLmxvYWRlci1jb21wb25lbnQge1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICB0b3A6IDA7XG4gICAgcmlnaHQ6IDA7XG4gICAgYm90dG9tOiAwO1xuICAgIGxlZnQ6IDA7XG4gIH1cbn1cblxuLmxpZ2h0LXRoZW1lIHtcbiAgLnNpdGUtY3JlYXRlLXZpZXctY29tcG9uZW50IHtcbiAgICAubG9hZGVyLWNvbXBvbmVudCB7XG4gICAgICBiYWNrZ3JvdW5kOiByZ2JhKGNvbG9ycy4kTGlnaHRBcHBsaWNhdGlvbkJhY2tncm91bmQsIDAuNik7XG4gICAgfVxuICB9XG59XG5cbi5kYXJrLXRoZW1lIHtcbiAgLnNpdGUtY3JlYXRlLXZpZXctY29tcG9uZW50IHtcbiAgICAubG9hZGVyLWNvbXBvbmVudCB7XG4gICAgICBiYWNrZ3JvdW5kOiByZ2JhKGNvbG9ycy4kRGFya0FwcGxpY2F0aW9uQmFja2dyb3VuZCwgMC42KTtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */