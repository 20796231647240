import React, { useEffect, useCallback, useRef } from 'react';
import { Button, Icon } from '@iq/react-components';
import { v4 as uuidv4 } from 'uuid';
import { capitalize } from '../../../../../utils';

import ColumnItem from '../ColumnItem';
import MoveItem from '../MoveItem';
import Delete from '../../../../Delete';
import JSONEditor from '../../../../JSONEditor';
import Heading from '../../../../Heading';
import TypeLabel from '../../../../TypeLabel';
import StyledItem from '../../../../StyledItem';

import sectionSchema from '../../schemas/sectionSchema';
import { displaySchema, displayUiSchema } from '../../schemas/displaySchema';

const SectionItem = ({
  activeTree = {},
  sectionIndex,
  sectionCount,
  moveSection,
  removeSection,
  updateSection,
  updateColumn,
  sectionActive,
  setActiveTreeIndexes,
  title,
  id,
  displayTitle,
  displayWhen,
  columns = [],
}) => {
  const sectionRef = useRef(null);

  const { page: activePageIndex = null, column: activeColumnIndex = null } = activeTree;

  useEffect(() => {
    if (sectionActive && sectionRef.current && activeColumnIndex === null) {
      sectionRef.current.scrollIntoView();
    }
  }, [sectionActive, activeColumnIndex, sectionRef.current]);

  const createColumn = () => {
    setActiveTreeIndexes({
      ...activeTree,
      column: columns.length,
      panel: undefined,
      initial: false,
    });
    updateSection(activePageIndex, sectionIndex, (section) => ({
      ...section,
      columns: [
        ...columns,
        {
          id: `column-${uuidv4()}`,
          title: 'Untitled column',
          panels: [],
        },
      ],
    }));
  };

  const moveColumn = (from, to) => {
    updateSection(activePageIndex, sectionIndex, (section) => {
      const column = section.columns[from];
      const updatedColumns = [...section.columns];
      updatedColumns.splice(from, 1);
      updatedColumns.splice(to, 0, column);
      return {
        ...section,
        columns: updatedColumns,
      };
    });
    setActiveTreeIndexes({
      ...activeTree,
      column: to,
      initial: false,
    });
  };

  const removeColumn = (columnIndex) => {
    setActiveTreeIndexes({
      ...activeTree,
      column: undefined,
      panel: undefined,
      initial: false,
    });
    updateSection(activePageIndex, sectionIndex, (section) => {
      const updatedColumns = [...section.columns];
      updatedColumns.splice(columnIndex, 1);
      return {
        ...section,
        columns: updatedColumns,
      };
    });
  };

  const updateSectionProps = useCallback(
    ({ formData: sectionProps }) => {
      updateSection(activePageIndex, sectionIndex, (section) => ({
        ...section,
        ...sectionProps,
      }));
    },
    [updateSection, activePageIndex, sectionIndex]
  );

  const updateDisplayProps = useCallback(
    ({ formData: newDisplaySettings }) => {
      updateSection(activePageIndex, sectionIndex, (page) => ({
        ...page,
        displayWhen: newDisplaySettings,
      }));
    },
    [updateSection, activePageIndex, sectionIndex]
  );

  const toggleSection = () => {
    if (sectionActive) {
      setActiveTreeIndexes({
        ...activeTree,
        section: undefined,
        column: undefined,
        panel: undefined,
        initial: false,
      });
    } else {
      setActiveTreeIndexes({
        ...activeTree,
        section: sectionIndex,
      });
    }
  };

  // If we upgrade to RJSFv5, we can try to get rid of the custom validation.
  const customValidate = useCallback(
    (formData, errors) => {
      sectionSchema.required.forEach((fieldName) => {
        const validValue = formData[fieldName];
        if (!validValue) {
          errors[fieldName].addError(`Section ${capitalize(fieldName)} is required`);
        }
      });
      return errors;
    },
    [sectionSchema]
  );
  // To remove duplicate error message
  const transformErrors = (errors) =>
    errors.map((error) => {
      /* eslint-disable no-return-assign, no-param-reassign */
      switch (error.name) {
        case 'required': {
          if (error.property === 'title') {
            error.message = '';
          }
          break;
        }
        default:
      }
      return error;
    });

  return (
    <StyledItem
      ref={sectionRef}
      itemClass="section-list-item"
      active={sectionActive}
      onClick={toggleSection}
      headerLabel={<TypeLabel type="section" />}
      headerContent={<div className="title">{title}</div>}
      headerActions={
        <>
          <MoveItem
            move={moveSection}
            index={sectionIndex}
            count={sectionCount}
          />
          <Icon icon={sectionActive ? 'he-up' : 'he-down'} />
        </>
      }
    >
      <JSONEditor
        title="Section properties"
        formData={{ title, id, displayTitle }}
        schema={sectionSchema}
        onFormSubmit={updateSectionProps}
        saveButtonText={'Update section properties'}
        customValidate={customValidate}
        customTransformErrors={(errors) => transformErrors(errors)}
      />

      <JSONEditor
        title="Display properties"
        formData={displayWhen}
        uiSchema={displayUiSchema}
        schema={displaySchema}
        onFormSubmit={updateDisplayProps}
        saveButtonText={'Update display properties'}
      />

      <Heading
        contentLeft={
          <>
            <div className="title">Columns</div>
            <Button
              onClick={createColumn}
              activity="secondary"
              slim
            >
              Create column
            </Button>
          </>
        }
      />

      {columns.map((column, i) => (
        <ColumnItem
          key={i}
          activeTree={activeTree}
          columnCount={columns.length}
          moveColumn={moveColumn}
          removeColumn={removeColumn}
          pageIndex={activePageIndex}
          sectionIndex={sectionIndex}
          columnIndex={i}
          updateColumn={updateColumn}
          columnActive={sectionActive && activeColumnIndex === i}
          setActiveTreeIndexes={setActiveTreeIndexes}
          {...column}
        />
      ))}

      <Delete
        onDelete={() => removeSection(sectionIndex)}
        title="Delete section"
      />
    </StyledItem>
  );
};

export default SectionItem;
