import React, { useMemo } from 'react';
import { Icon, Spinner, Button } from '@iq/react-components';

import { useSubscription } from '../../../../bundles/visualizations';
import { THRESHOLD_TYPES } from '../../../../constants';

const Status = ({ value, thresholds, loading, isDataInvalid }) => {
  if (loading) return <Spinner />;

  let unsortedThresholds = [];

  const typeSpecificThresholds = thresholds[THRESHOLD_TYPES[thresholds.thresholdType]?.key];
  if (typeSpecificThresholds) {
    unsortedThresholds = typeSpecificThresholds;
  } else if (Array.isArray(thresholds)) {
    // handle legacy thresholds
    unsortedThresholds = thresholds;
  }

  if (isDataInvalid) {
    return (
      <Button
        tooltip="Component status is invalid"
        design="text"
      >
        <Icon
          icon="he-statuswarning"
          color="#ceeed2"
          className="status-visualization-component-icon"
        />
      </Button>
    );
  }

  if (!Array.isArray(unsortedThresholds) || unsortedThresholds.length === 0) {
    return (
      <Icon
        icon="he-help"
        className="status-visualization-component-icon"
      />
    );
  }

  const sortedThresholds = unsortedThresholds.sort(
    ({ value: v1, max: m1 }, { value: v2, max: m2 }) => {
      if ((v1 || v1 === 0) && (v2 || v2 === 0)) {
        return v1 - v2;
      }
      return m1 - m2;
    }
  );

  const status = sortedThresholds.find(
    ({ value: thresholdValue, max }) => (thresholdValue || max) >= value
  );

  if (status !== undefined) {
    return (
      <>
        <Icon
          icon="he-statushealthy"
          className="status-visualization-component-icon"
          style={{ color: status.fill }}
        />
        <p style={{ color: status.fill }}>{status.description}</p>
      </>
    );
  }
  return (
    <>
      <Icon
        icon="he-statusriskhigh"
        className="status-visualization-component-icon"
        style={{ color: 'red' }}
      />
    </>
  );
};

const StatusVisualization = ({
  visualization: { id, configuration = {} },
  panelId,
  isPreview = false,
}) => {
  const { latestValues: values, initialLoaded, loading } = useSubscription(id, panelId);
  const { label = '', thresholds } = configuration;

  const [value, quality] = useMemo(() => {
    if (initialLoaded && values) {
      const [firstVar] = values;
      const [, lastValue, lastValueQuality] = firstVar?.value || [];
      if (lastValue || lastValue === 0) {
        const currValue = Number.isNaN(lastValue) ? null : lastValue;
        return [currValue, lastValueQuality];
      }
    }
    return [null, 0];
  }, [initialLoaded, values]);

  // only invalidate if quality 2 (invalid) or 3 (questionable)
  const isDataInvalid = quality > 1;

  return (
    <div className={`status-visualization-component ${isPreview ? 'preview' : 'normal'}`}>
      <p className="status-visualization-label">{label}</p>
      <Status
        value={value}
        thresholds={thresholds}
        loading={loading}
        isDataInvalid={isDataInvalid}
      />
    </div>
  );
};

export default StatusVisualization;
