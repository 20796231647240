import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from '@iq/react-components';
import { HvTabs, HvTab } from '@hitachivantara/uikit-react-core';

import ServiceAccountsTab from './ServiceAccountsTab';
import TemplateTab from './TemplateTab';
import IntegrationsTab from './IntegrationsTab';
import { requestIntegrations } from '../../../../bundles/integrations';
import { requestServiceAccounts } from '../../../../bundles/auth';

const TenantAdminIntegrationView = () => {
  const dispatch = useDispatch();
  const { realm } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (realm) {
      dispatch(requestIntegrations({ org: realm }));
      dispatch(requestServiceAccounts());
    }
  }, [realm, tabIndex]);

  const handleChange = (e, newTabIndex) => setTabIndex(newTabIndex);

  return (
    <div className="tenant-admin-panel">
      <HvTabs
        id="tabs-content-change"
        value={tabIndex}
        onChange={handleChange}
      >
        <HvTab
          id="templates"
          label="Templates"
        />
        <HvTab
          id="integrations"
          label="Integrations"
        />
        <HvTab
          id="service-accounts"
          label="Service accounts"
        />
      </HvTabs>
      <div className="tenant-admin-content-tabs-container">
        {tabIndex === 0 && <TemplateTab />}
        {tabIndex === 1 && <IntegrationsTab />}
        {tabIndex === 2 && <ServiceAccountsTab />}
      </div>
    </div>
  );
};

export default TenantAdminIntegrationView;
