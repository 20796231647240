import { VALID_COMPONENT_KEYS } from './schemas/componentSchemas';

const prepData = (formData) => {
  const keys = VALID_COMPONENT_KEYS;
  const data = keys
    .map((key) => ({ [key]: formData[key] }))
    .reduce(
      (prev, curr) => ({
        ...prev,
        ...curr,
      }),
      {}
    );

  if (data.source) {
    data.source_id = data.source;
    delete data.source;
  } else {
    // if no source, we explicitly set null and clear options
    data.source_id = null;
    data.options = {};
  }

  if (data.statefulVariables) {
    data.variables = data.statefulVariables;
    delete data.statefulVariables;
  }

  data.eventSources = (data.eventSources || []).filter((source) => source.source_id);
  return data;
};

export default prepData;
