const tenantAdminPages = [
  {
    id: 'tenant-admin-users',
    title: 'Users',
    path: '/tenant-administration/users',
    icon: 'he-people',
  },
  {
    id: 'tenant-admin-integration',
    title: 'Integrations',
    path: '/tenant-administration/integrations',
    icon: 'he-link',
  },
];

export default tenantAdminPages;
