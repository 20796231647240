/* eslint-disable no-param-reassign */
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Icon, colors } from '@iq/react-components';
import { useDropzone } from 'react-dropzone';
import { RRule } from 'rrule';
import { NotFoundError } from '@iq/services';
import { pad } from '@rjsf/utils';
import { toZonedTime, getTimezoneOffset } from 'date-fns-tz';

import { getTags } from '../../bundles/tags';
import { getComponents } from '../../bundles/components';
import { getMembers, getHasPermission } from '../../bundles/auth';
import { getUsers } from '../../bundles/ad';
import { deleteUserEvent, getSeriesEvents, excludeUserEventOccurrence } from '../../bundles/events';
import { checkIsOnline, displayNotification, getTempId } from '../../bundles/notifications';
import getNotification from '../../bundles/notification-defaults';
import { getTimezone } from '../../bundles/application';
import { FORM_STATES, STATELESS_EVENTS } from '../../constants';
import services from '../../services';
import { getSharedCacheKey, useAsync } from '../../utils';
import { utcToSite, siteToUtc } from '../../datetimeUtils';
import JSONEditor from '../JSONEditor';
import { DropdownMenu } from '../DropdownMenu';
import { FilesPanel } from '../panels/FilesPanel';
import SimpleModal from '../SimpleModal/SimpleModal';
import Indicator from '../panels/EventTimelinePanel/Indicator';
import TagSelect from '../TagSelect';
import ConfirmationDialog from '../ConfirmationDialog';

import {
  getEventMainSchema,
  getEventGeneralSchema,
  EventGeneralUiSchema,
  getEventPlanningSchema,
  EventPlanningUiSchema,
  getEventExecutionSchema,
  EventExecutionUiSchema,
  getEventAttributeSchemas,
} from './eventSchemas';
import CommentForm from './CommentForm';

const getRelativeDate = (relative, date) => {
  const period = ['day', 'week', 'month'].find((p) => relative?.includes(p));
  if (period) {
    const d = new Date(date);
    const numPeriods = parseInt(relative.split('_')[0], 10);
    switch (period) {
      case 'month':
        d.setMonth(d.getMonth() - numPeriods);
        break;
      case 'week':
        d.setDate(d.getDate() - numPeriods * 7);
        break;
      case 'day':
      default:
        d.setDate(d.getDate() - numPeriods);
        break;
    }
    return d;
  }
  return null;
};

const formDataSections = {
  main: ['type', 'planned', 'state', 'assignedTo'],
  general: ['name', 'description', 'components', 'events'],
  planning: ['from', 'to', 'notifyAt', 'recurrenceRule'],
  execution: ['executionFrom', 'executionTo'],
  attachments: ['files'],
  // we don't include attributes as a section since it already takes the form of one (JSONB)
};
const dateSubSections = {
  planningDates: ['from', 'to'],
  executionDates: ['executionFrom', 'executionTo'],
};
const boolToStrFields = ['planned'];
const toFormData = (event) => {
  const data = Object.entries(event).reduce(
    (acc, [key, value]) => {
      const [sectionKey] =
        Object.entries(formDataSections).find((section) => section[1].includes(key)) || [];
      if (sectionKey) {
        const [dateSubSectionKey] =
          Object.entries(dateSubSections).find((section) => section[1].includes(key)) || [];
        if (dateSubSectionKey) {
          const isFrom = key.toLowerCase().includes('from');
          acc[sectionKey] = {
            ...acc[sectionKey],
            [dateSubSectionKey]: {
              ...acc[sectionKey][dateSubSectionKey],
              [isFrom ? 'from' : 'to']: value,
            },
          };
        } else {
          acc[sectionKey] = {
            ...acc[sectionKey],
            [key]: boolToStrFields.includes(key) ? Boolean(value).toString() : value,
          };
        }
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {
      main: { type: 'general' },
      general: {},
      planning: { planningDates: { from: null, to: null }, recurrenceRule: null },
      execution: { executionDates: { from: null, to: null } },
      attributes: {},
      attachments: {},
    }
  );
  if (data.planning.planningDates.from === data.planning.planningDates.to) {
    data.planning.planningDates.to = null;
  }
  return data;
};

const fromFormData = (event) => ({
  type: event.main.type,
  planned: event.main.planned === 'true',
  state: event.main.state,
  assignedTo: event.main.assignedTo,
  name: event.general.name,
  description: event.general.description,
  components: event.general.components,
  events: event.general.events,
  from: event.planning.planningDates.from,
  to: event.planning.planningDates.to || event.planning.planningDates.from,
  recurrenceRule: event.planning.recurrenceRule,
  notifyAt: event.planning.notifyAt,
  executionFrom: event.execution.executionDates.from,
  executionTo: event.execution.executionDates.to,
  attributes: event.attributes,
  task: event.task,
  activeSeries: event.activeSeries,
  seriesId: event.seriesId,
});

const UserEventModal = (props) => {
  const {
    site,
    event,
    dateDomain,
    userEventTypes,
    hasHistory,
    hideInactiveSystemEvents = true,
    eventListColumns,
    initialEdit = false,
    onBack,
    onNext,
    onClose,
  } = props;

  const dispatch = useDispatch();

  const editing = event && initialEdit ? FORM_STATES.edit : null;
  const formattedEventData = useMemo(() => toFormData(event || {}), [event]);
  const initialEventState = event?.state;
  const [eventData, setEventData] = useState(formattedEventData);
  const [eventTags, setEventTags] = useState(event?.tags || []);
  const [modalState, setModalState] = useState(
    editing || (event ? FORM_STATES.view : FORM_STATES.create)
  );
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [dropFiles, setDropFiles] = useState([]);
  const [showFileOptions, setShowFileOptions] = useState(false);
  const [siteFiles, setSiteFiles] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [showFileSelector, setShowFileSelector] = useState(false);
  const [filesToRemoveContext, setFilesToRemoveContext] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isTask, setIsTask] = useState(!!formattedEventData.task); // to be set when updating main event data
  const [eventChange, setEventChange] = useState('');
  const timezone = useSelector(getTimezone);
  const seriesEvents = useSelector(getSeriesEvents);
  const tags = useSelector(getTags);
  const components = useSelector(getComponents);
  const tenantUsers = useSelector(getUsers);
  const siteMembers = useSelector((state) => getMembers(state, `site/${site.id}`));
  const canEditLimited = useSelector((state) =>
    getHasPermission(state, 'events/WriteLimited', { org: site.org, site: site.id })
  );
  const canEditFull = useSelector((state) =>
    getHasPermission(state, 'events/Write', { org: site.org, site: site.id })
  );
  const canDelete = useSelector((state) =>
    getHasPermission(state, 'events/Delete', { org: site.org, site: site.id })
  );

  const mainRef = useRef();
  const generalRef = useRef();
  const planningRef = useRef();
  const executionRef = useRef();

  const isActiveSeries = !!event?.activeSeries && !!event?.recurrenceRule;
  const isActiveSeriesEvent = !!event?.activeSeries && !!event?.seriesId;
  const isUnsavedSeriesEvent = isActiveSeriesEvent && !event?.createdAt;
  const isSavedSeriesEvent = isActiveSeriesEvent && event?.createdAt;

  const siteUsers = siteMembers
    .reduce(
      (acc, member) => [...acc, ...member.connected.map((userEntity) => userEntity.split('/')[1])],
      []
    )
    .map((userId) => {
      const user = tenantUsers.find(({ id }) => id === userId);
      if (user) {
        return {
          id: user.id,
          name: user.name,
        };
      }
      return undefined;
    })
    .filter((filterUser) => filterUser?.name);

  const onDrop = useCallback((dFiles) => setDropFiles((oldFiles) => [...oldFiles, ...dFiles]), []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled: isSaving,
    noClick: true,
    onDrop,
  });

  const fileIds = eventData?.attachments?.files || [];
  const { response: files = [] } = useAsync(
    async (cache) => {
      if (fileIds) {
        return Promise.all(
          fileIds.map((fileId) =>
            cache(
              () =>
                services.file.getFile(fileId).then(
                  (res) => res,
                  (e) => {
                    if (!(e instanceof NotFoundError)) {
                      console.error('Unable to fetch event file: ', e);
                      dispatch(checkIsOnline());
                      dispatch(displayNotification(getNotification('getEventFile', 'error')()));
                    }
                    return { error: e };
                  }
                ),
              getSharedCacheKey('file/getFile', fileId)
            )
          )
        );
      }
      return { response: [] };
    },
    [JSON.stringify(fileIds)]
  );
  const foundFiles = useMemo(() => (files || []).filter((f) => !f.error), [files]);

  useEffect(() => {
    setEventData(formattedEventData);
  }, [formattedEventData]);

  useEffect(() => {
    setIsTask(eventData.task);
  }, [eventData]);

  useEffect(() => {
    setAttachedFiles(foundFiles);
  }, [foundFiles]);

  const onUploadFiles = () => {
    setShowFileOptions(false);
    open();
  };
  useEffect(() => {
    const element = document.querySelector('.simple-modal-container');
    if (element) element.scrollTop = 0;
  }, [eventChange, isTask]);

  const onSelectSiteFiles = () => {
    setShowFileOptions(false);
    setShowFileSelector(true);
  };

  const onAttachSiteFiles = useCallback(
    (sFiles = []) => {
      setSiteFiles((oldFiles) => [
        ...oldFiles,
        ...sFiles.filter(
          (sFile) =>
            !attachedFiles.map(({ id }) => id).includes(sFile.id) &&
            !oldFiles.map(({ id }) => id).includes(sFile.id)
        ),
      ]);
      setShowFileSelector(false);
    },
    [attachedFiles]
  );

  const onDeleteFile = useCallback(
    (file) => {
      if (modalState === 'create' || (modalState === 'edit' && !file.id)) {
        setDropFiles((oldFiles) => oldFiles.filter((item) => item !== file));
      }
      if (modalState === 'edit' && file.id) {
        setAttachedFiles((oldFiles) => oldFiles.filter((item) => item.id !== file.id));
        const singleCtx = file.context && (file.context.identifier || file.context.length === 1);
        if (singleCtx && file.context.identifier === event.id) {
          setFilesToDelete((queuedFiles) => [...queuedFiles, file]);
        }
        if (
          file.context &&
          file.context.length > 1 &&
          file.context.map((ctx) => ctx.identifier).includes(event?.id)
        ) {
          setFilesToRemoveContext((queuedFiles) => [...queuedFiles, file]);
        }
      }
    },
    [event, modalState]
  );

  const onRemoveSiteFile = useCallback(
    (file) => setSiteFiles((oldFiles) => oldFiles.filter((f) => f.id !== file.id)),
    []
  );

  const stateOptions = useMemo(() => {
    const { planning: { recurrenceRule } = {}, main: { state } = {} } = eventData || {};
    const pendingOpt = { const: 'pending', title: 'Pending' };
    const todoOpt = { const: 'todo', title: 'To-do' };
    const openOpt = { const: 'open', title: 'Open' };
    const overdueOpt = { const: 'overdue', title: 'Overdue' };
    const closedOpt = { const: 'closed', title: 'Closed' };

    if ((recurrenceRule && recurrenceRule !== 'no-repeat') || modalState === FORM_STATES.create) {
      return [pendingOpt];
    }
    if (state === 'todo') {
      return [pendingOpt, todoOpt, openOpt, closedOpt];
    }
    if (state === 'overdue') {
      return [pendingOpt, openOpt, overdueOpt, closedOpt];
    }
    return [pendingOpt, openOpt, closedOpt];
  }, [eventData, modalState]);

  const schemaConfig = useMemo(
    () => ({
      site,
      dateDomain,
      modalState,
      components,
      siteUsers,
      userEventTypes,
      hideInactiveSystemEvents,
      eventListColumns,
      stateOptions,
      isTask,
      sourceEventId: event?.id,
      isActiveSeriesEvent,
      onNext,
      timezone,
    }),
    [
      site,
      dateDomain,
      modalState,
      components,
      siteUsers,
      userEventTypes,
      hideInactiveSystemEvents,
      eventListColumns,
      stateOptions,
      isTask,
      event,
      isActiveSeriesEvent,
      onNext,
      timezone,
    ]
  );
  const mainSchema = useMemo(() => getEventMainSchema(schemaConfig), [schemaConfig]);
  const generalSchema = useMemo(() => getEventGeneralSchema(schemaConfig), [schemaConfig]);
  const planningSchema = useMemo(() => getEventPlanningSchema(schemaConfig), [schemaConfig]);
  const executionSchema = useMemo(() => getEventExecutionSchema(schemaConfig), [schemaConfig]);
  const attributeSchemas = useMemo(() => getEventAttributeSchemas(schemaConfig), [schemaConfig]);

  const editMenuOptions = useMemo(() => {
    const options = [];
    if (modalState === FORM_STATES.view && (canEditFull || canEditLimited)) {
      options.push({
        component: <div>Edit event</div>,
        onSelect: () =>
          setModalState(() => (canEditFull ? FORM_STATES.edit : FORM_STATES.editLimited)),
      });
    }
    if ((modalState === FORM_STATES.view || isActiveSeries) && canDelete) {
      options.push({
        component: <div>{`Delete ${isActiveSeries ? 'series' : 'event'}`}</div>,
        onSelect: () => setShowDeleteConfirm(true),
      });
    }
    return options;
  }, [canEditFull, canEditLimited, canDelete]);

  const onConfirmDeletion = useCallback(() => {
    setShowDeleteConfirm(false);
    if (event.createdAt) {
      dispatch(deleteUserEvent(event?.id));
    } else {
      dispatch(excludeUserEventOccurrence(event));
    }
    onClose();
  }, [event]);

  const confirmDeletionBody = useMemo(
    () =>
      isActiveSeries ? (
        <>
          <p>Are you sure you want to delete this event series?</p>
          <p>Unedited events from this series will no longer be available.</p>
        </>
      ) : (
        <>
          <p>Are you sure you want to delete this event?</p>
          <p>This will remove both the event and any associated files and comments.</p>
        </>
      ),
    [isActiveSeries]
  );

  const deleteEventConfirmation = useMemo(
    () =>
      showDeleteConfirm ? (
        <ConfirmationDialog
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={onConfirmDeletion}
          confirmType="danger"
          confirmText="Delete"
          title={`Delete Event${isActiveSeries ? ' Series' : ''}`}
          body={confirmDeletionBody}
        />
      ) : null,
    [isActiveSeries, showDeleteConfirm, confirmDeletionBody, onConfirmDeletion]
  );
  function fileName(message) {
    // Find the position of the first and last single quote
    const firstQuoteIndex = message.indexOf("'");
    const lastQuoteIndex = message.lastIndexOf("'");

    // Extract the substring between the single quotes
    return message.substring(firstQuoteIndex + 1, lastQuoteIndex);
  }
  async function createFile(file) {
    const formData = new FormData();
    Object.keys(file).forEach((key) => {
      if (Array.isArray(file[key])) {
        file[key].forEach((item) => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, file[key]);
      }
    });
    try {
      return await services.file.createFile(formData);
    } catch (error) {
      return { id: null, error };
    }
  }

  const getUpdatedFiles = useCallback(
    async (eId) => {
      let fileUpdateErrors = []; // Array to collect errors

      try {
        const newFiles = await Promise.all(
          dropFiles
            .map((file) => ({
              file,
              site: site.id,
              org: site.org,
              tags: eventTags || [],
              components: eventData.general.components || [],
              context: JSON.stringify([{ type: 'event', identifier: eId }]),
            }))
            .map(createFile)
        );

        // Filter out files with success and error
        const successfulNewFiles = newFiles.filter((file) => file.id !== null);
        const ErrorFiles = newFiles.filter((file) => file.id === null);

        if (ErrorFiles.length > 0) {
          // Collect error messages
          fileUpdateErrors = ErrorFiles.map((result) => result.error);
        }

        // only delete files that have a single, matching context
        await Promise.all(filesToDelete.map((file) => services.file.deleteFile(file.id)));
        // else we update to remove this context
        await Promise.all(
          filesToRemoveContext.map((file) =>
            services.file.updateFile(file.id, {
              ...file,
              context: file.context.filter((ctx) => ctx.identifier !== eId),
            })
          )
        );
        // add event context to any site files attached
        await Promise.all(
          siteFiles.map((file) => {
            let newComps = [];
            let newTags = [];
            if (components) {
              newComps = eventData.general.components.filter(
                (c) => !(file.components || []).includes(c)
              );
            }
            if (tags) {
              newTags = eventTags.filter((t) => !(file.tags || []).includes(t));
            }
            return services.file.updateFile(file.id, {
              ...file,
              components: [...(file.components || []), ...newComps],
              tags: [...(file.tags || []), ...newTags],
              context: [file.context, { type: 'event', identifier: eId }]
                .flat()
                .filter((ctx) => ctx),
            });
          })
        );

        return [
          ...attachedFiles.map((f) => f.id),
          ...successfulNewFiles.map((f) => f.id),
          ...siteFiles.map((f) => f.id),
        ];
      } catch (e) {
        console.error('Unable to update files: ', e);
        dispatch(checkIsOnline());
        dispatch(displayNotification(getNotification('updateEventFiles', 'error')()));
        return [];
      } finally {
        if (Array.isArray(fileUpdateErrors)) {
          fileUpdateErrors.forEach((e) => {
            const tempId = getTempId();
            const existingFileName = fileName(e.response.data.message);
            const errorMessage = e?.response.status === 453 ? e.response.data.message : e.message;
            console.error('Unable to update files: ', e);
            dispatch(checkIsOnline());
            dispatch(
              displayNotification(
                getNotification('createFile', 'error')(errorMessage, tempId, !!existingFileName)
              )
            );
          });
        }
      }
    },
    [eventData, eventTags, dropFiles, filesToDelete, filesToRemoveContext, siteFiles, attachedFiles]
  );
  const onSubmitEvent = useCallback(
    async (e) => {
      let hasErrors = false;
      // first, validate forms
      const { errors: mainErrors } = mainRef.current.validate(eventData.main);
      const { errors: generalErrors } = generalRef.current.validate(eventData.general);
      const { errors: planningErrors } = planningRef.current.validate(eventData.planning);
      const { errors: executionErrors } = executionRef.current
        ? executionRef.current.validate(eventData.execution)
        : { errors: [] };

      if (mainErrors.length) {
        hasErrors = true;
        mainRef.current.onSubmit(e);
      }

      if (generalErrors.length) {
        hasErrors = true;
        generalRef.current.onSubmit(e);
      }

      if (planningErrors.length) {
        hasErrors = true;
        planningRef.current.onSubmit(e);
      }
      if (executionErrors.length) {
        hasErrors = true;
        executionRef.current.onSubmit(e);
      }

      if (!hasErrors) {
        setIsSaving(true);
        const eventPayload = fromFormData(eventData);

        // add other fields
        eventPayload.site = site.id;
        eventPayload.org = site.org;
        eventPayload.tags = eventTags;
        eventPayload.notificationDate = getRelativeDate(eventPayload.notifyAt, eventPayload.from);

        // clear legacy recurrence rule
        const hasValidRecurrenceRule = eventPayload.recurrenceRule?.includes('RRULE:');
        if (!hasValidRecurrenceRule) {
          eventPayload.recurrenceRule = null;
        } else {
          // we need to include tz info and adjust dtstart to site time
          const siteStart = utcToSite(eventPayload.from, timezone);
          const time = `${pad(siteStart.getHours(), 2)}${pad(siteStart.getMinutes(), 2)}${pad(siteStart.getSeconds(), 2)}`;

          eventPayload.recurrenceRule = `DTSTART;TZID=${timezone}:${siteStart.getFullYear()}${pad(
            siteStart.getMonth() + 1,
            2
          )}${pad(siteStart.getDate(), 2)}T${time}\n${eventPayload.recurrenceRule.replace(
            ';BYMONTHDAY=null',
            ''
          )}`;
        }

        try {
          if (modalState === FORM_STATES.create) {
            const { id: eventId } = await services.event.createEvent(eventPayload);
            await services.event.updateEvent(eventId, { files: await getUpdatedFiles(eventId) });
          }

          if (modalState === FORM_STATES.edit) {
            const eventFiles = await getUpdatedFiles(event.id);
            if (!isUnsavedSeriesEvent) {
              eventPayload.activeSeries = !!eventPayload.recurrenceRule;
              await services.event.updateEvent(event.id, { ...eventPayload, files: eventFiles });
            } else {
              // instantiating series event
              await services.event.createEvent({
                ...eventPayload,
                id: event.id,
                files: eventFiles,
              });
            }
          }

          if (modalState === FORM_STATES.editLimited) {
            const eventFiles = await getUpdatedFiles(event.id);
            if (!isUnsavedSeriesEvent) {
              await services.event.updateLimitedEvent(event.id, {
                ...eventPayload,
                files: eventFiles,
              });
            }
          }

          dispatch(
            displayNotification(
              getNotification('saveEvent', 'success')(
                modalState.split('-')[0],
                event?.id || 'save-event',
                isActiveSeries ? 'series' : ''
              )
            )
          );
          const getSeries =
            modalState === FORM_STATES.create
              ? !!eventPayload.recurrenceRule
              : eventPayload.activeSeries && !!eventPayload.recurrenceRule;
          onClose({ getSeries });
          setIsSaving(false);
        } catch (submitError) {
          console.error(`Unable to ${modalState.replace('Limited', '')} event: `, submitError);
          dispatch(checkIsOnline());
          dispatch(
            displayNotification(
              getNotification('saveEvent', 'error')(
                modalState.split('-')[0],
                event?.id || 'save-event',
                isActiveSeries ? 'series' : ''
              )
            )
          );
        }
      }
    },
    [
      mainRef.current,
      generalRef.current,
      planningRef.current,
      executionRef.current,
      eventData,
      site,
      timezone,
      modalState,
      eventTags,
      isUnsavedSeriesEvent,
      getUpdatedFiles,
    ]
  );

  const saveEvent = useCallback(async () => {
    // called from within CommentForm in the case that a comment is added to a future series event
    // as comments are only added in view mode, we don't need any validation, data transformation or file handling
    const eventPayload = fromFormData(eventData);

    // add other fields
    eventPayload.site = site.id;
    eventPayload.org = site.org;
    eventPayload.id = event.id;
    try {
      const { id } = await services.event.createEvent(eventPayload);
      return !!id;
    } catch (submitError) {
      console.error(`Unable to save event: `, submitError);
      dispatch(checkIsOnline());
      dispatch(
        displayNotification(
          getNotification('saveEvent', 'error')(FORM_STATES.create, event?.id || 'save-event')
        )
      );
      return false;
    }
  }, [eventData, site]);

  const customMainValidator = useCallback(
    (data, errors) => {
      const { type, state } = data;

      const overdueStateError = 'planning date must be in the future for this status';
      if (
        !STATELESS_EVENTS.includes(type) &&
        initialEventState === 'overdue' &&
        state !== 'overdue' &&
        state !== 'closed' &&
        new Date(
          eventData?.planning?.planningDates?.to || eventData?.planning?.planningDates?.from
        ) < Date.now()
      ) {
        errors.state.addError(overdueStateError);
      }
      if (
        !STATELESS_EVENTS.includes(type) &&
        initialEventState === 'overdue' &&
        state !== 'overdue' &&
        new Date(
          eventData?.planning?.planningDates?.to || eventData?.planning?.planningDates?.from
        ) > Date.now()
      ) {
        errors.state.__errors = errors.state.__errors.filter((e) => e !== overdueStateError);
      }

      const todoStateError = 'notify date must be in the future for this status';
      const notifyAt =
        eventData?.planning?.notifyAt && eventData?.planning?.planningDates?.from
          ? new Date(
              getRelativeDate(eventData.planning.notifyAt, eventData.planning.planningDates.from)
            )
          : null;

      if (
        !STATELESS_EVENTS.includes(type) &&
        initialEventState === 'todo' &&
        state === 'pending' &&
        notifyAt &&
        notifyAt < Date.now()
      ) {
        errors.state.addError(todoStateError);
      }
      if (
        !STATELESS_EVENTS.includes(type) &&
        initialEventState === 'todo' &&
        state !== 'pending' &&
        notifyAt &&
        notifyAt > Date.now()
      ) {
        errors.state.__errors = errors.state.__errors.filter((e) => e !== todoStateError);
      }

      return errors;
    },
    [
      initialEventState,
      eventData?.planning?.planningDates?.to,
      eventData?.planning?.planningDates?.from,
    ]
  );

  const customPlanningValidator = useCallback(
    (data, errors) => {
      const {
        planningDates: { from, to },
        recurrenceRule,
      } = data;

      const isRecurring = recurrenceRule?.includes('RRULE:');
      const startNotInFutureError = 'start date must be in the future for recurring events';
      if (isRecurring && from && toZonedTime(from, timezone).getTime() < Date.now()) {
        errors.planningDates.from.addError(startNotInFutureError);
      } else if (errors.planningDates.from.__errors.includes(startNotInFutureError)) {
        errors.planningDates.from.__errors = errors.planningDates.from.__errors.filter(
          (e) => e !== startNotInFutureError
        );
      }

      const startGtEndError = 'start date must preceed end date';
      if (from && to && to < from) {
        errors.planningDates.from.addError(startGtEndError);
      } else if (errors.planningDates.from.__errors.includes(startGtEndError)) {
        errors.planningDates.from.__errors = errors.planningDates.from.__errors.filter(
          (e) => e !== startGtEndError
        );
      }

      const noStartError = 'date is required';
      if (!from) {
        errors.planningDates.from.addError(noStartError);
      } else if (errors.planningDates.from.__errors.includes(noStartError)) {
        errors.planningDates.from.__errors = errors.planningDates.from.__errors.filter(
          (e) => e !== noStartError
        );
      }

      const startAfterUntilError = 'start date must fall before recurrence until date';
      if (recurrenceRule && recurrenceRule.indexOf('UNTIL') > 0) {
        const rRuleObj = RRule.fromString(recurrenceRule);
        const { until } = rRuleObj.origOptions;
        const siteTime = siteToUtc(until, timezone);
        const appTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const appTzOffset = getTimezoneOffset(appTimezone);
        const untilForCompare = new Date(siteTime.getTime() - appTzOffset);
        if (from && from > untilForCompare.toISOString()) {
          errors.planningDates.from.addError(startAfterUntilError);
        } else if (errors.planningDates.from.__errors.includes(startAfterUntilError)) {
          errors.planningDates.from.__errors = errors.planningDates.from.__errors.filter(
            (e) => e !== startAfterUntilError
          );
        }
      }
      if (recurrenceRule && recurrenceRule === 'invalid') {
        errors.recurrenceRule.addError(' ');
      } else {
        errors.recurrenceRule.__errors = [];
      }

      return errors;
    },
    [timezone]
  );

  const customExecutionValidator = useCallback((data, errors) => {
    const {
      executionDates: { from: executionFrom, to: executionTo },
    } = data;
    if (executionFrom && executionTo && executionTo < executionFrom) {
      errors.executionDates.addError('start date must preceed end date');
    }
    if (executionTo && !executionFrom) {
      errors.executionDates.addError('start date required if end is present');
    }
    return errors;
  }, []);

  const editable = modalState === FORM_STATES.edit || modalState === FORM_STATES.create;
  const editableLimited = editable || modalState === FORM_STATES.editLimited;

  const typedAttributesSchema = attributeSchemas[eventData?.main?.type];
  const shouldShowAttributes =
    Object.keys(typedAttributesSchema?.properties || []).length > 0 &&
    (modalState !== FORM_STATES.view || Object.keys(eventData?.attributes || {}).length > 0);

  const attributesForm = useMemo(
    () =>
      eventData?.main?.type ? (
        <JSONEditor
          schema={attributeSchemas[eventData.main.type]}
          formData={eventData.attributes}
          initialEditMode={true}
          showEditButton={false}
          showButtons={false}
          editorOnly
          onFormChange={({ formData }) =>
            setEventData((data) => ({ ...data, attributes: formData }))
          }
        />
      ) : null,
    [eventData, attributeSchemas]
  );

  const buildFileList = (filesToList, canEdit, onFileDelete) => {
    return (
      <FilesPanel
        hideUploadButton
        fileLimit={15}
        selector
        filesToList={filesToList}
        onFileDelete={(file) => onFileDelete(file)}
        editEvent={canEdit}
        isEventPage={true}
        site={site}
      />
    );
  };

  const attachmentsField = useMemo(
    () =>
      attachedFiles.length > 0 ||
      dropFiles.length > 0 ||
      siteFiles.length > 0 ||
      modalState !== FORM_STATES.view ? (
        <>
          <div className="event-modal__form-field-group">
            <div className="event-modal__label-row">
              <label>Attachments</label>
              {(editable || editableLimited) && (
                <Button
                  activity="secondary"
                  slim
                  onClick={() => setShowFileOptions(true)}
                  onBlur={(e) => {
                    if (!e?.relatedTarget?.classList.contains('file-options-element')) {
                      setShowFileOptions(false);
                    }
                  }}
                  type="button"
                >
                  Add
                </Button>
              )}
              <input {...getInputProps()} />
              {showFileOptions && (
                <div
                  className="event-modal__file-options file-options-element"
                  tabIndex={0}
                >
                  <Button
                    className="file-options-element"
                    onClick={onUploadFiles}
                    slim
                  >
                    Add files
                  </Button>

                  <Button
                    className="file-options-element"
                    onClick={onSelectSiteFiles}
                    slim
                  >
                    Select site files
                  </Button>
                </div>
              )}
            </div>
            <div className="event-modal__attachments">
              {attachedFiles.length > 0 && buildFileList(attachedFiles, editable, onDeleteFile)}
              {dropFiles.length > 0 && buildFileList(dropFiles, editable, onDeleteFile)}
              {siteFiles.length > 0 && buildFileList(siteFiles, editable, onRemoveSiteFile)}
            </div>
            {(editable || editableLimited) && (
              <div
                {...getRootProps()}
                className={`event-modal__file-input${
                  (!attachedFiles.length && !dropFiles.length && !siteFiles.length) || isDragActive
                    ? ' drop-zone-active'
                    : ''
                }`}
              >
                <div
                  className="drop-zone"
                  onClick={open}
                >
                  <input
                    name="files"
                    {...getInputProps()}
                  />
                  <p>Drop or click to upload</p>
                </div>
              </div>
            )}
          </div>
          {showFileSelector && (
            <SimpleModal
              className="file-selector-modal"
              onClose={() => setShowFileSelector(false)}
              title="Select files"
            >
              <FilesPanel
                hideUploadButton
                fileLimit={15}
                selector
                onAttach={onAttachSiteFiles}
                onClose={() => setShowFileSelector(false)}
                site={site}
              />
            </SimpleModal>
          )}
        </>
      ) : (
        <div className="event-modal__attachments--empty">No attachments found</div>
      ),
    [
      modalState,
      attachedFiles,
      dropFiles,
      siteFiles,
      showFileOptions,
      editable,
      editableLimited,
      onDeleteFile,
      showFileSelector,
      onRemoveSiteFile,
    ]
  );

  const handleMainChange = useCallback(
    ({ formData }) => {
      const selectedEventType = userEventTypes.find((t) => t.id === formData.type);
      setEventData((data) => ({
        ...data,
        main: formData,
        planning: {
          ...data.planning,
          recurrenceRule: selectedEventType?.task ? data.planning.recurrenceRule : null,
        },
        task: !!selectedEventType?.task,
      }));
      setEventChange(formData.type);
    },
    [planningRef.current, userEventTypes]
  );

  const handlePlanningChange = useCallback(({ formData }) => {
    if (formData.notifyAt) {
      if (Number.isNaN(Date.parse(formData.notifyAt))) {
        // none selected, we set notificationDate to null
        formData.notificationDate = null;
      } else {
        formData.notificationDate = formData.notifyAt;
      }
    }

    const hasValidRecurrenceRule = formData.recurrenceRule?.includes('RRULE:');
    setEventData((data) => ({
      ...data,
      planning: formData,
      main: hasValidRecurrenceRule ? { ...data.main, state: 'pending' } : data.main,
    }));
  }, []);

  const handleEditSeries = () => {
    const seriesEvent = seriesEvents.find((evt) => evt.id === event.seriesId);
    if (seriesEvent) {
      onNext({ eventType: 'user', event: seriesEvent, eventId: seriesEvent.id, edit: true });
    }
  };

  const isStateLessEvent = STATELESS_EVENTS.includes(event?.type);

  return (
    <>
      <SimpleModal
        size="l"
        onClose={onClose}
        className={`event-modal-wrapper ${modalState}`}
        headerLeft={
          hasHistory ? (
            <Button
              design="text"
              tooltip="Back"
              onClick={onBack}
            >
              <Icon icon="he-previous" />
            </Button>
          ) : null
        }
        title={
          <div className="event-modal-header">
            <Indicator
              eventState={isStateLessEvent ? 'stateless' : eventData?.main?.state}
              round={true}
              size={12}
            />
            <div className="title">{eventData?.general?.name || 'User Event'}</div>
            <TagSelect
              onChange={(selected) => setEventTags(selected)}
              value={eventTags}
              creatable
              popout
              availableTags={tags}
              editable={modalState === FORM_STATES.edit || modalState === FORM_STATES.create}
            />
          </div>
        }
        headerCenter={
          <>
            {isActiveSeriesEvent && canEditFull && (
              <div className="series-cta">
                {`${modalState === FORM_STATES.edit ? 'Editing' : 'Viewing'} a series event. `}
                {isUnsavedSeriesEvent && (
                  <span className="series-link">
                    <Button
                      className="series-link-btn"
                      type="button"
                      design="text"
                      activity="primary"
                      onClick={handleEditSeries}
                    >
                      Edit series
                    </Button>
                  </span>
                )}
                {isSavedSeriesEvent && (
                  <>
                    <div className="saved-series-info">
                      <Icon
                        icon="he-info"
                        style={{ color: colors.StatusBlue }}
                      />
                    </div>
                    <div className="saved-series-info--outer">
                      <div className="saved-series-info--inner">
                        <p>
                          Recurring event series can only be edited in unmodified events with a
                          pending status.
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        }
        headerRight={
          <>
            {(canEditFull || (canEditLimited && !isUnsavedSeriesEvent)) &&
              event &&
              editMenuOptions.length > 0 &&
              (modalState === FORM_STATES.view || isActiveSeries) && (
                <DropdownMenu
                  trigger="he-moreoptionshorizontal"
                  triggerSize="s"
                  triggerThemeLightColor={colors.LightApplicationPrimaryText}
                  menuOptions={editMenuOptions}
                  className="event-modal-menu"
                  menuXPlacement="left"
                  styles={{
                    dropdownIndicator: { padding: '0 0.5rem', filter: 'none' },
                    option: { cursor: 'pointer' },
                  }}
                />
              )}
          </>
        }
      >
        <div className={`event-modal ${modalState}`}>
          <div className={`top-section`}>
            <JSONEditor
              formRef={mainRef}
              className="top-section__content"
              schema={mainSchema}
              formData={eventData.main}
              initialEditMode={true}
              showEditButton={false}
              showButtons={false}
              editorOnly
              onFormChange={handleMainChange}
              customValidate={customMainValidator}
            />
          </div>

          <div className={`body-section ${isTask ? 'show-planning' : ''}`}>
            <div className="event-modal__column">
              <div className="event-modal__group-header">General</div>
              <div className="general">
                <JSONEditor
                  formRef={generalRef}
                  schema={generalSchema}
                  formData={eventData.general}
                  uiSchema={EventGeneralUiSchema}
                  initialEditMode={true}
                  showEditButton={false}
                  showButtons={false}
                  editorOnly
                  onFormChange={({ formData }) =>
                    setEventData((data) => ({ ...data, general: formData }))
                  }
                />
              </div>
              {event && modalState === FORM_STATES.view && (
                <>
                  <div className="event-modal__group-header">Comments</div>
                  <CommentForm
                    site={site}
                    parentId={event.id}
                    parentFiles={foundFiles}
                    parentComponents={event.components}
                    isUnsavedSeriesEvent={isUnsavedSeriesEvent}
                    saveEvent={saveEvent}
                  />
                </>
              )}
            </div>

            <div className="event-modal__column">
              <div className="event-modal__group-header">{isTask ? 'Planning' : 'Time'}</div>
              <div className="planning">
                <JSONEditor
                  formRef={planningRef}
                  schema={planningSchema}
                  formData={eventData.planning}
                  uiSchema={EventPlanningUiSchema}
                  initialEditMode={true}
                  showEditButton={false}
                  showButtons={false}
                  editorOnly
                  onFormChange={handlePlanningChange}
                  customValidate={customPlanningValidator}
                />
              </div>
              {!isTask && (
                <>
                  {shouldShowAttributes && (
                    <>
                      <div className="event-modal__group-header">Type specific</div>
                      {attributesForm}
                    </>
                  )}
                  <div className="event-modal__group-header">Attachments</div>
                  {attachmentsField}
                </>
              )}
            </div>

            {isTask && (
              <div className="event-modal__column">
                {(modalState === FORM_STATES.view ||
                  !eventData.planning.recurrenceRule ||
                  eventData.planning.recurrenceRule === 'no-repeat') && (
                  <>
                    <div className="event-modal__group-header">Execution</div>
                    <div className="execution">
                      <JSONEditor
                        formRef={executionRef}
                        schema={executionSchema}
                        formData={eventData.execution}
                        uiSchema={EventExecutionUiSchema}
                        initialEditMode={true}
                        showEditButton={false}
                        showButtons={false}
                        editorOnly
                        onFormChange={({ formData }) =>
                          setEventData((data) => ({ ...data, execution: formData }))
                        }
                        customValidate={customExecutionValidator}
                      />
                    </div>
                  </>
                )}
                {shouldShowAttributes && (
                  <>
                    <div className="event-modal__group-header">Type specific</div>
                    {attributesForm}
                  </>
                )}
                <div className="event-modal__group-header">Attachments</div>
                {attachmentsField}
              </div>
            )}
          </div>

          {modalState !== FORM_STATES.view && (
            <div className="event-modal__actions">
              <Button
                activity="secondary"
                type="text"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                activity="primary"
                type="submit"
                onClick={onSubmitEvent}
                disabled={isSaving}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </SimpleModal>
      {deleteEventConfirmation}
    </>
  );
};

export default UserEventModal;
