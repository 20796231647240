import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '@iq/react-components';

import { getActiveComponent, getSiteComponent } from '../../../bundles/components';

const ComponentAttributesPanel = ({
  showComponentName = true,
  showCategories = true,
  categoriesFilter = [],
  showSiteIfNoActiveComponent = true,
}) => {
  const siteComponent = useSelector(getSiteComponent);
  let activeComponent = useSelector(getActiveComponent);

  if (typeof activeComponent === 'undefined') {
    activeComponent = {
      ...siteComponent,
      ...(showSiteIfNoActiveComponent ? {} : { asset: {} }),
    };
  }

  const {
    id,
    name,
    itemDesignation,
    icon,
    asset: { variables = [] },
  } = activeComponent;

  const filteredAttributes = useMemo(() => {
    const actualCategories = categoriesFilter.filter((c) => c);
    if (actualCategories.length === 0) {
      return variables;
    }

    return variables.filter((attr) =>
      actualCategories.some((c) => c.toLowerCase() === attr?.category?.toLowerCase())
    );
  }, [variables, categoriesFilter]);

  const categorizeAttributes = useCallback(() => {
    if (filteredAttributes.length === 0) {
      return {};
    }

    return filteredAttributes.reduce((acc, cur) => {
      acc[cur.category || 'Uncategorized'] = [...(acc[cur.category || 'Uncategorized'] || []), cur];
      return acc;
    }, {});
  }, [filteredAttributes]);

  const sortByName = ({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB);

  const renderWithCategories = useCallback(() => {
    const categorizedAttributes = categorizeAttributes();
    return Object.keys(categorizedAttributes)
      .toSorted((a, b) => a.localeCompare(b))
      .map((cat) => (
        <table key={cat}>
          <thead>
            <tr>
              <th>{cat}</th>
            </tr>
          </thead>
          <tbody>
            {categorizedAttributes[cat].sort(sortByName).map((attr, i) => (
              <tr
                key={`${attr.name}_${attr.lastValueAt}_${i}`}
                className="property"
              >
                <td>{attr.name}</td>
                <td>{attr.lastValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ));
  }, [categorizeAttributes, showCategories]);

  const renderWithoutCategories = useCallback(
    () => (
      <table>
        <tbody>
          {filteredAttributes.sort(sortByName).map((attr, i) => (
            <tr
              key={`${attr.name}_${attr.lastValueAt}_${i}`}
              className="property"
            >
              <td>{attr.name}</td>
              <td>{attr.lastValue}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ),
    [filteredAttributes]
  );

  const renderAttributes = useCallback(() => {
    if (variables.length === 0) {
      return <div className="properties empty">No attributes for selected component</div>;
    }
    if (filteredAttributes.length === 0) {
      return <div className="properties empty">No attributes for selected category filter</div>;
    }
    return (
      <div className="properties">
        {showCategories ? renderWithCategories() : renderWithoutCategories()}
      </div>
    );
  }, [variables, filteredAttributes, showCategories]);

  if (!id) {
    return <div className="cp-panel-component empty">No component selected</div>;
  }

  return (
    <div className="cp-panel-component">
      {showComponentName && (
        <div className="component-header">
          <div>
            <div className="component-name">{name}</div>
            <div className="component-id">{itemDesignation}</div>
          </div>
          <Icon icon={icon} />
        </div>
      )}
      {renderAttributes()}
    </div>
  );
};

export default ComponentAttributesPanel;
