import React, { useEffect, useRef, useState } from 'react';
import { Icon, Button, colors } from '@iq/react-components';
import { useSelector, useDispatch } from 'react-redux';

import classnames from 'classnames';
import {
  getAggregatedConnectivity,
  getSourceConnectivityTree,
  getSourcesLoaded,
  requestConnectivity,
} from '../../../bundles/sources';

const ConnectivityStatus = ({ siteId }) => {
  const dispatch = useDispatch();
  const sourcesLoaded = useSelector(getSourcesLoaded);
  const [open, setIsOpen] = useState(false);
  const ref = useRef(null);

  const aggregatedConnectivity = useSelector(getAggregatedConnectivity);
  const connectivityTree = useSelector(getSourceConnectivityTree);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (sourcesLoaded && siteId) {
      dispatch(requestConnectivity(siteId));
    }
  }, [sourcesLoaded, siteId]);

  const handleToggleOpen = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const getStatusColor = (status) =>
    ({
      0: colors.StatusRed,
      1: colors.Green50,
      2: colors.Grey10,
    })[status];

  const connectivity = (tree) =>
    tree.map((item, index) => {
      const key = `${item.name}-${index}`;

      const itemPartContainer = (dependency, iteration, treeIndex) => {
        const statusClass = classnames({
          offline: dependency.status === 0,
          online: dependency.status === 1,
          unknown: dependency.status === 2,
          [`level-${iteration}`]: true,
        });

        return (
          <React.Fragment key={`source-${treeIndex}-level-${iteration}`}>
            <div className={`connectivity-item-container ${statusClass}`}>
              <span className={`connectivity-line ${statusClass}`}></span>
              <div className="connectivity-item">
                <Icon
                  fill={getStatusColor(dependency.status)}
                  icon={dependency.status ? 'he-connect' : 'he-disconnect'}
                  className={`${statusClass}`}
                ></Icon>
                <p className={statusClass}>{dependency.name}</p>
              </div>
            </div>
            {dependency.dependencies.map((subDependency, i) =>
              itemPartContainer(subDependency, iteration + 1, i)
            )}
          </React.Fragment>
        );
      };

      return (
        <div
          className="source-connectivity-item"
          key={key}
        >
          {itemPartContainer(item, 0, index)}
        </div>
      );
    });

  const aggregatedStatus = Object.values(aggregatedConnectivity).includes(0) ? 0 : 1;
  const offlineCount = Object.values(aggregatedConnectivity).filter((val) => val === 0).length;
  const aggregatedStatusDisplay = offlineCount ? `Offline (${offlineCount})` : 'Online';

  return siteId && Object.keys(aggregatedConnectivity).length ? (
    <div
      className="connectivity"
      ref={ref}
    >
      <Button
        design="text"
        onClick={handleToggleOpen}
        className=""
      >
        <Icon
          icon="he-connect"
          size="s"
          fill={getStatusColor(aggregatedStatus)}
        />
      </Button>

      {open && (
        <div className="connectivity-container">
          <div className="connectivity-header">
            <h4>IoT Connection Status</h4>
            <h4 style={{ color: getStatusColor(aggregatedStatus) }}>{aggregatedStatusDisplay}</h4>
          </div>
          <div className="connectivity-content">
            {connectivityTree.length && connectivity(connectivityTree)}{' '}
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default ConnectivityStatus;
