import React, { useState, useCallback, useEffect } from 'react';
import { Icon } from '@iq/react-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toZonedTime } from 'date-fns-tz';

import './ImportMaintenancePlanModal.scss';
import JSONEditor from '../../../JSONEditor';
import SimpleModal from '../../../SimpleModal';
import { getTimezone, useDateSubscription } from '../../../../bundles/application';
import {
  getStaticComponents,
  upsertComponents,
  getComponentsImportedAt,
  resetImportedComponentsAt,
  getImportErrors as getComponentImportErrors,
  setImportErrors as setComponentImportErrors,
} from '../../../../bundles/components';
import { EXPORT_EVENT_PANEL } from '../../../../constants';
import {
  bulkOverwriteEventSeries,
  getImportErrors as getEventImportErrors,
  getImportedAt as getEventImportedAt,
  setImportErrors as setEventImportedErrors,
  setImportedAt as setEventImportedAt,
} from '../../../../bundles/events';

import { getImportEventsSchema, getImportEventUiSchema } from './ImportMaintenancePlanSchema';
import { addMissingDataToMaintenanceSeries } from '../../../../externalUtils';
import Loader from '../../../Loader';

const ImportMaintenancePlanModal = ({
  onClose,
  site,
  maintenanceSeriesToCreate,
  maintenanceComponentsToCreate,
  missingComponents,
}) => {
  const dispatch = useDispatch();

  const maintenanceSeriesInfo = [
    `${maintenanceSeriesToCreate.eventSeries.length} maintenance series will be created on import.`,
    `All series and occurrences will be tagged with "${maintenanceSeriesToCreate.overwriteTag}".`,
    `Any existing series or occurrences not in an open or closed state which have this tag will be removed.`,
  ];
  const inputDateRange = useDateSubscription(EXPORT_EVENT_PANEL);
  const siteComponents = useSelector(getStaticComponents);
  const eventImportErrors = useSelector(getEventImportErrors);
  const componentImportErrors = useSelector(getComponentImportErrors);
  const eventsImported = useSelector(getEventImportedAt);
  const timezone = useSelector(getTimezone);
  const [importing, setImporting] = useState(false);
  const [message, setMessage] = useState();
  const [importErrors, setImportErrors] = useState([]);
  const compsImported = useSelector(getComponentsImportedAt);
  const [formData, setFormData] = useState({});
  const iconContainer = (
    <div className="message--icon">
      <Icon
        icon="he-info"
        size="s"
      />
    </div>
  );

  async function createAllMaintenanceSeries() {
    const [allComponentsFound, maintenanceSeries] = addMissingDataToMaintenanceSeries(
      maintenanceSeriesToCreate,
      siteComponents,
      formData.planningDates.from,
      formData.planningDates.to,
      timezone
    );

    if (allComponentsFound) {
      dispatch(bulkOverwriteEventSeries(maintenanceSeries));
    } else {
      // This should never happen
      setImportErrors(['An unexpected error occurred']);
      setImporting(false);
    }
  }

  const onFormSubmit = useCallback(async () => {
    if (formData.planningDates.from !== null) {
      if (formData.createUnmatchedComponents) {
        dispatch(resetImportedComponentsAt());
        setImporting(true);
        dispatch(upsertComponents(site.id, site.org, maintenanceComponentsToCreate, true));
      } else {
        setImporting(true);
        createAllMaintenanceSeries();
      }
    }
  }, [formData]);

  useEffect(() => {
    if (missingComponents.length === 0) {
      setMessage(
        <div className="message--container">
          {iconContainer}
          <div className="message--message">
            <div>{`${maintenanceSeriesInfo[0]}`}</div>
            {maintenanceSeriesInfo.slice(1).map((messagePart, key) => {
              return <div key={key}>{messagePart}</div>;
            })}
          </div>
        </div>
      );
    }

    setFormData((fd) => ({ ...fd, from: inputDateRange.startDate, to: inputDateRange.endDate }));
  }, [inputDateRange]);

  const onHandleChange = useCallback(
    ({ formData: updatedFormData }) => {
      if (updatedFormData.createUnmatchedComponents) {
        setMessage(
          <div className="message--container">
            {iconContainer}
            <div className="message--message">
              <div>{`${missingComponents.length} missing component${missingComponents.length > 1 ? 's' : ''}, ${maintenanceComponentsToCreate.length - missingComponents.length} parent component${maintenanceComponentsToCreate.length - missingComponents.length > 1 ? 's' : ''}
              and ${maintenanceSeriesInfo[0]}`}</div>
              {maintenanceSeriesInfo.slice(1).map((messagePart, key) => {
                return <div key={key}>{messagePart}</div>;
              })}
            </div>
          </div>
        );
        setImportErrors([]);
      } else if (formData.createUnmatchedComponents && !updatedFormData.createUnmatchedComponents) {
        setMessage(
          missingComponents.length > 0 ? null : (
            <div className="message--container">
              {iconContainer}
              <div className="message--message">
                {maintenanceSeriesInfo.slice(1).map((messagePart, key) => (
                  <div key={key}>{messagePart}</div>
                ))}
              </div>
            </div>
          )
        );
      }
      setFormData((fd) => ({ ...fd, ...updatedFormData }));
    },
    [formData]
  );

  useEffect(() => {
    if (importing && compsImported) {
      createAllMaintenanceSeries();
      dispatch(resetImportedComponentsAt());
    }
  }, [importing, compsImported, maintenanceSeriesToCreate, siteComponents]);

  useEffect(() => {
    if (importing && eventsImported) {
      setImporting(false);
      dispatch(setEventImportedAt(0));
      onClose({ getSeries: true });
    }
  }, [importing, eventsImported]);

  useEffect(() => {
    if (importing) {
      if (componentImportErrors.length > 0 || eventImportErrors.length > 0) {
        // Errors will be shown in notification from bundle
        dispatch(setComponentImportErrors([]));
        dispatch(setEventImportedErrors([]));
        setImporting(false);
        onClose();
      }
    }
  }, [componentImportErrors, eventImportErrors, importing]);

  const customPlanningValidator = useCallback(
    (data, errors) => {
      const {
        planningDates: { from, to },
      } = data;

      const startNotInFutureError = 'start date must be in the future for recurring events';
      if (from && toZonedTime(from, timezone).getTime() < Date.now()) {
        errors.planningDates.from.addError(startNotInFutureError);
      } else if (errors.planningDates.from.__errors.includes(startNotInFutureError)) {
        // eslint-disable-next-line no-param-reassign
        errors.planningDates.from.__errors = errors.planningDates.from.__errors.filter(
          (e) => e !== startNotInFutureError
        );
      }

      const startGtEndError = 'start date must preceed end date';
      if (from && to && to < from) {
        errors.planningDates.from.addError(startGtEndError);
      } else if (errors.planningDates.from.__errors.includes(startGtEndError)) {
        // eslint-disable-next-line no-param-reassign
        errors.planningDates.from.__errors = errors.planningDates.from.__errors.filter(
          (e) => e !== startGtEndError
        );
      }

      const noStartError = 'date is required';
      if (!from) {
        errors.planningDates.from.addError(noStartError);
      } else if (errors.planningDates.from.__errors.includes(noStartError)) {
        // eslint-disable-next-line no-param-reassign
        errors.planningDates.from.__errors = errors.planningDates.from.__errors.filter(
          (e) => e !== noStartError
        );
      }

      return errors;
    },
    [timezone]
  );

  const onCancelExport = () => {
    onClose();
  };

  return (
    <SimpleModal
      className="maintenance-import-modal"
      onClose={onClose}
      title="Import Maintenance Plan [Beta]"
    >
      {!importing && (
        <div>
          <div className="json-editor">
            <JSONEditor
              formData={formData}
              schema={getImportEventsSchema(
                missingComponents.length,
                formData.createUnmatchedComponents
              )}
              uiSchema={getImportEventUiSchema()}
              editorOnly
              cancelCallback={onCancelExport}
              onFormChange={onHandleChange}
              onFormSubmit={onFormSubmit}
              saveButtonText={'Import Maintenance Plan'}
              initialEditMode={true}
              showEditButton={false}
              customValidate={customPlanningValidator}
              customMessage={message}
              submitDisabled={missingComponents.length > 0 && !formData.createUnmatchedComponents}
            />
          </div>
          <div>
            {importErrors.length > 0 &&
              importErrors.map((err, i) => {
                const key = `error-${i}`;
                return (
                  <p
                    key={key}
                    className="error-text"
                  >
                    {err}
                  </p>
                );
              })}
          </div>
        </div>
      )}
      {importing && (
        <div>
          <Loader />
        </div>
      )}
    </SimpleModal>
  );
};

ImportMaintenancePlanModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  site: PropTypes.object.isRequired,
  maintenanceSeriesToCreate: PropTypes.object.isRequired,
  maintenanceComponentsToCreate: PropTypes.array.isRequired,
  missingComponents: PropTypes.array.isRequired,
};

export default ImportMaintenancePlanModal;
