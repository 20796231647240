import { Input, Button, Icon } from '@iq/react-components';
import React, { useState, useEffect, useCallback } from 'react';
import { RRule } from 'rrule';
import { getTimezoneOffset } from 'date-fns-tz';

import CustomSelect from '../../CustomSelect';
import './RecurrenceScheduleField.scss';
import { DateTimePicker } from '../../DateTimePicker';
import { siteToUtc, utcToSite } from '../../../datetimeUtils';

const weekdays = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

const defaultRuleConfig = {
  interval: 1,
  freq: 'WEEKLY',
  byweekday: [],
  bymonthday: null,
  until: null, // stored in site time
};

const ruleMap = {
  BYDAY: 'byweekday',
  BYMONTHDAY: 'bymonthday',
  FREQ: 'freq',
  INTERVAL: 'interval',
  UNTIL: 'until',
};

const parseRecurrenceRule = (rule) => {
  let ruleSansStartDate = rule;
  const rRuleObj = RRule.fromString(rule);
  if (rule.includes('DTSTART') && rule.includes('\n')) {
    [, ruleSansStartDate] = ruleSansStartDate.split('\n');
  }
  const [, rulePart] = ruleSansStartDate.split(':');
  const parts = rulePart.split(';');

  const config = parts.reduce(
    (acc, part) => {
      const [key, value] = part.split('=');
      const configKey = ruleMap[key];

      if (configKey) {
        if (key === 'BYDAY') {
          const formattedDays = value.split(',');
          acc[configKey] = formattedDays;
        } else if (key === 'UNTIL') {
          acc[configKey] = rRuleObj.origOptions.until;
        } else {
          acc[configKey] = value;
        }
      }
      return acc;
    },
    { ...defaultRuleConfig }
  );
  return config;
};

const getRuleString = (config) => {
  const preparedConfig = {
    interval: config.interval ? parseInt(config.interval, 10) : null,
    freq: RRule[config.freq],
    byweekday: config?.byweekday?.length ? config?.byweekday?.map((day) => RRule[day]) : null,
    until: config.until || null,
  };
  return new RRule(preparedConfig).toString();
};

const RecurrenceScheduleField = (props) => {
  const {
    schema: { title, timezone, editable = true },
    formData: recurrenceRule,
    onChange,
  } = props;

  const hasValidRecurrenceRule = !!recurrenceRule?.includes('RRULE:');
  const [periodInterval, setPeriodInterval] = useState(null);
  const [ruleConfig, setRuleConfig] = useState(
    hasValidRecurrenceRule ? parseRecurrenceRule(recurrenceRule) : { ...defaultRuleConfig }
  );
  const [recurring, setRecurring] = useState(hasValidRecurrenceRule);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!recurring) {
      onChange(null);
    } else {
      onChange(getRuleString(ruleConfig));
    }
  }, [recurring, ruleConfig]);

  useEffect(() => {
    if (hasValidRecurrenceRule) {
      const newConfig = parseRecurrenceRule(recurrenceRule);
      setRuleConfig(() => newConfig);
    }
  }, [recurrenceRule, hasValidRecurrenceRule]);

  const frequencyOptions = [
    { value: 'WEEKLY', label: 'Week' },
    { value: 'MONTHLY', label: 'Month' },
    { value: 'YEARLY', label: 'Year' },
  ];

  const handleToggleRecurring = () => {
    setRecurring((prevVal) => !prevVal);
  };

  const intervalErrorMessages = {
    WEEKLY: 'interval must be between 1 and 52',
    MONTHLY: 'interval must be between 1 and 12',
    YEARLY: 'interval must be between 1 and 50',
  };

  const handleSetInterval = useCallback(
    (interval) => {
      if (
        interval &&
        ((ruleConfig.freq === 'WEEKLY' && interval >= 1 && interval <= 52) ||
          (ruleConfig.freq === 'MONTHLY' && interval >= 1 && interval <= 12) ||
          (ruleConfig.freq === 'YEARLY' && interval >= 1 && interval <= 50))
      ) {
        setErrors((e) => ({ ...e, interval: null }));
        setRuleConfig((config) => ({ ...config, interval }));
      } else {
        setErrors((e) => ({ ...e, interval: intervalErrorMessages[ruleConfig.freq] }));
        onChange('invalid');
      }
      setPeriodInterval(interval);
    },
    [ruleConfig.freq]
  );

  const handleSetFrequency = useCallback(
    (frequency) => {
      let hasErrors = false;
      setRuleConfig((config) => {
        const newConfig = { ...config, freq: frequency };
        if (frequency === 'YEARLY') {
          if (!(periodInterval >= 1 && periodInterval <= 50)) {
            hasErrors = true;
          }
          newConfig.byweekday = null;
        }
        if (frequency === 'WEEKLY') {
          if (!(periodInterval >= 1 && periodInterval <= 52)) {
            hasErrors = true;
          }
          newConfig.bymonthday = null;
        }
        if (frequency === 'MONTHLY') {
          if (!(periodInterval >= 1 && periodInterval <= 12)) {
            hasErrors = true;
          }
          newConfig.byweekday = null;
        }
        return newConfig;
      });
      if (hasErrors) {
        setErrors((e) => ({ ...e, interval: intervalErrorMessages[frequency] }));
        onChange('invalid');
      }
    },
    [periodInterval]
  );

  const handleSetWeekday = (weekday) => {
    setRuleConfig((config) => {
      if (!config.byweekday.includes(weekday)) {
        return { ...config, byweekday: [...config.byweekday, weekday] };
      }
      return { ...config, byweekday: config.byweekday.filter((day) => day !== weekday) };
    });
  };

  const handleSetUntil = useCallback(
    (change) => {
      // we need to convert back to site time and get new Date with correct offset since we will attach a tz to the rrule
      const siteUntil = utcToSite(change, timezone);
      const updatedUntil = siteUntil
        ? new Date(
            Date.UTC(
              siteUntil.getFullYear(),
              siteUntil.getMonth(),
              siteUntil.getDate(),
              siteUntil.getHours(),
              siteUntil.getMinutes(),
              siteUntil.getSeconds()
            )
          )
        : null;

      setRuleConfig((config) => ({ ...config, until: updatedUntil }));
    },
    [timezone]
  );

  const { interval, freq, byweekday, until } = ruleConfig;
  // we need to find the browser's timezone offset and subtract it from the Date we get
  // from the rule config to display the correct time in the DateTimePicker
  const siteTime = until ? siteToUtc(until, timezone) : null;
  const appTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const appTzOffset = getTimezoneOffset(appTimezone);
  const displayUntil = siteTime ? new Date(siteTime.getTime() - appTzOffset) : null;

  useEffect(() => {
    setPeriodInterval(interval);
  }, [interval]);

  return (
    editable && (
      <div className="recurrence-container">
        <div className="title">
          <label htmlFor="recurring-toggle">{title}</label>
          <Button
            id="recurring-toggle"
            type="button"
            className="toggle-recurrence"
            design="text"
            tabIndex="-1"
            tooltip={recurring ? 'Disable recurrence' : 'Enable recurrence'}
          >
            <Icon
              icon={recurring ? 'toggle-on' : 'toggle-off'}
              className={recurring ? 'toggle-on' : 'toggle-off'}
              onClick={handleToggleRecurring}
              size="m"
            />
          </Button>
        </div>
        {recurring && (
          <div className="recurrence-options-container">
            <div className="recurrence-options-section">
              <label
                className="interval-label"
                htmlFor="interval"
              >
                Every
              </label>
              <div className="recurrence-options">
                <Input
                  className="recurrence-interval"
                  id="interval"
                  type="number"
                  min={1}
                  max={365}
                  value={periodInterval}
                  onChange={(e) => handleSetInterval(e.target.value)}
                />
                <CustomSelect
                  className="frequency-select"
                  isMulti={false}
                  rawOptions={frequencyOptions}
                  placeholder="Select interval"
                  value={freq}
                  onChange={handleSetFrequency}
                />
              </div>
              {errors.interval && <div className="errors">{errors.interval}</div>}
            </div>
            <div className="recurrence-options-section">
              {freq === 'WEEKLY' && (
                <div className="day-container">
                  {weekdays.map((weekday) => (
                    <Button
                      key={weekday}
                      className={`day-label ${byweekday.includes(weekday) ? 'selected' : ''}`}
                      onClick={() => handleSetWeekday(weekday)}
                      design="text"
                    >
                      {weekday}
                    </Button>
                  ))}
                </div>
              )}
            </div>
            <div className="recurrence-options-section">
              <div className="until-label">
                <label>Until</label>
                <div>
                  <Button
                    activity="secondary"
                    slim
                    onClick={() => {
                      handleSetUntil(null);
                    }}
                    type="button"
                  >
                    {`Remove`}
                  </Button>
                </div>
              </div>
              <div className="until-container">
                <DateTimePicker
                  id="recurrence-until"
                  value={displayUntil}
                  onChange={(_, newUntil) => handleSetUntil(newUntil)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default RecurrenceScheduleField;
