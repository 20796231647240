import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from '@iq/react-components';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import Draggable from 'react-draggable';
import { Close as MinimizeIcon } from '@mui/icons-material';
import SimpleModal from '../SimpleModal';

import { useComponentId, useClientSizeRefresh } from '../../utils';
import AppContext from '../../AppContext';
import { getActiveSiteId } from '../../bundles/sites';
import { getSyncHealth, getSyncedSources } from '../../bundles/sources';
import { getIsTenantOrSuperAdmin, getHasPermission } from '../../bundles/auth';

import { ComponentsPanel } from '../panels/ComponentsPanel';
import EventTimelinePanel from '../panels/EventTimelinePanel';
import { FilesPanel } from '../panels/FilesPanel';
import { TextPanel } from '../panels/TextPanel';
import { TFRPanel } from '../panels/TFRPanel';
import { ViewerPanel } from '../panels/ViewerPanel';
import { VisualizationsPanel } from '../panels/VisualizationsPanel';
import { HealthPanel } from '../panels/HealthPanel';
import { ComponentAttributesPanel } from '../panels/ComponentAttributesPanel';
import PanelSettings from './PanelSettings';
import SyncHealthNotification from '../SyncHealthNotification';

const panels = {
  components: ComponentsPanel,
  'event-timeline': EventTimelinePanel,
  files: FilesPanel,
  text: TextPanel,
  'tfr-viewer': TFRPanel,
  viewer: ViewerPanel,
  visualizations: VisualizationsPanel,
  health: HealthPanel,
  'component-properties': ComponentAttributesPanel,
};

const renderComponent = (
  format,
  isFullScreen,
  isFloatMode,
  options,
  pageIndex,
  panelId,
  site,
  type,
  componentScope = {}
) => {
  const props = {
    format,
    isFullScreen,
    isFloatMode,
    componentScope,
    ...options,
    pageIndex,
    panelId,
    site,
  };

  const PanelComponent = panels[type];

  if (!PanelComponent) return <div>Unknown component</div>;
  return <PanelComponent {...props} />;
};

const Panel = React.memo(
  (props) => {
    const {
      id,
      hidePanel = false,
      allowFullScreen,
      columnIndex,
      componentScope,
      format,
      options = {},
      pageIndex,
      panelIndex,
      sectionIndex,
      site,
      title,
      displayTitle,
      type,
      onHidePanel,
    } = props;
    const [settingsType, setSettingsType] = useState();
    const [settingsUrl, setSettingsUrl] = useState();
    const history = useHistory();

    const activeSiteId = useSelector(getActiveSiteId);
    const { org, id: siteId } = site || {};
    const isTenantOrSuperAdmin = useSelector((state) => getIsTenantOrSuperAdmin(state, org));
    const hasSiteWrite = useSelector((state) =>
      getHasPermission(state, 'sites/Write', { org, site: siteId })
    );
    const hasFilesWrite = useSelector((state) =>
      getHasPermission(state, 'files/Write', { org, site: siteId })
    );
    const hasVisualizationsWrite = useSelector((state) =>
      getHasPermission(state, 'visualizations/Write', { org, site: siteId })
    );
    const hasEventsWrite = useSelector((state) =>
      getHasPermission(state, 'events/Write', { org, site: siteId })
    );
    const hasComponentsWrite = useSelector((state) =>
      getHasPermission(state, 'components/Write', { org, site: siteId })
    );

    const quickPanelPermissions = {
      files: hasFilesWrite,
      viewer: hasSiteWrite,
      visualizations: hasVisualizationsWrite,
      'event-timeline': hasEventsWrite,
    };
    const panelPermissions = {
      ...quickPanelPermissions,
      components: hasComponentsWrite,
      text: hasSiteWrite,
      'event-timeline': hasEventsWrite,
      'tfr-viewer': hasSiteWrite,
      health: hasComponentsWrite,
      'component-properties': hasComponentsWrite,
      'component-source-properties': hasComponentsWrite,
    };
    const {
      setIsFullScreen: setIsFullScreenGlobal,
      isViewerFullScreen,
      isFullscreen: isFullscreenGlobal,
      isTouchDevice,
    } = useContext(AppContext);
    const panelId = useComponentId();
    const refreshClientSize = useClientSizeRefresh();

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isFloatMode, setIsFloatMode] = useState(false);
    const [hide, setHide] = useState(hidePanel);
    const [showSyncHealth, setShowSyncHealth] = useState(false);
    const syncHealth = useSelector((state) => getSyncHealth(state, siteId));
    const syncedSources = useSelector(getSyncedSources);

    const toggleFullScreen = (persist) => {
      setIsFullScreenGlobal(!isFullScreen, persist);
      setIsFullScreen(!isFullScreen);
      refreshClientSize();
    };

    useEffect(() => {
      if (!isFullscreenGlobal) {
        setIsFullScreen(false);
      }
    });

    useEffect(() => {
      if (options.allowFloat && type !== 'viewer' && isViewerFullScreen && !isFullScreen) {
        setIsFloatMode(true);
      } else {
        setIsFloatMode(false);
      }
    }, [options.allowFloat, type, isViewerFullScreen, isFullScreen]);

    useEffect(() => {
      setHide(hidePanel);
    }, [hidePanel]);

    const persistentHeader = type !== 'viewer' || isTouchDevice;

    const headerClasses = classnames({
      'panel-header': true,
      transient: !persistentHeader,
      persistent: persistentHeader,
      floating: type === 'viewer',
      draggable: isFloatMode,
    });

    const openFullSettings = useCallback(() => {
      history.push(settingsUrl);
      setSettingsType(undefined);
    }, [setSettingsType, history, settingsUrl]);

    const handleCloseSettings = useCallback(() => setSettingsType(undefined), [setSettingsType]);

    const adminSettings = (sType) => {
      if (!sType) return null;

      return (
        <PanelSettings
          site={site}
          type={sType}
          pageIndex={pageIndex}
          sectionIndex={sectionIndex}
          columnIndex={columnIndex}
          panelIndex={panelIndex}
          onFullSettings={openFullSettings}
          onClose={handleCloseSettings}
        />
      );
    };

    const onSettings = () => {
      if (panelPermissions[type]) {
        let url = `/sites/${activeSiteId}/admin/layout/pages/${pageIndex}`;
        if (sectionIndex >= 0) {
          url += `/sections/${sectionIndex}`;
        }
        if (columnIndex >= 0) {
          url += `/columns/${columnIndex}`;
        }
        if (panelIndex >= 0) {
          url += `/panels/${panelIndex}`;
        }
        if (Object.keys(quickPanelPermissions).includes(type)) {
          setSettingsType(type);
          setSettingsUrl(url);
        } else {
          history.push(url);
        }
      } else {
        setSettingsType(type);
      }
    };

    const getSyncErrors = useCallback(() => {
      syncedSources.map((source) => ({
        source,
        errors: {
          syncErrors: syncHealth[`sync_${source.type}_events_${source.id}`],
          backfillErrors: syncHealth[`backfill${source.type}_events_${source.id}`],
        },
      }));
    }, [syncedSources, syncHealth]);

    // This is useful when needing to test syncError UI
    // const mockSyncErrorData = [
    //   {
    //     source: 'dec',
    //     syncErrors: {
    //       error: {
    //         failedRuns: 23,
    //         minDate: '2021-10-03T14:22:41',
    //         maxDate: '2021-12-03T14:22:41',
    //       },
    //     },
    //     backfillErrors: {
    //       error: {
    //         failedRuns: 55,
    //         minDate: '2021-10-03T14:22:41',
    //         maxDate: '2021-12-03T14:22:41',
    //       },
    //     },
    //   },
    //   {
    //     source: 'cake',
    //     syncErrors: {
    //       error: {
    //         failedRuns: 23,
    //         minDate: '2021-10-03T14:22:41',
    //         maxDate: '2021-12-03T14:22:41',
    //       },
    //     },
    //   },
    // ];
    // const syncErrors = mockSyncErrorData;

    const syncErrors = useMemo(
      () => (syncHealth ? getSyncErrors() : null),
      [syncHealth, getSyncErrors]
    );

    const syncHealthToggle =
      syncErrors && (type === 'files' || type === 'event-timeline' || type === 'tfr-viewer') ? (
        <div
          className="sync-health-toggler"
          onClick={() => {
            setShowSyncHealth(true);
          }}
        >
          <Icon
            size="s"
            icon="data-sync-error"
          />
          <div>Data sync error</div>
        </div>
      ) : null;

    const syncHealthModal = showSyncHealth ? (
      <SimpleModal
        title="Data Sync Failure"
        onClose={() => setShowSyncHealth(false)}
        size="s"
      >
        <SyncHealthNotification syncErrors={syncErrors} />
      </SimpleModal>
    ) : null;

    const panelTitle =
      displayTitle && title ? (
        <div className="panel-title">{title[0].toUpperCase() + title.slice(1)}</div>
      ) : null;

    const renderHeader = () => (
      <>
        <div className={headerClasses}>
          {panelTitle}
          {syncHealthToggle}
          <div className="tools">
            {((allowFullScreen && !isFloatMode) || type === 'viewer') && (
              <Button
                onClick={() => toggleFullScreen(type === 'viewer')}
                slim
                activity="secondary"
                icon={
                  <Icon
                    size="xs"
                    icon={isFullScreen ? 'he-actualsize' : 'he-fullscreen'}
                  />
                }
                tooltip={isFullScreen ? 'Exit Fullscreen' : 'Fullscreen'}
              />
            )}
            {(isTenantOrSuperAdmin || panelPermissions[type]) && (
              <Button
                onClick={onSettings}
                slim
                activity="secondary"
                icon={
                  <Icon
                    size="xs"
                    icon="he-settings"
                  />
                }
                tooltip="Settings"
              />
            )}
            {isFloatMode && type !== 'viewer' && (
              <Button
                onClick={() => onHidePanel(id)}
                slim
                activity="secondary"
                icon={<MinimizeIcon fontSize="small" />}
                tooltip="Close"
              />
            )}
          </div>
        </div>
      </>
    );

    const panelClasses = classnames({
      'panel-component': true,
      'hide-panel': hide,
      [`${type}-component-type`]: type,
      'full-screen': isFullScreen,
      floating: isFloatMode,
    });

    const panelElement = useMemo(
      () => (
        <div className={panelClasses}>
          {renderHeader()}
          {renderComponent(
            format,
            isFullScreen,
            isFloatMode,
            options,
            pageIndex,
            panelId,
            site,
            type,
            componentScope
          )}
        </div>
      ),
      [
        panelClasses,
        format,
        isFullScreen,
        isFloatMode,
        options,
        pageIndex,
        panelId,
        site,
        type,
        componentScope,
      ]
    );

    return (
      <>
        {isFloatMode ? (
          <Draggable
            bounds=".draggable-container"
            handle=".panel-header"
          >
            {panelElement}
          </Draggable>
        ) : (
          panelElement
        )}
        {adminSettings(settingsType)}
        {syncHealthModal}
      </>
    );
  },
  (prevProps, props) => JSON.stringify(prevProps) === JSON.stringify(props)
);

export default Panel;
