import React from 'react';
import { useSelector } from 'react-redux';

import { getInstructional } from '../../bundles/files';
import VideoPreview from '../FilePreviewModal/types/VideoPreview';
import SimpleModal from '../SimpleModal';

const InstructionalModal = (props) => {
  const { identifier, onClose, fullScreenRef, title } = props;
  const trainingVid = useSelector((state) => getInstructional(state, identifier));

  if (!trainingVid) return null;
  return (
    <SimpleModal
      onClose={onClose}
      overlayCanClose={false}
      title={title || 'Instructional video'}
    >
      <VideoPreview
        file={trainingVid}
        videoRef={fullScreenRef}
        noPadding
      />
    </SimpleModal>
  );
};

export default InstructionalModal;
