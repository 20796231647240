import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Icon, Button, Label } from '@iq/react-components';
import JSONEditor from '../../../JSONEditor';
import {
  getUserEventFiltersSchema,
  getUserEventFiltersUiSchema,
  iotEventFiltersSchema,
} from './eventFiltersSchema';
import { useFilterParams, useEventTypeFilter } from '../EventTimelineState';
import { requestLimitedUsers, getLimitedUsers } from '../../../../bundles/ad';
import { getTags } from '../../../../bundles/tags';
import { requestUserEventTypes } from '../../../../bundles/events';
import { getUserEvents } from '../../../../services';
import { displayNotification, checkIsOnline } from '../../../../bundles/notifications';
import getNotification from '../../../../bundles/notification-defaults';
import {
  setPollingDateRange,
  getPollingDateRange,
  getInitialPollingDateRange,
} from '../../../../bundles/application';

const EventFilters = React.memo((props) => {
  const { eventTypeFilter, panelId, site, availableUserEventTypes, availableIotEventTypes, mode } =
    props;

  const [, onChangeEventTypes] = useEventTypeFilter();
  const dispatch = useDispatch();
  const [filterParams, onChangeFilterParams] = useFilterParams();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(
    filterParams && Object.keys(filterParams).length > 0 ? filterParams : { iot: {}, user: {} }
  );
  const [showIotEvents, setShowIotEvents] = useState(
    filterParams?.iot ? filterParams.iot.showIotEvents : true
  );
  const [showUserEvents, setShowUserEvents] = useState(
    filterParams?.user ? filterParams.user.showUserEvents : true
  );
  const panelDateRange = useSelector((state) => getPollingDateRange(state, panelId));

  const ref = useRef(null);
  const users = useSelector(getLimitedUsers);
  const tags = useSelector(getTags);

  const handleClickOutside = useCallback((event) => {
    if (
      ref.current &&
      !(
        ref.current.contains(event.target) ||
        // event toggles class (toggle svg icons' parents)
        event.target?.classList?.contains('show-events') ||
        event.target?.parentElement?.classList?.contains('show-events') ||
        // react-select menu option class matching
        Array.from(event.target.classList.values())
          .map((v) => v.match(/^css-\w{6}-option$/))
          .some((match) => !!match)
      )
    ) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClickOutside, true);
    } else {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [open]);

  useEffect(() => {
    dispatch(requestLimitedUsers(site.id));
    dispatch(requestUserEventTypes());
  }, [site]);

  const resetFormData = () => {
    const resetData = { iot: {}, user: { quickFilter: 'none' } };
    setFormData(resetData);
    onChangeFilterParams(resetData);
  };

  useEffect(() => {
    if (mode !== 'relatedEvents') {
      if (eventTypeFilter === 'all') {
        setShowIotEvents(true);
        setShowUserEvents(true);
      } else if (showIotEvents && eventTypeFilter === 'user') {
        setShowIotEvents(false);
        setShowUserEvents(true);
        resetFormData();
      } else if (showUserEvents && eventTypeFilter === 'iot') {
        setShowUserEvents(false);
        setShowIotEvents(true);
        resetFormData();
      }
    }
  }, [eventTypeFilter, mode]);

  const onUserChange = ({ formData: updatedFormData }) => {
    let newData = JSON.parse(JSON.stringify(updatedFormData));
    const { quickFilter: filter } = newData;

    setFormData((data) => {
      let shouldReset = false;
      const { user } = data;

      if (filter !== 'none' && ((!user?.quickFilter && filter) || user?.quickFilter !== filter)) {
        newData = { quickFilter: filter, status: filter };
      } else if (
        filter === 'none' &&
        filter !== user?.quickFilter &&
        updatedFormData.status === user?.status
      ) {
        newData.status = 'all';
      }

      if (filter === 'none' && user?.quickFilter !== 'none') {
        shouldReset = true;
      }
      return { ...data, user: { ...newData }, shouldReset };
    });
  };

  const onIotChange = ({ formData: updatedFormData }) => {
    setFormData((data) => ({ ...data, iot: updatedFormData }));
  };

  // TBD
  const onFiltersApply = async () => {
    const filterData = formData;
    filterData.iot.showIotEvents = showIotEvents;
    filterData.user.showUserEvents = showUserEvents;
    if (filterData.user.quickFilter !== 'none') {
      const userEvents = await getUserEvents({
        site: site.id,
        org: site.org,
        state: [filterData.user.quickFilter],
      }).then(
        (res) => res,
        (e) => {
          console.error('Unable to fetch user events: ', e);
          dispatch(checkIsOnline());
          dispatch(displayNotification(getNotification('getUserEvents', 'error')()));
          return {};
        }
      );
      panelDateRange.startDate = new Date(
        userEvents.data[0]
          ? userEvents.data[0]?.from
          : getInitialPollingDateRange(panelId).startDate
      ).getTime();
      panelDateRange.endDate = new Date(
        userEvents.data[0]
          ? userEvents.data[userEvents.data.length - 1]?.from
          : getInitialPollingDateRange(panelId).endDate
      ).getTime();
      panelDateRange.label = undefined;
      dispatch(setPollingDateRange(panelDateRange, panelId));
    } else {
      const range = filterData.shouldReset ? getInitialPollingDateRange(panelId) : panelDateRange;
      dispatch(setPollingDateRange(range, panelId));
    }
    onChangeFilterParams(filterData);
    setOpen(!open);
  };

  const onFiltersReset = () => {
    resetFormData();
    dispatch(setPollingDateRange(getInitialPollingDateRange(panelId), panelId));
  };

  const userEventsFilterSchema = useMemo(
    () => getUserEventFiltersSchema(availableUserEventTypes, tags, users),
    [availableUserEventTypes, tags, users]
  );
  const userEventsFilterUiSchema = useMemo(() => getUserEventFiltersUiSchema(formData), [formData]);

  const onIotToggle = (val) => {
    setShowIotEvents(val);
    if (!val) {
      if (!showUserEvents) {
        setShowUserEvents(true);
      }
      onChangeEventTypes('user');
    } else {
      onChangeEventTypes('all');
    }
  };

  const onUserToggle = (val) => {
    setShowUserEvents(val);
    if (!val) {
      if (!showIotEvents) {
        setShowIotEvents(true);
      }
      onChangeEventTypes('iot');
    } else {
      onChangeEventTypes('all');
    }
  };

  const classes = classnames('event-filters-component', { open });

  return (
    <div
      ref={ref}
      className={classes}
    >
      <div
        className="header"
        onClick={() => setOpen(!open)}
      >
        <div className="label">Filters</div>
        <div className="icon-container">
          <Icon
            icon="he-down"
            size="xs"
          />
        </div>
      </div>
      <div className="content">
        <div className="actions">
          <Label>Filters</Label>
          <div className="actions-button">
            <Button
              onClick={onFiltersReset}
              activity="secondary"
              slim
            >
              Reset
            </Button>
            <Button
              slim
              onClick={onFiltersApply}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="headers">
          {['iot', 'all'].includes(eventTypeFilter) && (
            <div className="iot">
              <Label>IOT events</Label>
              {eventTypeFilter === 'all' && (
                <Button
                  design="text"
                  tooltip={showIotEvents ? 'Hide' : 'Show'}
                >
                  <Icon
                    size="m"
                    className={`show-events ${showIotEvents ? 'on' : ''}`}
                    icon={showIotEvents ? 'toggle-on' : 'toggle-off'}
                    onClick={() => onIotToggle(!showIotEvents)}
                  ></Icon>
                </Button>
              )}
            </div>
          )}
          {['user', 'all'].includes(eventTypeFilter) && (
            <div className="user">
              <Label>User events</Label>
              {eventTypeFilter === 'all' && (
                <Button
                  design="text"
                  tooltip={showUserEvents ? 'Hide' : 'Show'}
                >
                  <Icon
                    size="m"
                    className={`show-events ${showUserEvents ? 'on' : ''}`}
                    icon={showUserEvents ? 'toggle-on' : 'toggle-off'}
                    onClick={() => onUserToggle(!showUserEvents)}
                  ></Icon>
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="body">
          {['iot', 'all'].includes(eventTypeFilter) && (
            <div className="iot">
              {showIotEvents && (
                <JSONEditor
                  formData={formData.iot}
                  onFormChange={onIotChange}
                  schema={iotEventFiltersSchema(availableIotEventTypes)}
                  initialEditMode={true}
                  showEditButton={false}
                  showButtons={false}
                ></JSONEditor>
              )}
            </div>
          )}
          {['user', 'all'].includes(eventTypeFilter) && (
            <div className="user">
              {showUserEvents && (
                <JSONEditor
                  formData={formData.user}
                  onFormChange={onUserChange}
                  schema={userEventsFilterSchema}
                  uiSchema={userEventsFilterUiSchema}
                  initialEditMode={true}
                  showEditButton={false}
                  showButtons={false}
                ></JSONEditor>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default withRouter(EventFilters);
