import React, { useState } from 'react';
import { Icon, Button } from '@iq/react-components';
import './IconPickerField.scss';

const DEFAULT_ICONS = [
  'he-settings',
  'he-voltage',
  'he-wrench',
  'he-preview',
  'he-template',
  'he-auxiliarypower',
  'he-speedometer',
  'he-upempty',
  'he-train',
  'he-favoritefill',
  'he-datastore',
  'he-robot',
  'he-qrcode',
  'he-list',
  'he-building',
  'he-storage',
  'he-signal',
  'he-binary',
  'he-barchart',
  'he-locationtarget',
  'he-alert',
  'he-light-on',
  'he-transformer',
  'he-operation',
  'he-cart',
  'he-remove',
  'he-check',
  'he-edit',
  'he-forwards',
  'he-backwards',
  'he-map',
  'he-user',
  'he-refresh',
  'he-sun',
  'he-line-chart-alt',
  'he-close',
  'he-battery',
];

const IconPicker = (props) => {
  const {
    schema: { icons = [] },
    required,
    formData,
    value,
    onChange,
  } = props;
  const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);

  const handleIconChange = (icon) => {
    onChange(icon);
    setIsIconPickerOpen(false);
  };

  const iconList = icons.length ? icons : DEFAULT_ICONS;

  return (
    <div className="form-group field field-select">
      <label className="control-label">
        Icon
        {required && <span className="required">*</span>}
      </label>
      <Button
        activity="secondary"
        className="icon-picker-toggler"
        onClick={() => setIsIconPickerOpen(!isIconPickerOpen)}
        type="button"
      >
        <Icon icon={formData || value || DEFAULT_ICONS[1]} />
      </Button>
      {isIconPickerOpen && (
        <div className="icon-picker-popup">
          <div className="icon-picker-icons">
            {iconList.map((icon) => (
              <Button
                key={icon}
                activity="secondary"
                onClick={() => handleIconChange(icon)}
                type="button"
              >
                <Icon icon={icon} />
              </Button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IconPicker;
