import { panelViews } from './EventTimelinePanel';
import { optionalIotEventColumns, optionalUserEventColumns } from './EventList';
import { getGroupedUserEventOptions } from './utils';
import { SEVERITIES } from '../../../constants';

// eslint-disable-next-line no-unused-vars
const getFilters = (types) => {
  const filters = {};
  const iotEventTypes = [
    ...Object.entries(SEVERITIES).map(([k, v]) => ({
      enum: [k],
      title: v,
    })),
  ];

  const groupedOptions = getGroupedUserEventOptions(types);

  const userEventTypes = types?.length
    ? [
        ...types.map((type) => ({
          enum: [type.id],
          title: type.name,
        })),
      ]
    : [];

  const iotFilterFields = {
    iotExcludeTypes: {
      type: 'array',
      title: 'IoT Event types to exclude',
      default: ['internal'],
      uniqueItems: true,
      isMulti: true,
      items: {
        type: 'string',
        anyOf: iotEventTypes,
      },
    },
    iotColumns: {
      type: 'array',
      title: 'IoT Event List Columns (additional)',
      default: [],
      items: {
        type: 'string',
        anyOf: optionalIotEventColumns.map((col) => ({
          enum: [col.id],
          title: col.title,
        })),
      },
      uniqueItems: true,
      isMulti: true,
    },
  };

  const userFilterFields = {
    userExcludeTypes: {
      type: 'array',
      title: 'User Event types to exclude',
      uniqueItems: true,
      isMulti: true,
      groupedOptions,
      items: {
        type: 'string',
        anyOf: userEventTypes,
      },
    },
    userColumns: {
      type: 'array',
      title: 'User Event List Columns (additional)',
      default: [],
      items: {
        type: 'string',
        anyOf: optionalUserEventColumns.map((col) => ({
          enum: [col.id],
          title: col.title,
        })),
      },
      uniqueItems: true,
      isMulti: true,
    },
  };

  if (types?.length) {
    filters.eventTypes = {
      type: 'object',
      title: '',
      properties: {
        types: {
          title: 'Event types',
          type: 'string',
          default: 'all',
          oneOf: [
            { enum: ['all'], title: 'All' },
            { enum: ['user'], title: 'User Events' },
            { enum: ['iot'], title: 'IoT Events' },
          ],
        },
      },
      dependencies: {
        types: {
          oneOf: [
            {
              properties: {
                types: {
                  enum: ['iot'],
                },
                hideInactiveSystemEvents: {
                  title: 'Hide inactive control system',
                  type: 'boolean',
                  default: true,
                },
                ...iotFilterFields,
              },
            },
            {
              properties: {
                types: {
                  enum: ['user'],
                },
                ...userFilterFields,
              },
            },
            {
              properties: {
                types: {
                  enum: ['all'],
                },
                hideInactiveSystemEvents: {
                  title: 'Hide inactive control system',
                  type: 'boolean',
                  default: true,
                },
                ...iotFilterFields,
                ...userFilterFields,
              },
            },
          ],
        },
      },
    };
  }

  return filters;
};

const getEventTimeLinePanelSchema = (types) => ({
  title: {
    type: 'string',
    default: 'Event Timeline',
  },
  options: {
    type: 'object',
    properties: {
      views: {
        type: 'array',
        title: 'Views (forced limited list view in narrow columns)',
        default: panelViews.map((item) => item.type).slice(0, -1),
        items: {
          type: 'string',
          anyOf: panelViews.map((panel) => ({
            enum: [panel.type],
            title: panel.title,
          })),
        },
        uniqueItems: true,
        isMulti: true,
      },
      limits: {
        type: 'object',
        title: 'Limited list options',
        properties: {
          time: {
            type: 'string',
            title: 'Events',
            default: 'past',
            oneOf: [
              { enum: ['past'], title: 'Previous' },
              { enum: ['future'], title: 'Upcoming' },
            ],
          },
          range: {
            type: 'object',
            title: 'Time span',
            properties: {
              period: {
                type: 'string',
                title: 'Period',
                default: 'hours',
                oneOf: [
                  { enum: ['minutes'], title: 'Minutes' },
                  { enum: ['hours'], title: 'Hours' },
                  { enum: ['days'], title: 'Days' },
                ],
              },
              numberPeriods: {
                type: 'number',
                title: 'Span',
                default: 1,
                min: 1,
                max: 365,
              },
            },
          },
          limit: {
            type: 'number',
            title: 'Number of events',
            default: 10,
            min: 1,
            max: 100,
          },
        },
      },
      filters: {
        title: 'Filters',
        type: 'object',
        properties: getFilters(types),
      },
    },
  },
});

const getEventTimeLineAdminSchema = (types = []) => ({
  type: 'object',
  properties: {
    filters: {
      title: '',
      description: !types.length ? 'None available' : '',
      type: 'object',
      properties: getFilters(types),
    },
  },
});

export { getEventTimeLinePanelSchema, getEventTimeLineAdminSchema };
