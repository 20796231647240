import React, { useMemo } from 'react';
import { Spinner, Icon } from '@iq/react-components';
import { useSelector } from 'react-redux';

import { getHasPermission } from '../../../../../bundles/auth';
import { DropdownMenu, DropdownTrigger } from '../../../../DropdownMenu';
import ListItem from '../../../../ListItem';

const UserList = (props) => {
  const { org, siteId, tenantUsers, searchText, roleFilter, isLoading, onDelete, getRoleOptions } =
    props;
  const canDeleteUsers = useSelector((state) =>
    getHasPermission(state, 'members/Delete', { org, site: siteId })
  );

  const roleFilteredUsers = useMemo(() => {
    if (roleFilter !== 'all') {
      return tenantUsers.filter((u) => (u.roles[roleFilter] || []).length);
    }
    return tenantUsers;
  }, [tenantUsers, roleFilter]);

  const filteredUsers = useMemo(() => {
    if (searchText) {
      const filters = searchText.trim().split(' ');
      return roleFilteredUsers.filter(
        (u) =>
          filters.every((f) => (u.name?.toLowerCase() || '').indexOf(f.toLowerCase()) !== -1) ||
          filters.every((f) => (u.email?.toLowerCase() || '').indexOf(f.toLowerCase()) !== -1)
      );
    }
    return roleFilteredUsers;
  }, [roleFilteredUsers, searchText]);

  const headers = ['Users', 'Login email', 'Role'];

  const headerCols = headers.map((h, i) => {
    const key = `col-${i}`;
    return (
      <div
        key={key}
        className="list-item--header__col"
      >
        {h}
      </div>
    );
  });

  const listItems = useMemo(
    () =>
      filteredUsers.map((user, i) => (
        <ListItem
          key={user.name + i}
          itemIndex={i}
          entity={user.name}
          item={user}
          onDelete={() => onDelete(user.id)}
          confirmationDialogTitle={'Delete user'}
          hideActionMenu
          actionButtons={
            canDeleteUsers && (
              <Icon
                icon="he-delete"
                onClick={() => onDelete(user.id)}
                className="delete-icon"
              />
            )
          }
          confimationDialogBody={
            <p style={{ paddingTop: '1.5rem' }}>
              Are you sure you want to remove <b>{user.name}</b> from your organization?
            </p>
          }
          columns={[
            <div
              key={`row-${i}-name`}
              className="ellipsed-text"
            >
              {user.name}
            </div>,
            <div
              key={`row-${i}-email`}
              className="ellipsed-text"
            >
              {user.email}
            </div>,
            <div
              key={`row-${i}-role`}
              className="role-select-column"
            >
              <DropdownMenu
                className="dropdown-menu"
                value={user.role}
                trigger={
                  <DropdownTrigger
                    label={user.role}
                    icon="he-down"
                  />
                }
                menuOptions={getRoleOptions(user)}
              />
            </div>,
          ]}
        />
      )),
    [filteredUsers, getRoleOptions]
  );

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <Spinner
            size="s"
            className="spinner"
          />
        </div>
      ) : (
        <div className="list-container">
          <ListItem
            isHeader
            columns={headerCols}
          />
          <div className="custom-thin-scrollbar">{listItems}</div>
        </div>
      )}
    </>
  );
};

export default UserList;
