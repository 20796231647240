import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Icon, Select, Creatable, useTheme, colors } from '@iq/react-components';

import Pill from '../Pill';
import './PopoutSelect.scss';

export default function PopoutSelect({
  label,
  round = false,
  icon = 'he-down',
  isMulti,
  options,
  value,
  onChange = () => null,
  isDisabled = false,
  creatable = false,
  onCreateOption = () => null,
  components = {},
  menuMinWidth,
  menuXPlacement = 'right',
  menuYPlacement = 'bottom',
  menuMaxHeight,
  fullHeight = false,
  ...props
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const Component = creatable ? Creatable : Select;

  const toggleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const selectStyles = {
    option: (base, state) => ({
      ...base,
      color: state.isSelected ? colors.Grey10 : base.color,
    }),
    menu: (base) => ({
      ...base,
      ...(fullHeight ? {} : { minHeight: 'unset' }),
      border: `1px solid ${theme === 'dark' ? colors.Grey70 : colors.Grey20}`,
      marginTop: '0.2rem',
      zIndex: '1000',
    }),
    menuList: (base) => ({
      ...base,
      ...(menuMaxHeight ? { maxHeight: menuMaxHeight } : {}),
      scrollbarColor: `${
        theme === 'dark'
          ? 'rgba(255, 255, 255, .25) rgba(0, 0, 0, 0)'
          : 'rgba(0, 0, 0, .25) rgba(0, 0, 0, 0)'
      }`,
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        background: `${theme === 'dark' ? 'rgba(255, 255, 255, .25)' : 'rgba(0, 0, 0, .25)'}`,
        borderRadius: '7px',
        boxShadow: `${
          theme === 'dark' ? '0 0 1px rgba(0, 0, 0, .5)' : '"0 0 1px rgba(255, 255, 255, .5)"'
        }`,
      },
    }),
    control: (base) => ({
      ...base,
      backgroundColor: `${theme === 'dark' ? colors.Grey90 : colors.Grey0}`,
    }),
  };

  const handleChange = (selection) => {
    if (!isMulti) {
      toggleOpen();
    }
    onChange(selection);
  };

  const triggerClass = classnames('popout-select--trigger', {
    active: isOpen,
    'full-height': fullHeight,
    round,
  });

  return (
    <div className="popout-select">
      <Button
        className={triggerClass}
        onClick={toggleOpen}
        activity="tertiary"
        design="outlined"
      >
        <div>
          {!round && (isMulti ? label || 'Select' : options.find((o) => o.value === value).label)}
          {isMulti && value && value.length > 0 && <Pill text={value.length} />}
        </div>
        <div className="icon-container">
          {typeof icon === 'string' ? (
            <Icon
              icon={icon}
              size="xs"
            />
          ) : (
            icon
          )}
        </div>
      </Button>
      {isOpen && (
        <>
          <div
            className={`popout-select--select-wrapper ${
              menuXPlacement === 'left' ? 'anchor-right' : 'anchor-left'
            }`}
            style={{ minWidth: (menuMinWidth && `${menuMinWidth}px`) || '200px' }}
          >
            <Component
              autoFocus
              isMulti={isMulti}
              components={{ DropdownIndicator: null, IndicatorSeparator: null, ...components }}
              hideSelectedOptions={false}
              menuIsOpen
              menuPlacement={menuYPlacement}
              onChange={handleChange}
              options={options}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={value}
              isDisabled={isDisabled}
              onCreateOption={onCreateOption}
              {...props}
            />
          </div>
          <div
            className="popout-select--select-mask"
            onClick={toggleOpen}
          />
        </>
      )}
    </div>
  );
}
