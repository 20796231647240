/* eslint-disable no-param-reassign */

const getImportEventsSchema = (missingComponents, checked) => {
  const schema = {
    type: 'object',
    properties: {
      planningDates: {
        type: 'object',
        required: ['from'],
        editable: true,
        title: 'Maintenance plan',
        rangedSelect: true,
        isRequired: true,
        properties: {
          from: { type: 'string' },
          to: { type: ['string', 'null'] },
        },
      },
      ...(missingComponents > 0 && {
        createUnmatchedComponents: {
          type: 'boolean',
          default: false,
          title: 'Create missing components',
          description: !checked
            ? `${missingComponents} of the components in the maintenance plan do not exist in IdentiQ.\n Enable creation by checking the box below.`
            : '',
        },
      }),
    },
  };

  return schema;
};

const getImportEventUiSchema = () => {
  return {
    planningDates: {
      'ui:field': 'dateTimePicker',
    },
  };
};

export { getImportEventsSchema, getImportEventUiSchema };
