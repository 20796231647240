import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Icon } from '@iq/react-components';
import ListItem from '../../ListItem';
import {
  requestStateSets,
  saveStateSet,
  getStateSets,
  deleteStateSet,
  updateStateSet,
} from '../../../bundles/statesets';

import Heading from '../../Heading';
import SimpleModal from '../../SimpleModal/SimpleModal';
import { schema, uiSchema } from './schemas/stateSetSchema';
import JSONEditor from '../../JSONEditor';
import { getActiveSite } from '../../../bundles/sites';

const StatesView = memo(() => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const { id: siteId, org } = useSelector(getActiveSite);
  const stateSets = useSelector(getStateSets);

  const customTransformErrors = (errors) => {
    return errors.filter((error) => error.name !== 'if' && error.name !== 'type');
  };

  useEffect(() => {
    dispatch(requestStateSets(siteId));
  }, [siteId]);

  const onFormChange = ({ formData: updatedFormData }) => {
    setFormData({ ...updatedFormData });
  };

  const handleUpdateState = (data) => {
    setShowModal(true);
    setFormData(data);
  };

  const onCancel = () => {
    setShowModal(false);
    setFormData({});
  };

  const onDelete = (stateSetId) => {
    dispatch(deleteStateSet(stateSetId, siteId));
  };

  const onSubmit = () => {
    const payload = { ...formData };
    if (payload.id) {
      dispatch(updateStateSet(payload));
      onCancel();
    } else {
      dispatch(saveStateSet(payload, siteId, org));
      onCancel();
    }
  };

  useEffect(() => {
    if (formData.isAreaStateSet && Array.isArray(formData.states)) {
      setFormData((prevData) => ({ ...prevData, states: {} }));
    }
  }, [formData.isAreaStateSet]);

  const headers = ['State set', 'Type', 'States', 'Text', 'Color', 'Trigger', 'Suggestion'];

  const headerCols = headers.map((h) => (
    <div className="list-item--header__col">
      {h.indexOf('Type') > -1 ? (
        <>
          <div className="stateset-type-info-trigger">
            {h}
            <Icon icon="he-help" />
          </div>
          <div className="stateset-type-info-tooltip--outer">
            <div className="stateset-type-info-tooltip--inner">
              <p>Stateset type cannot be changed after creation</p>
            </div>
          </div>
        </>
      ) : (
        h
      )}
    </div>
  ));

  const getStates = (stateSet) => {
    if (!stateSet.states) {
      return null;
    }
    const states = Array.isArray(stateSet.states) ? stateSet.states : [stateSet.states];

    return states.map((state) => [
      '',
      '',
      <div className="state-name">{state.name}</div>,
      <div className="state-text">{state.text}</div>,
      <div>
        <div
          className="state-color"
          style={{ backgroundColor: state.color }}
        ></div>
        {state.color}
      </div>,
      '',
      <div className="state-description">{state.description}</div>,
    ]);
  };

  return (
    <>
      <Heading contentRight={<Button onClick={() => setShowModal(true)}>Add state set</Button>} />

      <div className="manage-states-body">
        <div className="list-container">
          <ListItem
            isHeader
            columns={headerCols}
          />
          <div className="custom-thin-scrollbar">
            {stateSets?.map((s, i) => (
              <ListItem
                key={s.name + i}
                itemIndex={i}
                customEdit={true}
                onEdit={handleUpdateState}
                entity={`State set (${s.name})`}
                item={s}
                schema={schema}
                onDelete={() => onDelete(s.id)}
                confirmationDialogTitle={'Delete stateSet'}
                confimationDialogBody={
                  <>
                    <p style={{ paddingTop: '1.5rem' }}>
                      Are you sure you want to remove this stateset?
                    </p>
                  </>
                }
                accordionContent={getStates(s)}
                columns={[
                  <div className="ellipsed-text">{s.name}</div>,
                  <div className="ellipsed-text">
                    {s.isAreaStateSet === true ? 'Area' : 'Component'}
                  </div>,
                  <div className="count-indicator">{s?.states?.length || 1}</div>,
                  '',
                  '',
                  '',
                  '',
                ]}
              />
            ))}
          </div>
        </div>
      </div>
      {showModal && (
        <SimpleModal
          title={formData.id ? `Update State set (${formData?.name})` : 'Add state set'}
          onClose={onCancel}
        >
          <div>
            <JSONEditor
              schema={schema}
              uiSchema={uiSchema()}
              formData={formData || {}}
              customTransformErrors={customTransformErrors}
              saveButtonText={formData.id ? 'Update' : 'Create'}
              onFormChange={onFormChange}
              initialEditMode
              showEditButton={false}
              cancelCallback={onCancel}
              onFormSubmit={onSubmit}
              editorOnly
            />
          </div>
        </SimpleModal>
      )}
    </>
  );
});

export default StatesView;
