const getAttributeSchema = (components = [], isNewAttribute = true) => {
  const schema = {
    type: 'object',
    properties: {
      name: {
        title: 'Name',
        type: 'string',
      },
      dataType: {
        type: 'string',
        title: 'Data Type',
        oneOf: [
          {
            enum: ['string'],
            title: 'String',
          },
          {
            enum: ['number'],
            title: 'Number',
          },
          {
            enum: ['boolean'],
            title: 'Boolean',
          },
        ],
        default: 'string',
      },
      lastValue: {
        type: 'string',
        title: 'Value',
      },
      category: {
        title: 'Category',
        type: 'string',
      },
    },
    required: ['dataType', 'name', 'lastValue'],
    dependencies: {
      dataType: {
        oneOf: [
          {
            properties: {
              dataType: {
                enum: ['boolean'],
              },
              lastValue: {
                title: 'Value',
                type: 'string',
                oneOf: [
                  {
                    enum: ['true'],
                    title: 'True',
                  },
                  {
                    enum: ['false'],
                    title: 'False',
                  },
                ],
                default: 'true',
              },
            },
          },
          {
            properties: {
              dataType: {
                enum: ['string'],
              },
              lastValue: {
                title: 'Value',
                type: 'string',
              },
              unit: {
                title: 'Unit',
                type: 'string',
              },
            },
          },
          {
            properties: {
              dataType: {
                enum: ['number'],
              },
              lastValue: {
                title: 'Value',
                type: 'number',
              },
              unit: {
                title: 'Unit',
                type: 'string',
              },
            },
          },
        ],
      },
    },
  };

  if (components.length && isNewAttribute) {
    schema.properties.asset_id = {
      type: 'string',
      title: 'Component',
      anyOf: components.map((comp) => ({
        enum: [comp.asset.id],
        title: `${comp.name} (${comp.itemDesignation})`,
      })),
    };
    schema.required.push('asset_id');
  }

  return schema;
};

const attributeUiSchema = {
  'ui:order': ['name', 'dataType', 'lastValue', 'unit', '*'],
};

export { getAttributeSchema, attributeUiSchema };
