const panelTypes = [
  {
    displayName: 'Components',
    value: 'components',
  },
  {
    displayName: 'Event Timeline',
    value: 'event-timeline',
  },
  {
    displayName: 'Files',
    value: 'files',
  },
  {
    displayName: 'Text',
    value: 'text',
  },
  {
    displayName: 'TFR Viewer',
    value: 'tfr-viewer',
  },
  {
    displayName: 'Visualizations',
    value: 'visualizations',
  },
  {
    displayName: 'Status',
    value: 'health',
  },
  {
    displayName: 'Component Attributes',
    value: 'component-properties',
  },
  {
    displayName: 'Component Source Properties',
    value: 'component-source-properties',
  },
];

const panelSchema = {
  type: 'object',
  required: ['type', 'title'],
  properties: {
    title: {
      type: 'string',
      title: 'Panel title',
      minLength: 1,
    },
    displayTitle: {
      type: 'boolean',
      title: 'Display title',
      default: false,
    },
    allowFullScreen: {
      type: 'boolean',
      default: false,
      title: 'Allow full screen',
    },
    type: {
      type: 'string',
      title: 'Panel type',
      oneOf: panelTypes.map((panelType) => ({
        enum: [panelType.value],
        title: panelType.displayName,
      })),
    },
  },
};

const unscopedPanels = ['components', 'text', 'tfr-viewer', 'health', 'component-properties'];

const scopeOptions = [
  { enum: ['all'], title: 'All components' }, // all components
  { enum: ['active_all'], title: 'Active component, else all' }, // active component only, or all components if none active
  { enum: ['active_none'], title: 'Active component, else none' }, // active component only, or no components if none active
  { enum: ['active_children_all'], title: 'Active with children, else all' }, // active component and children, or all components if none active
  { enum: ['active_children_none'], title: 'Active with children, else none' }, // active component and children, or no components if none active
];
const COMPONENT_SCOPES = scopeOptions.map(({ enum: [scope] }) => scope);

const getExtendedPanelSchema = (extendedPanels) => ({
  ...panelSchema,
  dependencies: {
    type: {
      oneOf: Object.keys(extendedPanels).map(
        // eslint-disable-next-line no-confusing-arrow
        (panelType) =>
          unscopedPanels.includes(panelType)
            ? {
                properties: {
                  type: { enum: [panelType] },
                  ...extendedPanels[panelType],
                },
              }
            : {
                properties: {
                  type: { enum: [panelType] },
                  componentScope: {
                    type: 'object',
                    title: 'Component scope',
                    properties: {
                      selectedScope: {
                        title: 'Selected Scope',
                        type: 'string',
                        default: 'all',
                        oneOf: scopeOptions,
                      },
                      activeComponentTypeFilter: {
                        type: 'string',
                        default: 'any',
                        title: 'Only components of type',
                        oneOf: [
                          { enum: ['any'], title: 'Any' },
                          { enum: ['site'], title: 'Site' },
                          { enum: ['branch'], title: 'Branch' },
                          { enum: ['component'], title: 'Component' },
                          { enum: ['virtual'], title: 'Area' },
                        ],
                      },
                    },
                  },
                  ...extendedPanels[panelType],
                },
              }
      ),
    },
  },
});

export { panelSchema, getExtendedPanelSchema, COMPONENT_SCOPES };
