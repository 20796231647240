const getRoleSchema = (members, member) => {
  const roleSchema = {
    type: 'object',
    required: ['name', 'selectedRole'],
    properties: {
      name: {
        title: member ? 'Edit role name' : 'Role name',
        type: 'string',
      },
    },
  };

  if (!member) {
    roleSchema.properties.selectedRole = {
      title: 'Copy permissions from',
      type: 'string',
      oneOf: members.map((role) => ({
        enum: [role.type],
        title: role.type,
      })),
    };
  }

  return roleSchema;
};

const getPermissionsSchema = (allPermissions, includeList) => ({
  type: 'object',
  properties: allPermissions
    .sort((a, b) => a.localeCompare(b))
    .reduce(
      (acc, permission) => ({
        ...acc,
        [permission]: {
          type: 'array',
          items: {
            type: 'boolean',
          },
          includeList,
        },
      }),
      {}
    ),
});

const getPermissionsUiSchema = (allPermissions) =>
  allPermissions.reduce(
    (acc, permission) => ({
      ...acc,
      [permission]: { 'ui:field': 'permissionsSelector' },
    }),
    {}
  );

export { getRoleSchema, getPermissionsSchema, getPermissionsUiSchema };
