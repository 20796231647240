/* eslint-disable no-param-reassign */
import { FORM_STATES } from '../../constants';

const getTypeDependentMainFields = (
  userEventTypes,
  siteUsers,
  editable,
  editableLimited,
  viewMode,
  stateOptions
) => {
  const taskFields = {
    planned: {
      type: 'string',
      title: 'Activity',
      default: 'true',
      editable,
      anyOf: [
        { enum: ['true'], title: 'Planned' },
        { enum: ['false'], title: 'Reactive' },
      ],
    },
    state: {
      type: 'string',
      title: 'Status',
      default: stateOptions[0]?.const,
      editable: editableLimited,
      anyOf: stateOptions,
    },
    assignedTo: {
      type: 'string',
      title: 'Assignee',
      editable: editableLimited && siteUsers.length > 0,
      default: 'NA',
      oneOf: [
        ...siteUsers.map((user) => ({ enum: [user.id], title: user.name })),
        { enum: ['NA'], title: 'Not Assigned' },
      ],
    },
  };

  return userEventTypes.map((eventType) => ({
    if: {
      properties: {
        type: { enum: [eventType.id] },
      },
    },
    then: {
      required: eventType.task && !viewMode ? ['planned', 'state'] : [],
      properties: {
        ...(eventType.task ? taskFields : {}),
      },
    },
  }));
};

const getEventMainSchema = ({ modalState, siteUsers = [], userEventTypes = [], stateOptions }) => {
  const editable = modalState === FORM_STATES.edit || modalState === FORM_STATES.create;
  const editableLimited = editable || modalState === FORM_STATES.editLimited;
  const viewMode = modalState === FORM_STATES.view;
  const groupedOptions = userEventTypes.reduce(
    (acc, eventType) => {
      if (!eventType.task) {
        acc[0] = {
          ...acc[0],
          options: [...acc[0].options, { value: eventType.id, label: eventType.name }],
        };
      } else {
        acc[1] = {
          ...acc[1],
          options: [...acc[1].options, { value: eventType.id, label: eventType.name }],
        };
      }
      return acc;
    },
    [
      { label: 'EVENTS', options: [] },
      { label: 'TASKS', options: [] },
    ]
  );

  return {
    required: !viewMode ? ['type'] : [],
    properties: {
      type: {
        type: 'string',
        title: 'Event Type',
        default: 'general',
        editable,
        userEventTypes,
        groupedOptions,
        oneOf: [...userEventTypes.map((type) => ({ enum: [type.id], title: type.name }))],
      },
    },
    dependencies: {
      type: {
        allOf: getTypeDependentMainFields(
          userEventTypes,
          siteUsers,
          editable,
          editableLimited,
          viewMode,
          stateOptions
        ),
      },
    },
  };
};

const getEventGeneralSchema = ({
  site,
  dateDomain,
  modalState,
  components = [],
  hideInactiveSystemEvents,
  eventListColumns,
  sourceEventId,
  onNext = () => null,
}) => {
  const editable = modalState === FORM_STATES.edit || modalState === FORM_STATES.create;
  const viewMode = modalState === FORM_STATES.view;

  return {
    type: 'object',
    required: !viewMode ? ['name'] : [],
    properties: {
      name: {
        type: 'string',
        title: 'Name',
        editable,
      },
      description: {
        type: 'string',
        title: 'Description',
        editable,
        placeholder: 'Add event description here...',
      },
      components: {
        isMulti: true,
        type: 'array',
        title: 'Components',
        uniqueItems: true,
        editable,
        placeholder: 'Select associated components...',
        items: {
          type: 'string',
          anyOf: components.map((component) => ({
            enum: [component.id],
            title: `${component.name} (${component.itemDesignation})`,
          })),
        },
      },
      events: {
        title: 'Related Events',
        type: 'object',
        category: 'general',
        site,
        dateDomain,
        editable,
        hideInactiveSystemEvents,
        eventListColumns,
        sourceEventId,
        onNext,
      },
    },
  };
};

const getEventPlanningSchema = ({ modalState, isTask, isSeriesEvent, timezone }) => {
  const editable = modalState === FORM_STATES.edit || modalState === FORM_STATES.create;

  const schema = {
    type: 'object',
    properties: {
      planningDates: {
        type: 'object',
        required: ['from'],
        editable,
        title: isTask ? 'Planned' : 'Event',
        rangedSelect: true,
        isRequired: true,
        properties: {
          from: { type: 'string' },
          to: { type: ['string', 'null'] },
        },
      },
    },
  };

  if (isTask && !isSeriesEvent) {
    schema.properties = {
      ...schema.properties,
      recurrenceRule: {
        title: 'Recurring',
        type: ['string', 'null'],
        editable,
        timezone,
      },
      notifyAt: {
        type: 'string',
        default: 'none',
        title: 'Notify at',
        editable,
        oneOf: [
          { enum: ['none'], title: 'Do not notify' },
          { enum: ['1_day'], title: '1 day before' },
          { enum: ['1_week'], title: '1 week before' },
          { enum: ['1_month'], title: '1 month before' },
          { enum: ['3_months'], title: '3 months before' },
        ],
      },
    };
  }

  return schema;
};

const getEventExecutionSchema = ({ modalState }) => {
  const editable = modalState === FORM_STATES.edit || modalState === FORM_STATES.create;
  const editableLimited = editable || modalState === FORM_STATES.editLimited;

  return {
    type: 'object',
    properties: {
      executionDates: {
        editable: editableLimited,
        title: 'Execution',
        rangedSelect: true,
        properties: {
          executionFrom: { type: 'string' },
          executionTo: { type: 'string' },
        },
      },
    },
  };
};

const getEventAttributeSchemas = ({ modalState, userEventTypes = [] }) => {
  const editable = modalState === FORM_STATES.edit || modalState === FORM_STATES.create;

  return userEventTypes.reduce((typeAcc, eventType) => {
    typeAcc[eventType.id] = {
      type: 'object',
      properties: eventType.attributes.reduce((attrAcc, { field, name, ...attr }) => {
        attrAcc[field] = {
          ...attr,
          title: name,
          editable,
          readOnly: !editable,
        };
        return attrAcc;
      }, {}),
    };
    return typeAcc;
  }, {});
};

const EventGeneralUiSchema = {
  description: {
    'ui:widget': 'textarea',
    'ui:placeholder': 'Add event description here',
  },
  tags: {
    'ui:placeholder': 'Select tags',
  },
  components: {
    'ui:placeholder': 'Select associated components',
  },
  events: {
    'ui:field': 'relatedEvents',
  },
};

const EventPlanningUiSchema = {
  planningDates: {
    'ui:field': 'dateTimePicker',
  },
  recurrenceRule: {
    'ui:field': 'recurrenceScheule',
  },
};

const EventExecutionUiSchema = {
  executionDates: {
    'ui:field': 'dateTimePicker',
  },
};

export {
  getEventMainSchema,
  getEventGeneralSchema,
  EventGeneralUiSchema,
  getEventPlanningSchema,
  EventPlanningUiSchema,
  getEventExecutionSchema,
  EventExecutionUiSchema,
  getEventAttributeSchemas,
};
