import {
  DEFAULT_AGGREGATION_OPTIONS,
  GRANULARITY_OPTIONS,
  EXPORT_TIMESERIES_PANEL,
} from '../../../../constants';

const getVariableSchema = (statesets) => {
  const componentStates = (statesets || []).filter((s) => !s.isAreaStateSet);

  const schema = {
    type: 'object',
    required: ['name'],
    properties: {
      name: {
        type: 'string',
        title: 'Name',
      },

      itemDesignation: {
        type: 'string',
        title: 'Reference designation',
      },

      dataType: {
        type: 'string',
        title: 'Type',
      },

      unit: {
        type: 'string',
        title: 'Unit',
      },

      source_id: {
        type: 'string',
        title: 'Data Source',
        readonly: true,
      },
    },
  };

  if (statesets) {
    schema.properties.stateset_id = {
      type: ['string', 'null'],
      title: 'State set',
      default: null,
      placeholder: 'Select a stateset',
      oneOf: [
        {
          type: 'null',
          enum: [null],
          title: 'None',
        },
        ...componentStates.map((state) => ({
          type: 'string',
          enum: [state.id],
          title: state.name,
        })),
      ],
    };
  }

  return schema;
};

const variableUiSchema = {
  'ui:order': [
    'id',
    'name',
    'itemDesignation',
    'dataType',
    'unit',
    'source_id',
    'options',
    'stateset_id',
  ],
  id: { 'ui:widget': 'hidden' },
};

const getTimeSeriesExportSchema = (showAggregation, defaultGranularity, parentPanelId) => {
  const schema = {
    type: 'object',
    required: ['range', 'exportType'],
    properties: {
      range: {
        title: 'Export time range',
        default: null,
        panelId: parentPanelId,
        pickerId: EXPORT_TIMESERIES_PANEL,
      },
      granularity: {
        type: 'string',
        title: 'Aggregation granularity*',
        default: defaultGranularity,
        selectOptions: GRANULARITY_OPTIONS,
        description:
          'The Aggregation granularity and the time period together defines the intervals in which aggregation is performed.',
        action: 'info',
      },

      exportType: {
        type: 'string',
        title: 'Export as',
        default: 'xlsx',
        oneOf: [
          { enum: ['csv'], title: '.csv' },
          { enum: ['xlsx'], title: '.xlsx' },
        ],
      },
    },
  };
  const uiSchema = {
    range: {
      'ui:field': 'dateRangePicker',
    },
    granularity: {
      'ui:field': 'inputWithTooltip',
    },
    'ui:order': ['range', 'aggregation', 'granularity', 'exportType'],
  };
  if (showAggregation) {
    schema.properties.aggregation = {
      type: 'string',
      title: 'Aggregation*',
      default: 'avg',
      selectOptions: DEFAULT_AGGREGATION_OPTIONS,
      description:
        'For a selected time period, an aggregation method can be selected. The aggregation method narrows down from a larger set of datapoints to a smaller set of datapoints that makes sense in the specific time period that is selected. E.g in a specific timespan there can be 1 million data points, to visualize that in a graph those 1 million data points can be aggregated to 1000 datapoints thorugh the aggregation methods Max, Min and Average.',
      action: 'info',
    };
    uiSchema.aggregation = {
      'ui:field': 'inputWithTooltip',
    };
  }
  return [schema, uiSchema];
};

export { getVariableSchema, variableUiSchema, getTimeSeriesExportSchema };
