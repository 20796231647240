/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

import { getLastRefresh, getPollingInterval } from '../../../bundles/application';
import { requestLimitedUsers, requestUsers } from '../../../bundles/ad';
import { requestMembers, getIsAdmin, getIsTenantOrSuperAdmin } from '../../../bundles/auth';
import {
  getUserEventTypes,
  requestUserEventTypes,
  requestSeriesEvents,
} from '../../../bundles/events';
import {
  getStaticComponents,
  requestComponents,
  setImportErrors,
  getImportErrors,
} from '../../../bundles/components';
import { requestTags } from '../../../bundles/tags';
import { useClientSize } from '../../../utils';
import { SEVERITIES, EVENT_TYPE_USER, EVENT_TYPE_IOT, EVENT_TYPE_ALL } from '../../../constants';
import { useEventModal } from '../../EventModal';
import EventBrush from './EventBrush';
import EventLegends from './EventLegends';
import { EventList, optionalIotEventColumns, optionalUserEventColumns } from './EventList';
import EventTimelineHeader from './EventTimelineHeader';
import IotTimeline from './IotTimeline';
import UserTimeline from './UserTimeline';
import LoadingLayer from '../../LoadingLayer';
import {
  EventTimelineProvider,
  useDateDomainSource,
  useUserEventTypes,
} from './EventTimelineState';
import { parseMaintenanceExcel } from '../../../externalUtils';
import ConfirmationDialog from '../../ConfirmationDialog';
import ImportMaintenancePlanModal from './ImportMaintenancePlanModal/ImportMaintenancePlanModal';

export const SPLIT_VIEW = 'split';
export const LIST_VIEW = 'list view';
export const LIMITED_LIST_VIEW = 'limited list view';
export const TIMELINE_VIEW = 'timeline';

export const panelViews = [
  {
    type: TIMELINE_VIEW,
    title: 'Timeline view',
    icon: 'he-bubble-chart',
    tooltip: 'Show timeline view',
  },
  {
    type: SPLIT_VIEW,
    title: 'Split view',
    icon: 'he-group',
    tooltip: 'Show split view',
  },
  {
    type: LIST_VIEW,
    title: 'List view',
    icon: 'he-list',
    tooltip: 'Show list view',
  },
  {
    type: LIMITED_LIST_VIEW,
    title: 'Limited List View',
    icon: 'he-list',
    tooltip: 'Show limited list view',
  },
];

const EventTimelinePanel = memo((props) => {
  const {
    site,
    views,
    limits: {
      time,
      range: { period, numberPeriods },
      limit,
    },
    filters: {
      eventTypes: {
        types: panelEventType = EVENT_TYPE_ALL,
        iotExcludeTypes = [],
        userExcludeTypes = [],
        iotColumns = [],
        userColumns = [],
        hideInactiveSystemEvents = true,
      } = {},
    } = {},
    isFullScreen,
    componentScope,
    format,
    panelId,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestUserEventTypes());
    dispatch(requestTags(site.id));
    dispatch(requestSeriesEvents({ site: site.id, org: site.org, seriesOnly: true }));
  }, []);

  useEffect(() => {
    dispatch(requestComponents(site.id));
  }, []);

  const importErrors = useSelector(getImportErrors);
  const siteComponents = useSelector(getStaticComponents);
  const isTenantAdmin = useSelector((state) => getIsTenantOrSuperAdmin(state, site.org));
  const isAdmin = useSelector((state) => getIsAdmin(state, site));
  const [importing, setImporting] = useState(false);
  const [maintenanceSeriesToCreate, setMaintenanceSeriesToCreate] = useState([]);
  const [maintenanceComponentsToCreate, setmaintenanceComponentsToCreate] = useState([]);
  const [missingComponents, setMissingComponents] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    if (isTenantAdmin || isAdmin) {
      dispatch(requestUsers());
      dispatch(requestMembers(`site/${site.id}`));
    } else {
      requestLimitedUsers(site.id);
    }
  }, [isTenantAdmin, isAdmin]);

  useEffect(() => {
    if (importErrors.length) {
      setImporting(false);
      setShowErrors(true);
    } else {
      setShowErrors(false);
    }
  }, [importErrors]);

  const allUserEventTypes = useSelector(getUserEventTypes);
  const allIotEventTypes = [
    ...Object.entries(SEVERITIES).map(([k, v]) => ({
      name: v,
      id: k,
    })),
  ];
  const availableEventTypes = {
    iot: [EVENT_TYPE_IOT, EVENT_TYPE_ALL].includes(panelEventType)
      ? allIotEventTypes.filter((type) => !iotExcludeTypes.includes(type.id))
      : [],
    user: [EVENT_TYPE_USER, EVENT_TYPE_ALL].includes(panelEventType)
      ? allUserEventTypes.filter((type) => !userExcludeTypes.includes(type.id))
      : [],
  };

  let additionalColumns = [];
  if (panelEventType === 'iot') {
    additionalColumns = iotColumns;
  } else if (panelEventType === 'user') {
    additionalColumns = userColumns;
  } else {
    additionalColumns = [...iotColumns, ...userColumns];
  }

  const optionalEventColumns = [...optionalIotEventColumns, ...optionalUserEventColumns];
  const eventListColumns = optionalEventColumns.filter((c) => additionalColumns.includes(c.id));

  const layouts = useMemo(
    () => panelViews.filter((view) => !views || views.includes(view.type)),
    [views]
  );
  const [activeLayout, setActiveLayout] = useState(() => {
    if (format === 'narrow') return LIST_VIEW;
    return layouts[0] ? layouts[0].type : panelViews[0].type;
  });

  const onSetLayout = useCallback((layoutType) => setActiveLayout(layoutType), []);

  const [{ width, height }, clientRef] = useClientSize();
  const [dateDomain, refresh] = useDateDomainSource(panelId);
  const userEventTypes = useUserEventTypes();
  const sortedUserEventTypes = useMemo(
    () =>
      (userEventTypes || []).toSorted(({ name: nameA }, { name: nameB }) =>
        nameA.localeCompare(nameB)
      ),
    [userEventTypes]
  );

  const [eventDetail, onEventDetail] = useEventModal();
  const [fileUpload, onFileUpload] = useEventModal();

  const handleClose = useCallback(
    ({ getSeries = false } = {}) => {
      if (getSeries) {
        dispatch(requestSeriesEvents({ site: site.id, org: site.org, seriesOnly: true }, refresh));
      } else {
        refresh();
      }
      setImporting(false);
    },
    [site, refresh]
  );
  const onOpenNewEvent = useCallback(() => {
    onEventDetail({
      modalType: 'user',
      dateDomain,
      userEventTypes: sortedUserEventTypes,
      site: { id: site.id, org: site.org },
      onClose: handleClose,
    });
  }, [dateDomain, sortedUserEventTypes, site, refresh]);

  const onOpenEvent = useCallback(
    (item) => {
      const { event, type, id } = item;
      onEventDetail({
        modalType: type,
        event,
        eventId: id,
        dateDomain,
        userEventTypes: sortedUserEventTypes,
        site: { id: site.id, org: site.org },
        onDelete: refresh,
        onClose: handleClose,
        hideInactiveSystemEvents,
        eventListColumns,
      });
    },
    [dateDomain, sortedUserEventTypes, site, refresh, hideInactiveSystemEvents, eventListColumns]
  );

  async function importMaintenanecePlan(file) {
    const [maintenanceSeries, maintenanceComponents, missingComps, maintenanceErrors] =
      await parseMaintenanceExcel({
        file,
        components: siteComponents,
        site: site.id,
        org: site.org,
      });

    if (maintenanceErrors.length > 0) {
      dispatch(setImportErrors(maintenanceErrors));
    } else {
      setMaintenanceSeriesToCreate(maintenanceSeries);
      setmaintenanceComponentsToCreate(maintenanceComponents);
      setMissingComponents(missingComps);
      setImporting(true);
    }
  }

  const xmlMimes = [
    'application/xml',
    'text/xml',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  const onDrop = (accepted) => {
    if (accepted.length > 0) {
      if (
        accepted[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        importMaintenanecePlan(accepted[0]);
      } else {
        onFileUpload({ modalType: 'file', file: accepted[0], isTenantAdmin });
      }
    } else {
      dispatch(
        setImportErrors([
          'Imports should be from a single Excel file for maintenance plans or a single XML file for import of IoT Events',
        ])
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: !isAdmin,
    noClick: true,
    accept: xmlMimes,
    multiple: false,
    noKeyboard: true,
    onDrop,
  });

  const getPeriod = () => {
    const periodToMillis = {
      minutes: 1000 * 60,
      hours: 1000 * 60 * 60,
      days: 1000 * 60 * 60 * 24,
    };
    const now = Date.now();
    if (time === 'past') {
      return [now - periodToMillis[period] * numberPeriods, now];
    }
    return [now, now + periodToMillis[period] * numberPeriods];
  };

  const isLimitedListView = useMemo(
    () => format === 'narrow' || activeLayout === LIMITED_LIST_VIEW,
    [format, activeLayout]
  );

  const [limitedListDateDomain, setLimitedListDateDomain] = useState(getPeriod());
  const pollingInterval = useSelector(getPollingInterval);
  const userRefresh = useSelector(getLastRefresh);
  let interval;

  useEffect(() => {
    if (interval) {
      clearInterval(interval);
    }
    if (pollingInterval || userRefresh) {
      let intervalCb = () => refresh();
      if (isLimitedListView) {
        setLimitedListDateDomain(() => getPeriod());
        intervalCb = () => setLimitedListDateDomain(() => getPeriod());
      } else {
        refresh();
      }
      if (pollingInterval !== 0) {
        interval = setInterval(intervalCb, pollingInterval);
      }
    }
    return () => clearInterval(interval);
  }, [pollingInterval, userRefresh]);

  const timelineBucketCount = width > 200 ? Math.floor((width - 100) / 10) : 10;
  const brushBucketCount = width > 200 ? Math.floor((width - 100) / 10) : 10;

  // 377 width of narrow panel @ screen 1330 (event list item breakpoint)
  // 330 width of narrow panel @ screen 1200 (font-size breakpoint)
  let itemHeight = 67.5;
  if (isLimitedListView && width < 377) {
    itemHeight = width < 330 ? 85.5 : 89;
  }
  const getListHeight = () => {
    if (isLimitedListView) {
      return Math.min(itemHeight * 5, itemHeight * Math.max(limit, 1));
    }
    if (!isFullScreen) {
      if (activeLayout === SPLIT_VIEW) return 300;
      if (activeLayout === LIST_VIEW) return 600;
    }
    // Full-screen responsive LIST height
    return height;
  };

  const [dragCounter, setDragCounter] = useState(0);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragCounter(dragCounter + 1);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragCounter(dragCounter - 1);
  };

  if (isLimitedListView) {
    return (
      <EventTimelineProvider
        site={site}
        dateDomain={limitedListDateDomain}
        timelineBucketCount={timelineBucketCount}
        brushBucketCount={brushBucketCount}
        componentScope={componentScope}
        eventListOrdering={time === 'past' ? 'desc' : 'asc'}
        availableIotEventTypes={availableEventTypes.iot}
        availableUserEventTypes={availableEventTypes.user}
        hideInactiveSystemEvents={hideInactiveSystemEvents}
        eventTypeFilter={panelEventType}
      >
        <div
          ref={clientRef}
          className="event-timeline-outer-container"
        >
          <LoadingLayer>
            <div className="event-timeline-panel">
              <div
                className="event-timeline-list-container limited custom-scrollbar"
                style={{ height: `${getListHeight()}px` }}
              >
                <EventList
                  width={width}
                  height={getListHeight()}
                  itemSize={itemHeight}
                  onOpenEvent={onOpenEvent}
                  limit={limit}
                  time={time}
                  format={format}
                  hideInactiveSystemEvents={hideInactiveSystemEvents}
                  allUserEventTypes={allUserEventTypes}
                />
              </div>
            </div>
          </LoadingLayer>
        </div>
        {eventDetail}
      </EventTimelineProvider>
    );
  }

  const buildEventTimelines = (timelineType) => {
    switch (timelineType) {
      case EVENT_TYPE_USER:
        return (
          <UserTimeline
            fullWidth={activeLayout === LIST_VIEW}
            onOpenEvent={onOpenEvent}
          />
        );
      case EVENT_TYPE_IOT:
        return (
          <IotTimeline
            fullWidth={activeLayout === LIST_VIEW}
            onOpenEvent={onOpenEvent}
          />
        );
      default:
        return (
          <>
            <IotTimeline
              fullWidth={activeLayout === LIST_VIEW}
              onOpenEvent={onOpenEvent}
            />
            <UserTimeline
              fullWidth={activeLayout === LIST_VIEW}
              onOpenEvent={onOpenEvent}
            />
          </>
        );
    }
  };
  return (
    <EventTimelineProvider
      site={site}
      dateDomain={dateDomain}
      timelineBucketCount={timelineBucketCount}
      brushBucketCount={brushBucketCount}
      componentScope={componentScope}
      availableIotEventTypes={availableEventTypes.iot}
      availableUserEventTypes={availableEventTypes.user}
      hideInactiveSystemEvents={hideInactiveSystemEvents}
      eventTypeFilter={panelEventType}
    >
      <EventTimelineHeader
        format={format}
        layouts={layouts}
        activeLayout={activeLayout}
        onSetLayout={onSetLayout}
        onCreateEvent={onOpenNewEvent}
        width={width}
        isFullScreen={isFullScreen}
        panelId={panelId}
        availableIotEventTypes={availableEventTypes.iot}
        availableUserEventTypes={availableEventTypes.user}
        panelEventType={panelEventType}
        allUserEventTypes={allUserEventTypes}
      />
      <div
        ref={clientRef}
        className="event-timeline-outer-container"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <LoadingLayer>
          <div className="event-timeline-panel">
            <div
              {...getRootProps()}
              className="drop-wrapper"
            >
              {dragCounter > 0 && isDragActive && (isTenantAdmin || isAdmin) && (
                <>
                  <input {...getInputProps()} />
                  <div className="drop-zone">
                    <p>Drop an event XML file here...</p>
                  </div>
                </>
              )}
              {activeLayout !== LIST_VIEW && (
                <div className="event-timeline-view">{buildEventTimelines(panelEventType)}</div>
              )}
              {activeLayout !== LIST_VIEW && (
                <div className="event-timeline-brush">
                  <EventBrush type={panelEventType} />
                </div>
              )}
              {activeLayout !== TIMELINE_VIEW && (
                <>
                  <EventLegends
                    fullWidth
                    showLegend={false}
                  />
                  <div
                    className="event-timeline-list-container custom-scrollbar"
                    style={{
                      display: activeLayout === TIMELINE_VIEW ? 'none' : 'block',
                    }}
                  >
                    <EventList
                      width={width}
                      height={getListHeight()}
                      onOpenEvent={onOpenEvent}
                      listColumns={eventListColumns}
                      layoutName={activeLayout}
                      hideInactiveSystemEvents={hideInactiveSystemEvents}
                      allUserEventTypes={allUserEventTypes}
                    />
                  </div>
                </>
              )}
            </div>
            {importing && (
              <ImportMaintenancePlanModal
                onClose={handleClose}
                site={site}
                maintenanceSeriesToCreate={maintenanceSeriesToCreate}
                maintenanceComponentsToCreate={maintenanceComponentsToCreate}
                missingComponents={missingComponents}
              />
            )}
            {showErrors ? (
              <ConfirmationDialog
                onConfirm={() => {
                  dispatch(setImportErrors([]));
                }}
                onCancel={() => {
                  dispatch(setImportErrors([]));
                }}
                showCancel={false}
                title="Import errors"
                subtitle="The following errors occurred while attempting import:"
                body={importErrors.map((e, i) => (
                  <p key={`err-${i}`}>{e}</p>
                ))}
                className="setup-wizard-errors"
              />
            ) : null}
          </div>
        </LoadingLayer>
      </div>
      {eventDetail}
      {fileUpload}
    </EventTimelineProvider>
  );
});
export default EventTimelinePanel;
